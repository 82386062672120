import { SP } from "../../http/serviceportalApi";
import { formatAsNumberWith } from "../../utils";
import Table from "../ui/Table";

interface Props {
  data: SP.ReferralsTableData;
}

const TopReferralsTable = ({ data: { rows } }: Props) => (
  <Table
    isStriped={true}
    isSelectable={false}
    head={
      <tr>
        <th align="left">Portal</th>
        <th align="right">Sitzungen</th>
      </tr>
    }
    body={rows.map(({ source, totalSessions }, index) => (
      <tr key={index}>
        <td align="left">{source}</td>
        <td align="right">{formatAsNumberWith(0, totalSessions)}</td>
      </tr>
    ))}
    total={rows.length}
  />
);

export default TopReferralsTable;
