import classNames from "classnames";
import { useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../context/authContext";
import useOnClickOutside from "../hooks/useOnClickOutside";
import useToggle from "../hooks/useToggle";
import { usePendingInvitesCount } from "../http/serviceportalApi";
import { isRouteEconomicAdvisors } from "../utils";
import styles from "./AccountButton.module.css";
import SvgLogout from "./icons/Logout";
import SvgOpenInNew from "./icons/OpenInNew";
import SvgOutbox from "./icons/Outbox";
import SvgPerson from "./icons/Person";
import Icon from "./ui/Icon";
import Profile from "./ui/Profile";

const AccountButton = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { user, logout } = useAuth();
  const menu = useToggle();
  const { data: pendingInvitesCount } = usePendingInvitesCount();
  const hasPendingInvites = (pendingInvitesCount ?? 0) > 0;
  const location = useLocation();

  useOnClickOutside(ref, menu.close);

  if (!user) {
    return null;
  }

  return (
    <div ref={ref} className={styles.wrapper}>
      <button className={styles.button} onClick={menu.toggle}>
        <Profile
          user={user}
          layout="compact"
          notificationCount={pendingInvitesCount}
        />
      </button>
      {menu.isOpen && (
        <div className={styles.menu}>
          <Profile user={user} className={styles.profile} layout="full" />
          <hr className={styles.separator} />
          <ul className={styles.list}>
            <li>
              <Link
                to="https://accounts.hgv.it"
                target="_blank"
                className={styles.menuItem}
                onClick={menu.close}
              >
                <Icon glyph={SvgPerson} className={styles.icon} />
                Konto
                <Icon glyph={SvgOpenInNew} className={styles.openInNew} />
              </Link>
            </li>
            {!isRouteEconomicAdvisors(location) && (
              <li>
                <Link
                  to="/einladungen"
                  className={styles.menuItem}
                  onClick={menu.close}
                >
                  <Icon glyph={SvgOutbox} className={styles.icon} />
                  <span
                    className={classNames({
                      [styles.unread]: hasPendingInvites,
                    })}
                  >
                    Einladungen
                  </span>
                </Link>
              </li>
            )}
            <li>
              <button className={styles.menuItem} onClick={logout}>
                <Icon glyph={SvgLogout} className={styles.icon} />
                Abmelden
              </button>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default AccountButton;
