import wretch from "wretch";
import QueryStringAddon from "wretch/addons/queryString";
import { FetcherOptions } from "../types";
import useSWRImmutable from "swr/immutable";

export namespace VV {
  export enum APIPermissions {
    Write = "https://vertragsverwaltung.hgvapis.it/write", // TODO: Read (for employees)/Write
  }

  export type MemberId = number;

  export type PricelistId = string;

  export interface Pricelist {
    id: PricelistId;
    name: string;
  }

  export interface Link {
    link: () => Promise<Blob>;
    name: string;
  }

  export type Document =
    | { type: "pricelist"; value: Pricelist }
    | { type: "link"; value: Link };

  export type AgreementId = string;

  export type AgreementVersion = number;

  export type AgreementType = "Master" | "License";

  export type AgreementStatus = "Published" | "Signed" | "Cancelled";

  export interface Agreement {
    id: AgreementId;
    version: AgreementVersion;
    name: string;
    signee: string;
    signedAt: string | null;
    type: AgreementType;
    status: AgreementStatus;
  }
}

const api = wretch()
  .addon(QueryStringAddon)
  .options({ credentials: "include" })
  .url(import.meta.env.VITE_VERTRAGSVERWALTUNG_URL);

const fetcher = <Response,>({ url, query, signal }: FetcherOptions) => {
  return api
    .options({ signal })
    .url(url)
    .query(query ?? {})
    .get()
    .json<Response>();
};

export const usePricelists = (
  memberId: VV.MemberId | undefined,
  signal: AbortSignal
) =>
  useSWRImmutable(
    memberId ? { url: `/members/${memberId}/pricelists`, signal } : null,
    (opts) => fetcher<VV.Pricelist[]>(opts)
  );

export const getPricelistPdf = (
  memberId: VV.MemberId,
  priceListId: VV.PricelistId,
  filename: string
) =>
  api
    .url(`/members/${memberId}/pricelists/${priceListId}/${filename}`)
    .get()
    .blob();

export const getPrivacyPolicyPdf = (memberId: VV.MemberId) =>
  api.url(`/members/${memberId}/privacy_policy`).get().blob();

export const useAgreements = (
  memberId: VV.MemberId | undefined,
  signal: AbortSignal
) =>
  useSWRImmutable(
    memberId ? { url: `/members/${memberId}/agreements`, signal } : null,
    (opts) => fetcher<VV.Agreement[]>(opts)
  );

const formatVersion = (version: number) =>
  version.toLocaleString("en-EN", {
    minimumFractionDigits: 1,
  });

const agreementVersionUrl = (
  memberId: VV.MemberId,
  agreementId: VV.AgreementId,
  agreementVersion: VV.AgreementVersion
) =>
  `/members/${memberId}/agreements/${agreementId}/versions/${formatVersion(
    agreementVersion
  )}`;

export const getAgreement = (
  memberId: VV.MemberId,
  agreementId: VV.AgreementId,
  agreementVersion: VV.AgreementVersion
) =>
  api
    .url(agreementVersionUrl(memberId, agreementId, agreementVersion))
    .get()
    .json<VV.Agreement>();

export const getSignedAgreement = (
  memberId: VV.MemberId,
  agreementId: VV.AgreementId,
  agreementVersion: VV.AgreementVersion,
  filename: string
) =>
  api
    .url(
      `${agreementVersionUrl(
        memberId,
        agreementId,
        agreementVersion
      )}/export/${filename}`
    )
    .get()
    .blob();

export const getPreviewAgreement = (
  memberId: VV.MemberId,
  agreementId: VV.AgreementId,
  agreementVersion: VV.AgreementVersion
) =>
  api
    .url(
      `${agreementVersionUrl(memberId, agreementId, agreementVersion)}/export`
    )
    .get()
    .text();

export const postSignAgreement = (
  memberId: VV.MemberId,
  agreementId: VV.AgreementId,
  agreementVersion: VV.AgreementVersion
) =>
  api
    .url(`${agreementVersionUrl(memberId, agreementId, agreementVersion)}/sign`)
    .post()
    .res();
