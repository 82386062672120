import classNames from "classnames";
import { Children, ReactElement } from "react";
import { NavLink } from "react-router-dom";
import NotificationBadge from "../ui/NotificationBadge";
import styles from "./Nav.module.css";

interface NavProps {
  className?: string;
  children?: ReactElement<NavItemProps>[] | ReactElement<NavItemProps>;
}

const Nav = ({ className, children }: NavProps) => (
  <ol className={classNames(styles.nav, className)}>
    {children &&
      Children.map(children, (child) => ({
        ...child,
        props: {
          ...child.props,
        },
      }))}
  </ol>
);

interface NavItemProps {
  title: string;
  to: string;
  unread?: number;
}
const Item = ({ title, to, unread }: NavItemProps) => {
  const u = unread ?? 0;

  return (
    <li className={styles.item}>
      <NavLink
        className={({ isActive }) => classNames({ [styles.active]: isActive })}
        to={to}
      >
        {title}
      </NavLink>
      {u > 0 && <NotificationBadge>{unread}</NotificationBadge>}
    </li>
  );
};

Nav.Item = Item;

export default Nav;
