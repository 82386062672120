import SvgCheckbox, { SvgCheckboxOutlineBlank } from "../icons/Checkbox";
import styles from "./CheckBox.module.css";
import Icon from "./Icon";

interface Props {
  label: string;
  isSelected: boolean;
  onChange: (b: boolean) => void;
}

const Checkbox = ({ label, isSelected, onChange }: Props) => (
  <div className={styles.checkbox} onClick={() => onChange(!isSelected)}>
    <Icon glyph={isSelected ? SvgCheckbox : SvgCheckboxOutlineBlank} />
    <label>{label}</label>
  </div>
);

export default Checkbox;
