import styles from "./Footer.module.css";

const Footer = () => (
  <footer className={styles.footer}>
    <div className={styles.footerContent}>
      <span>Hoteliers- und Gastwirteverband (HGV)</span>
      <span>·</span>
      <span>Schlachthofstraße 59</span>
      <span>·</span>
      <span>39100 Bozen</span>
      <span>·</span>
      <a href="tel:+390471317700">0471 317 700</a>
      <span>·</span>
      <a href="mailto:info@hgv.it">info@hgv.it</a>
      <span>·</span>
      <a href="https://www.hgv.it/" target="_blank">
        www.hgv.it
      </a>
    </div>
  </footer>
);

export default Footer;
