import classNames from "classnames";
import { forwardRef } from "react";
import styles from "./ToggleSwitch.module.css";

type Props = JSX.IntrinsicElements["input"];

const ToggleSwitch = forwardRef<HTMLInputElement, Props>(
  ({ disabled, ...props }: Props, ref) => {
    return (
      <span
        className={classNames(styles.toggleSwitch, {
          [styles.disabled]: disabled,
        })}
      >
        <input
          {...props}
          ref={ref}
          disabled={disabled}
          className={styles.checkbox}
          type="checkbox"
        />
        <span className={styles.background}></span>
      </span>
    );
  }
);

export default ToggleSwitch;
