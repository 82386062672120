import classNames from "classnames";
import { PropsWithChildren } from "react";
import styles from "./Label.module.css";

type Props = PropsWithChildren<{ className?: string }>;

const Label = ({ className, children }: Props) => (
  <p className={classNames(styles.label, className)}>{children}</p>
);

export default Label;
