import React, { useEffect, useState } from "react";
import styles from "./DocumentViewer.module.css";
import classNames from "classnames";
import Button from "../ui/Button";
import Icon from "../ui/Icon";
import SvgExpandMore from "../icons/ExpandMore";

interface Props {
  docRetriever: () => Promise<string>;
  loadingMessage?: string;
  onScrolledToBottom: () => void;
}

const DocumentViewer = ({
  docRetriever,
  loadingMessage,
  onScrolledToBottom: handleScrolledToBottom,
}: Props) => {
  const [doc, setDoc] = useState<string>();
  const [isLoading, setLoading] = useState(true);

  const observer = new IntersectionObserver(
    ([entry]) => {
      if (entry?.isIntersecting) handleScrolledToBottom();
    },
    { threshold: 1 }
  );

  const loadAgreement = async () => setDoc(await docRetriever());

  const onAgreementLoaded = (
    e: React.SyntheticEvent<HTMLIFrameElement, Event>
  ) => {
    setLoading(false);
    const lastParagraph =
      e.currentTarget.contentDocument?.body.querySelector("p:last-child");
    if (lastParagraph) observer.observe(lastParagraph);
  };

  const scrollDown = () => {
    const el = document.getElementById("preview") as HTMLIFrameElement;
    el.contentWindow?.scrollBy(0, 250);
  };

  useEffect(() => {
    loadAgreement();
  }, []);

  return (
    <div className={styles.documentViewer}>
      <div className={classNames({ [styles.hidden]: !isLoading })}>
        {loadingMessage}
      </div>
      <iframe
        id="preview"
        className={classNames({ [styles.hidden]: isLoading })}
        srcDoc={doc}
        onLoad={onAgreementLoaded}
      />
      <Button
        type="secondary"
        buttonProps={{
          onClick: scrollDown,
          className: classNames(styles.button, { [styles.hidden]: isLoading }),
        }}
      >
        <Icon glyph={SvgExpandMore} className={styles.icon} />
      </Button>
    </div>
  );
};

export default DocumentViewer;
