import { SP } from "../http/serviceportalApi";

const filterByMetric = (metric: string) => (x: SP.LineChartRow) =>
  x.code === metric;

const metricSelector = (
  { rows, filter1Count }: SP.LineChartData,
  metric: string
) => ({
  rows: rows.filter(filterByMetric(metric)),
  filter1Count,
});

export const usersSelector = (chartData: SP.LineChartData) =>
  metricSelector(chartData, "users");

export const sessionsSelector = (chartData: SP.LineChartData) =>
  metricSelector(chartData, "sessions");

export const bounceRateSelector = (chartData: SP.LineChartData) =>
  metricSelector(chartData, "bounceRate");

export const avgSessionDurationSelector = (chartData: SP.LineChartData) =>
  metricSelector(chartData, "avgSessionDuration");

export const pageViewsPerSessionSelector = (chartData: SP.LineChartData) =>
  metricSelector(chartData, "pageviewsPerSession");

export const goalCompletionsAllSelector = (chartData: SP.LineChartData) =>
  metricSelector(chartData, "goalCompletionsAll");

export const goalConversionRateAllSelector = (chartData: SP.LineChartData) =>
  metricSelector(chartData, "goalConversionRateAll");

const subMetricSessionsSelector = (
  { code, companyCount, subRows }: SP.KeyDataRow,
  valueSelector: (row: SP.KeyDataRow | undefined) => number
) => {
  const session = subRows.find((x) => x.code === "sessions");
  return {
    key: code,
    value: valueSelector(session),
    comparisonCount: companyCount || 0,
  };
};

const metricSumSelector = (
  keyData: SP.KeyData,
  metric: string
): SP.ComparisonPieChartData => {
  const keyDataRow = keyData.rows.find((x) => x.code === metric);
  const empty = [[], []] as SP.ComparisonPieChartData;
  const [own, filter]: SP.ComparisonPieChartData =
    keyDataRow?.subRows.reduce(([own, filter], x) => {
      const ownData = subMetricSessionsSelector(x, (session) => {
        const parsed = parseInt(session?.value?.ownValue ?? "", 10);
        return !isNaN(parsed) ? parsed : 0;
      });
      own.push(ownData);
      const filterData = subMetricSessionsSelector(
        x,
        (session) => session?.value?.filter1?.average || 0
      );
      filter.push(filterData);
      return [own, filter];
    }, empty) || empty;

  const byValueDescending: (a: SP.PieChartRow, b: SP.PieChartRow) => number = (
    a,
    b
  ) => b.value - a.value;
  const sortedOwn = own.sort(byValueDescending);
  const sortedFilter = filter.sort(byValueDescending);
  return [sortedOwn, sortedFilter];
};

export const countriesSelector = (keyData: SP.KeyData) =>
  metricSumSelector(keyData, "country");

export const deviceCategoriesSelector = (keyData: SP.KeyData) =>
  metricSumSelector(keyData, "deviceCategory");

export const channelGroupingSelector = (keyData: SP.KeyData) =>
  metricSumSelector(keyData, "channelGrouping");
