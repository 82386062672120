import classNames from "classnames";
import styles from "./ThreeYearAnalysisRow.module.css";
import { SP } from "../../http/serviceportalApi";
import TableCell from "../ui/TableCell";
import {
  formatAsCurrencyWith,
  formatAsNumberWith,
  formatAsPercent,
} from "../../utils";
import { getExpandedState } from "../../selectors/profitLosses";

interface Props {
  isExpandable: boolean;
  isExpanded: boolean;
  isCollapsed: boolean;
  expandCollapse: () => void;
  profitLoss: SP.Return<SP.ThreeYearRow>;
  showWholeYear: boolean;
  showDifferences: boolean;
}

const calculateDifference = (curr: number, prev: number) =>
  curr !== 0 && prev !== 0 ? (curr - prev) / prev : 0;

const ThreeYearAnalysisRow = ({
  isExpandable,
  isExpanded,
  isCollapsed,
  expandCollapse,
  profitLoss,
  showWholeYear,
  showDifferences,
}: Props) => {
  let valueFormatter = (type: SP.ValueType, value: number) => {
    switch (type) {
      case "absolute":
        return formatAsNumberWith(0, value);
      case "currency":
        return formatAsCurrencyWith(0, value);
      case "percentage":
        return formatAsPercent(0, value);
    }
  };

  let values = (
    [value, percent]: SP.ValuePercent,
    wholeYear: SP.ValuePercent | null
  ) => (
    <>
      <TableCell
        CellType="td"
        value={value}
        formatter={(value) => valueFormatter(profitLoss.valueType, value)}
      />
      <TableCell
        CellType="td"
        value={percent}
        formatter={(value) => formatAsPercent(2, value)}
      />
      {showWholeYear && wholeYear && (
        <>
          <TableCell
            CellType="td"
            value={wholeYear[0]}
            formatter={(value) => valueFormatter(profitLoss.valueType, value)}
          />
          <TableCell
            CellType="td"
            value={wholeYear[1]}
            formatter={(value) => formatAsPercent(2, value)}
          />
        </>
      )}
    </>
  );

  let difference = (diff: number) => (
    <TableCell
      CellType="td"
      value={diff}
      formatter={(value) => formatAsPercent(2, value)}
    />
  );

  return (
    <tr
      className={classNames(styles.threeYearAnalysisRow, {
        [styles.level1]: profitLoss.level === "level1",
        [styles.level2]: profitLoss.level === "level2",
        [styles.level4]: profitLoss.level === "level4",
        [styles.expandable]: isExpandable,
      })}
      onClick={() => isExpandable && expandCollapse()}
    >
      <td
        className={classNames({
          [styles.isInfo]: getExpandedState(
            isExpanded,
            isCollapsed,
            profitLoss
          ),
        })}
      />
      <td>{profitLoss.label}</td>
      {values(profitLoss.value.range1, profitLoss.value.year1)}
      {showDifferences &&
        difference(
          calculateDifference(
            profitLoss.value.range1[0],
            profitLoss.value.range2[0]
          )
        )}
      {values(profitLoss.value.range2, profitLoss.value.year2)}
      {showDifferences &&
        difference(
          calculateDifference(
            profitLoss.value.range2[0],
            profitLoss.value.range3[0]
          )
        )}
      {values(profitLoss.value.range3, profitLoss.value.year3)}
    </tr>
  );
};

export default ThreeYearAnalysisRow;
