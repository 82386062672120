import { useContext, useRef } from "react";
import { Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useMember } from "../../context/memberContext";
import NotificationDispatch, {
  showErrorNotification,
  showSuccessNotification,
} from "../../context/notificationContext";
import {
  putMemberUser,
  putWebdeskUser,
  upsertMemberInvite,
  useUser,
  useWebdeskUser,
} from "../../http/serviceportalApi";
import { getRemoteDataStatus } from "../../utils";
import CompanySelect from "../ui/CompanySelect";
import Form from "../ui/Form";
import FormField from "../ui/FormField";
import Input from "../ui/Input";
import Page from "../ui/Page";
import Panel from "../ui/Panel";
import PanelSection from "../ui/PanelSection";
import ToggleSwitch from "../ui/ToggleSwitch";
import ApplicationPermissions from "./ApplicationPermissions";
import DocumentPermissions from "./DocumentPermissions";
import styles from "./MemberUser.module.css";
import { Permission } from "../../types";

interface FormData {
  name: string;
  email: string;
  companyId?: string;
  isOwner: boolean;
  applicationPermissions: string[];
  documentPermissions: string[];
}

const memberLevelPermissions: string[] = [
  Permission.EmployeeRegistrationExecute,
  Permission.HourRegistrationExecute,
];

const MemberUser = () => {
  const { member } = useMember();
  const { userId, companyId } = useParams();
  const isNew = userId === "neu";
  const navigate = useNavigate();
  const dispatch = useContext(NotificationDispatch);

  const aborter = useRef(new AbortController());
  const { data, isValidating, error } = useUser(
    isNew ? undefined : member?.id,
    userId!,
    aborter.current.signal
  );
  const status = getRemoteDataStatus({ isValidating, error });

  const permissions =
    (!companyId
      ? data?.permissions
      : data?.companies.find((c) => c.id === +companyId)?.permissions) ?? [];
  const applicationPermissions = permissions.filter(
    (p) => !checkIsDocumentPermission(p)
  );
  const documentPermissions = permissions.filter(checkIsDocumentPermission);

  const { data: webdesk } = useWebdeskUser(
    isNew ? undefined : member?.id,
    userId!,
    aborter.current.signal
  );

  if (!member) return null;

  return (
    <Page
      title={isNew ? "Benutzer einladen" : "Benutzer bearbeiten"}
      backLinkText="Zurück zu Benutzer"
      isLoading={status === "validating"}
    >
      <Panel title="Berechtigungen">
        <div className={styles.formWrapper}>
          <Form<FormData>
            defaultValues={{
              name: data?.name,
              email: data?.email,
              companyId: companyId ?? "all",
              isOwner: data?.sources.includes("owner"),
              applicationPermissions,
              documentPermissions,
            }}
            submitText={isNew ? "Einladen" : "Speichern"}
            onSubmit={() => (formData) => {
              const companyId =
                formData.companyId !== "all"
                  ? parseInt(formData.companyId!, 10)
                  : undefined;
              const mergedPermissions = [
                ...formData.applicationPermissions,
                ...formData.documentPermissions,
              ];
              if (isNew) {
                return upsertMemberInvite(
                  member.id,
                  companyId,
                  formData.name,
                  formData.email,
                  formData.isOwner,
                  mergedPermissions
                )
                  .then(() => {
                    navigate("../einladungen");
                    dispatch(
                      showSuccessNotification(
                        "Einladung erfolgreich versendet."
                      )
                    );
                  })
                  .catch((err) => {
                    dispatch(
                      showErrorNotification(
                        err,
                        "Fehler beim Versenden der Einladung."
                      )
                    );
                  });
              } else {
                return putMemberUser(
                  member.id,
                  companyId,
                  userId!,
                  formData.isOwner,
                  mergedPermissions
                )
                  .then(() => {
                    dispatch(showSuccessNotification());
                  })
                  .catch((err) => {
                    dispatch(showErrorNotification(err));
                  });
              }
            }}
          >
            {({ register, control, watch, setValue }) => {
              const companyId = watch("companyId");
              const applicationPermissions = watch("applicationPermissions");
              return (
                <>
                  <div className={styles.grid}>
                    <Input
                      placeholder="Name"
                      disabled={!isNew}
                      {...register("name", {
                        required: true,
                      })}
                    />
                    <Input
                      type="email"
                      placeholder="E-Mail-Adresse"
                      disabled={!isNew}
                      {...register("email", {
                        required: true,
                      })}
                    />
                    <CompanySelect
                      disabled={!isNew}
                      alwaysShowAllCompaniesOption={true}
                      {...register("companyId", {
                        required: true,
                      })}
                      onChange={(e) => {
                        setValue("companyId", e.target.value);
                        setValue(
                          "applicationPermissions",
                          e.target.value === "all"
                            ? applicationPermissions
                            : applicationPermissions.filter(
                                (p) => !memberLevelPermissions.includes(p)
                              )
                        );
                      }}
                    />
                    <label className={styles.owner}>
                      Gesetzlicher Vertreter
                      <ToggleSwitch {...register("isOwner")} />
                    </label>
                  </div>
                  <PanelSection title="Anwendungen">
                    <Controller
                      name="applicationPermissions"
                      control={control}
                      render={({ field }) => (
                        <ApplicationPermissions
                          selectedPermissions={field.value}
                          disabledPermissions={
                            companyId !== "all" ? memberLevelPermissions : undefined
                          }
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </PanelSection>
                  <PanelSection title="Dokumente">
                    <Controller
                      name="documentPermissions"
                      control={control}
                      render={({ field }) => (
                        <DocumentPermissions
                          selectedPermissions={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </PanelSection>
                </>
              );
            }}
          </Form>
        </div>
      </Panel>

      {!isNew && (
        <Panel title="Webdesk">
          <Form<{ login: string }>
            defaultValues={{ login: webdesk?.login ?? "" }}
            onSubmit={(reset) => (formData) =>
              putWebdeskUser(member.id!, userId!, { login: formData.login })
                .then((res) => {
                  dispatch(showSuccessNotification());
                  reset({ login: res.login ?? "" });
                })
                .catch((err) => dispatch(showErrorNotification(err)))}
          >
            {({ register }) => (
              <FormField
                className={styles.webdeskFormField}
                label="Benutzername"
                isOptional={true}
                direction="row"
              >
                <Input {...register("login")} placeholder={`s${member.id}`} />
              </FormField>
            )}
          </Form>
        </Panel>
      )}
    </Page>
  );
};

const checkIsDocumentPermission = (permission: string) =>
  permission.startsWith("documents.");

export default MemberUser;
