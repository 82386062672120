import { Tab as HeadlessTab } from "@headlessui/react";
import classNames from "classnames";
import { PropsWithChildren } from "react";
import styles from "./Tab.module.css";

type TabGroupProps = PropsWithChildren<{
  defaultIndex?: number;
  selectedIndex?: number;
  onChange?: (index: number) => void;
}>;

const TabGroup = (props: TabGroupProps) => {
  return <HeadlessTab.Group {...props} />;
};

type TabListProps = PropsWithChildren<{ className?: string }>;

const TabList = ({ children, className }: TabListProps) => {
  if (!children) return null;

  return (
    <nav className={classNames(styles.nav, className)}>
      <HeadlessTab.List className={styles.list}>{children}</HeadlessTab.List>
    </nav>
  );
};

type TabProps = PropsWithChildren<{
  isActive?: boolean;
  title?: string;
  className?: string;
}>;

const Tab = ({ children, title, isActive, className }: TabProps) => (
  <HeadlessTab
    title={title}
    className={({ selected }) =>
      classNames(
        styles.link,
        { [styles.linkActive]: isActive ?? selected },
        className
      )
    }
  >
    {children}
  </HeadlessTab>
);

Tab.Group = TabGroup;
Tab.List = TabList;
Tab.Panels = HeadlessTab.Panels;
Tab.Panel = HeadlessTab.Panel;

export default Tab;
