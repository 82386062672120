import { SP } from "../http/serviceportalApi";
import { getLongMonthName, range } from "../utils";

interface WageCostsSummary {
  wage: number;
  additionalSalaries: number;
  otherWage: number;
  dues: number;
  abfertigung: number;
}

const summarizeWageCosts = (wageCosts: SP.WageCosts[]): WageCostsSummary =>
  wageCosts.reduce(
    (state: WageCostsSummary, value: SP.WageCosts) => ({
      wage: state.wage + (value.wage + value.overtimeHours),
      additionalSalaries: state.additionalSalaries + value.additionalSalaries,
      otherWage: state.otherWage + value.otherWage,
      dues: state.dues + (value.dues + value.additionalSalaryDues),
      abfertigung:
        state.abfertigung +
        (value.additionalPensionFund + value.abfertigungDesMonats),
    }),
    {
      wage: 0,
      additionalSalaries: 0,
      otherWage: 0,
      dues: 0,
      abfertigung: 0,
    }
  );

const getWageCostsByPeriod = (
  {
    from: { month: fromMonth },
    until: { year: untilYear, month: untilMonth },
  }: SP.Period,
  wageCosts: SP.WageCosts[]
) =>
  summarizeWageCosts(
    wageCosts.filter(
      ({ year, month }) =>
        year === untilYear && month >= fromMonth && month <= untilMonth
    )
  );

const subtractYear = (
  amount: number,
  {
    from: { year: fromYear, month: fromMonth },
    until: { year: untilYear, month: untilMonth },
  }: SP.Period
) => ({
  from: { year: fromYear - amount, month: fromMonth },
  until: { year: untilYear - amount, month: untilMonth },
});

const calculateThreeYearRowOld = (rows: SP.ThreeYearRowOld[]) =>
  rows.reduce(
    (state, value) => ({
      year1: state.year1 + value.year1,
      year2: state.year2 + value.year2,
      year3: state.year3 + value.year3,
    }),
    {
      year1: 0,
      year2: 0,
      year3: 0,
    }
  );

const getWageCostLabel = (k: keyof WageCostsSummary) => {
  switch (k) {
    case "wage":
      return "Entlohnung";
    case "additionalSalaries":
      return "Zusatzgehälter";
    case "otherWage":
      return "Andere Lohnelemente*";
    case "dues":
      return "Beiträge";
    case "abfertigung":
      return "Abfertigung (inkl. Fonds)";
  }
};

export const getThreeYearComparisonOperationRows = (
  period: SP.Period,
  wageCosts: SP.WageCosts[]
): SP.AnnualComparisonOperationRow<SP.ThreeYearRowOld>[] => {
  if (wageCosts.length == 0) {
    return [];
  }

  const costsCurrentYear = getWageCostsByPeriod(period, wageCosts);
  const costsPreviousYear = getWageCostsByPeriod(
    subtractYear(1, period),
    wageCosts
  );
  const costsBeforePreviousYear = getWageCostsByPeriod(
    subtractYear(2, period),
    wageCosts
  );
  const rows: SP.AnnualComparisonOperationRow<SP.ThreeYearRowOld>[] = (<
    ReadonlyArray<keyof WageCostsSummary>
  >["wage", "additionalSalaries", "otherWage", "dues", "abfertigung"]).map(
    (x) => ({
      type: "summand",
      value: {
        label: getWageCostLabel(x),
        value: {
          year1: costsCurrentYear[x],
          year2: costsPreviousYear[x],
          year3: costsBeforePreviousYear[x],
        },
      },
    })
  );
  const rowsSum = calculateThreeYearRowOld(
    rows.map(({ value: { value } }) => value)
  );
  return [
    ...rows,
    { type: "sum", value: { label: "Lohnkosten", value: rowsSum } },
  ];
};

const getWageCostsByExactYearMonth = (
  { year: untilYear, month: untilMonth }: SP.YearMonth,
  wageCosts: SP.WageCosts[]
) =>
  wageCosts.filter(
    ({ year, month }) => year === untilYear && month === untilMonth
  );

const getMonthlyComparisonRows = (
  { year: untilYear, month: untilMonth }: SP.YearMonth,
  wageCosts: SP.WageCosts[],
  selectorFunc: (state: number, curr: SP.WageCosts) => number
): SP.AnnualComparisonRow<SP.ThreeYearRowOld>[] => {
  return range(1, untilMonth).map((month) => ({
    label: getLongMonthName(month),
    value: {
      year1: getWageCostsByExactYearMonth(
        { year: untilYear, month },
        wageCosts
      ).reduce(selectorFunc, 0),
      year2: getWageCostsByExactYearMonth(
        { year: untilYear - 1, month },
        wageCosts
      ).reduce(selectorFunc, 0),
      year3: getWageCostsByExactYearMonth(
        { year: untilYear - 2, month },
        wageCosts
      ).reduce(selectorFunc, 0),
    },
  }));
};

export const getDaysAbsent = (
  yearMonth: SP.YearMonth,
  wageCosts: SP.WageCosts[]
) =>
  getMonthlyComparisonRows(
    yearMonth,
    wageCosts,
    (state: number, curr: SP.WageCosts) => state + curr.daysAbsent
  );

export const getWorkDays = (
  yearMonth: SP.YearMonth,
  wageCosts: SP.WageCosts[]
) =>
  getMonthlyComparisonRows(
    yearMonth,
    wageCosts,
    (state: number, curr: SP.WageCosts) => state + curr.workDays
  );

export type AverageNumberOfEmployeesFilter =
  | "owner_and_employedfamilymembers"
  | "employees"
  | "all";

export const getAverageNumberOfEmployees = (
  yearMonth: SP.YearMonth,
  wageCosts: SP.WageCosts[],
  filter: AverageNumberOfEmployeesFilter
) =>
  getMonthlyComparisonRows(
    yearMonth,
    wageCosts,
    (state: number, curr: SP.WageCosts) => {
      switch (filter) {
        case "owner_and_employedfamilymembers":
          return state + curr.nurFamGes;
        case "employees":
          return state + (curr.effektAnw - curr.nurFamGes);
        case "all":
        default:
          return state + curr.effektAnw;
      }
    }
  );

const calculateTotalWageCosts = (summary: WageCostsSummary): number => {
  return (
    summary.wage +
    summary.additionalSalaries +
    summary.otherWage +
    summary.dues +
    summary.abfertigung
  );
};

export const getTotalWageCosts = (
  { year: untilYear, month: untilMonth }: SP.YearMonth,
  wageCosts: SP.WageCosts[]
): SP.AnnualComparisonRow<SP.TwoYearRow>[] =>
  range(1, untilMonth).map((month) => ({
    label: getLongMonthName(month),
    value: {
      year1: calculateTotalWageCosts(
        summarizeWageCosts(
          getWageCostsByExactYearMonth({ year: untilYear, month }, wageCosts)
        )
      ),
      year2: calculateTotalWageCosts(
        summarizeWageCosts(
          getWageCostsByExactYearMonth(
            { year: untilYear - 1, month },
            wageCosts
          )
        )
      ),
    },
  }));
