import classNames from "classnames";
import useToggle from "../../hooks/useToggle";
import { SP } from "../../http/serviceportalApi";
import {
  formatAsCurrencyWith,
  formatLongDate,
  sumOpenItems,
} from "../../utils";
import SvgExpandLess from "../icons/ExpandLess";
import SvgExpandMore from "../icons/ExpandMore";
import Icon from "../ui/Icon";
import Table from "../ui/Table";
import TableCell from "../ui/TableCell";
import styles from "./DocumentsTable.module.css";

export interface OpenItemsDocument {
  documentDate: string | null;
  documentNumber: string;
  documentNumberOriginal: string;
  operationDate: string | null;
  section: string;
  openItems: SP.OpenItem[];
}

interface Props {
  documents: OpenItemsDocument[];
}

const DocumentsTable = ({ documents }: Props) => (
  <Table
    classNames={{ table: styles.table }}
    total={documents.length}
    head={
      <tr>
        <th></th>
        <th className={styles.iconCell}></th>
        <th className={styles.dateCell}>Rechnungsdatum</th>
        <th className={styles.docNumberCell}>Rechnungs-Nr.</th>
        <th className={styles.docNumberCell}>Dokument-Nr.</th>
        <th className={styles.dateCell}>Buchungsdatum</th>
        <th className={styles.amountCell}>Haben</th>
        <th className={styles.amountCell}>Soll</th>
        <th className={styles.commentCell}>Anmerkung</th>
        <th className={styles.titleCell}>Bezeichnung</th>
      </tr>
    }
    body={
      <>
        {documents.map(
          ({
            documentDate,
            documentNumber,
            documentNumberOriginal,
            operationDate,
            section,
            openItems,
          }) => (
            <Level1Row
              key={
                documentDate +
                documentNumber +
                documentNumberOriginal +
                operationDate +
                section
              }
              documentDate={documentDate}
              documentNumber={documentNumber}
              documentNumberOriginal={documentNumberOriginal}
              operationDate={operationDate}
              section={section}
              openItems={openItems}
            />
          )
        )}
      </>
    }
  />
);

interface Level1RowProps {
  documentDate: string | null;
  documentNumber: string;
  documentNumberOriginal: string;
  operationDate: string | null;
  section: string;
  openItems: SP.OpenItem[];
}

const Level1Row = ({
  documentDate,
  documentNumber,
  documentNumberOriginal,
  operationDate,
  section,
  openItems,
}: Level1RowProps) => {
  const isExpandable = openItems.length > 1;
  const level2 = useToggle();
  const isCredit = section === "A";
  const isDebit = section === "D";
  const totalBalance = sumOpenItems(openItems);

  return (
    <>
      <tr
        className={classNames(styles.level1Row, {
          [styles.expandable]: isExpandable,
          [styles.expanded]: level2.isOpen,
          [styles.credit]: isCredit,
          [styles.debit]: isDebit,
        })}
        onClick={() => isExpandable && level2.toggle()}
      >
        <td></td>
        <td className={styles.iconCell}>
          {isExpandable && (
            <Icon
              glyph={level2.isOpen ? SvgExpandLess : SvgExpandMore}
              className={styles.icon}
            />
          )}
        </td>
        <td>{documentDate ? formatLongDate(documentDate) : undefined}</td>
        <td>{documentNumber}</td>
        <td>{documentNumberOriginal}</td>
        <td>{operationDate ? formatLongDate(operationDate) : undefined}</td>
        <TableCell
          CellType="td"
          value={isCredit ? totalBalance : 0}
          formatter={(value) => formatAsCurrencyWith(2, value)}
        />
        <TableCell
          CellType="td"
          value={isDebit ? -totalBalance : 0}
          formatter={(value) => formatAsCurrencyWith(2, value)}
        />
        <td>{!isExpandable && openItems[0]?.notes}</td>
        <td>{!isExpandable && openItems[0]?.description}</td>
      </tr>
      {level2.isOpen &&
        openItems.map((openItem, index) => (
          <Level2Row key={index} openItem={openItem} />
        ))}
    </>
  );
};

interface Level2RowProps {
  openItem: SP.OpenItem;
}

const Level2Row = ({
  openItem: {
    documentDate,
    documentNumber,
    documentNumberOriginal,
    operationDate,
    section,
    balance,
    notes,
    description,
  },
}: Level2RowProps) => {
  const isCredit = section === "A";
  const isDebit = section === "D";

  return (
    <tr
      className={classNames(styles.level2Row, {
        [styles.credit]: isCredit,
        [styles.debit]: isDebit,
      })}
    >
      <td></td>
      <td className={styles.iconCell}></td>
      <td>{documentDate ? formatLongDate(documentDate) : undefined}</td>
      <td>{documentNumber}</td>
      <td>{documentNumberOriginal}</td>
      <td>{operationDate ? formatLongDate(operationDate) : undefined}</td>
      <TableCell
        CellType="td"
        value={isCredit ? balance : 0}
        formatter={(value) => formatAsCurrencyWith(2, value)}
      />
      <TableCell
        CellType="td"
        value={isDebit ? -balance : 0}
        formatter={(value) => formatAsCurrencyWith(2, value)}
      />
      <td>{notes}</td>
      <td>{description}</td>
    </tr>
  );
};

export default DocumentsTable;
