import { Fragment, useEffect, useState } from "react";
import styles from "./HighlightText.module.css";

interface Props {
  text: string;
  highlight: string;
  className?: string;
  showTitle?: boolean;
}

const HighlightText = ({
  text,
  highlight,
  className,
  showTitle = false,
}: Props) => {
  const [parts, setParts] = useState<string[]>([]);

  useEffect(
    () => setParts(text.split(new RegExp(`(${highlight})`, "gi"))),
    [text, highlight]
  );

  return (
    <span className={className} title={showTitle ? text : undefined}>
      {parts.map((part, index) => (
        <Fragment key={index}>
          {part.toLowerCase() === highlight.toLowerCase() ? (
            <span className={styles.highlighted}>{part}</span>
          ) : (
            part
          )}
        </Fragment>
      ))}
    </span>
  );
};

export default HighlightText;
