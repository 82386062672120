import classNames from "classnames";
import { PropsWithChildren } from "react";
import styles from "./Badge.module.css";

type Props = PropsWithChildren<{
  color: "grey" | "green" | "red" | "yellow" | "blue";
}>;

const Badge = ({ children, color }: Props) => (
  <span className={classNames(styles.badge, styles[color])}>{children}</span>
);

export default Badge;
