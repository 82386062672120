import i18n from "../i18n";
import { Department, Permission } from "../types";
import styles from "./PermissionTable.module.css";

interface Props {
  permissions: string[];
}

const PermissionTable = ({ permissions }: Props) => {
  const permissionsByDepartment = groupByDepartment(permissions);

  return (
    <table className={styles.permissionTable}>
      <tbody>
        {Object.entries(permissionsByDepartment).map(
          ([department, permissions]) =>
            permissions.length > 0 && (
              <tr key={department}>
                <td className={styles.department}>
                  {i18n.departments[department as Department].title}
                </td>
                <td>
                  {permissions.map((p) => i18n.getPermission(p)).join(", ")}
                </td>
              </tr>
            )
        )}
      </tbody>
    </table>
  );
};

const groupByDepartment = (permissions: string[]) =>
  permissions.reduce(
    (state: { [department in Department]: Permission[] }, value) => {
      switch (value) {
        case Permission.AccountingsRead:
        case Permission.OpenItemsRead:
        case Permission.InvoicePortalExecute:
        case Permission.BusinessCheckExecute:
        case Permission.TIBuchRead:
        case Permission.MonatsdatenDataWrite:
        case Permission.EndbestaendeWorksheetsWrite:
        case Permission.DocumentsSteuerRundschreibenRead:
        case Permission.DocumentsJahresauswertungRead:
          state["accounting"].push(value);
          break;

        case Permission.WageCostsRead:
        case Permission.HourRegistrationExecute:
        case Permission.EmployeeRegistrationExecute:
        case Permission.DocumentsBetriebOnlineServiceRead:
        case Permission.DocumentsBetriebStandardRead:
        case Permission.DocumentsPrivateHaushalteRead:
        case Permission.DocumentsAufstellungNettoloehneRead:
        case Permission.DocumentsBuchungsbelegeRead:
        case Permission.DocumentsCUDRead:
        case Permission.DocumentsEinbehalt15Read:
        case Permission.DocumentsErklSteuerfreibetraegeRead:
        case Permission.DocumentsLaborfondMonatlichRead:
        case Permission.DocumentsLaborfondTrimestralRead:
        case Permission.DocumentsLohnstreifenRead:
        case Permission.DocumentsMatrikelbuchRead:
        case Permission.DocumentsF24Read:
        case Permission.DocumentsNummInailRead:
        case Permission.DocumentsSchulgeldLehrlingeRead:
        case Permission.DocumentsUeberweisungslisteRead:
        case Permission.DocumentsZahlungsbestaetigungF24Read:
        case Permission.DocumentsEinheitslohnbuchRead:
        case Permission.Documents770SemplificatoRead:
        case Permission.Documents770OrdinarioRead:
        case Permission.DocumentsArbeitsvertraegeRead:
        case Permission.DocumentsLohnRundschreibenRead:
        case Permission.DocumentsProvisorischerLohnstreifenRead:
          state["payroll"].push(value);
          break;

        case Permission.WebsiteMetricsRead:
        case Permission.AgreementsExecute:
        case Permission.Dac7Write:
          state["webMarketing"].push(value);
          break;
      }
      return state;
    },
    { accounting: [], payroll: [], webMarketing: [] }
  );

export default PermissionTable;
