import { useRef, useState } from "react";
import { useMember } from "../../context/memberContext";
import {
  SP,
  useKeyData,
  useMetrics,
  useReferralsTableData,
} from "../../http/serviceportalApi";
import {
  avgSessionDurationSelector,
  bounceRateSelector,
  channelGroupingSelector,
  countriesSelector,
  deviceCategoriesSelector,
  goalCompletionsAllSelector,
  goalConversionRateAllSelector,
  pageViewsPerSessionSelector,
  sessionsSelector,
  usersSelector,
} from "../../selectors/metrics";
import { Permission, ViewMode } from "../../types";
import {
  formatAsNumberWith,
  formatAsPercent,
  formatAsTime,
  getDefaultPeriod,
} from "../../utils";
import ButtonGroup from "../ui/ButtonGroup";
import Chart from "../ui/Chart";
import CompanySelect from "../ui/CompanySelect";
import ErrorText from "../ui/ErrorText";
import LoadingSpinner from "../ui/LoadingSpinner";
import ComparisonPieChart from "./ComparisonPieChart";
import MetricsLineChart from "./MetricsLineChart";
import PeriodSelect from "./PeriodSelect";
import TopReferralsTable from "./TopReferralsTable";
import styles from "./WebsiteAnalysis.module.css";

const WebsiteAnalysis = () => {
  const { member } = useMember();
  const [companyId, setCompanyId] = useState<SP.CompanyId>();
  const [viewMode, setViewMode] = useState<ViewMode>("month");
  const [period, setPeriod] = useState(getDefaultPeriod());

  const aborter = useRef(new AbortController());

  const {
    data: lineChartData,
    isValidating: isValidatingLineChartData,
    error: lineChartDataError,
  } = useMetrics(
    member?.id,
    companyId,
    viewMode,
    period,
    aborter.current.signal
  );
  const isSuccessLineChartData =
    !isValidatingLineChartData && !lineChartDataError;

  const {
    data: keyData,
    isValidating: isValidatingKeyData,
    error: keyDataError,
  } = useKeyData(member?.id, companyId, period, aborter.current.signal);
  const isSuccessKeyData = !isValidatingKeyData && !keyDataError;

  const {
    data: referralsTableData,
    isValidating: isValidatingReferralsTableData,
    error: referralsTableDataError,
  } = useReferralsTableData(aborter.current.signal);
  const isSuccessReferralsTableData =
    !isValidatingReferralsTableData && !referralsTableDataError;

  const emptyResultMessage =
    "Es wurden keine Daten für den ausgewählten Zeitraum gefunden.";
  const errorMessage = "Fehler beim Laden des Berichtes.";

  const renderLineChartFilters = (idPrefix: string) => (
    <div className={styles.filters}>
      <div className={styles.filterSetting}>
        <label htmlFor={`${idPrefix}Company`}>Betriebsauswahl</label>
        <CompanySelect
          id={`${idPrefix}Company`}
          className={styles.companySelect}
          isSingleSelect={true}
          allowedPermissions={[Permission.WebsiteMetricsRead]}
          value={companyId}
          onChange={(e) =>
            setCompanyId(
              e.target.value !== "all"
                ? parseInt(e.target.value, 10)
                : undefined
            )
          }
        />
      </div>
      <div className={styles.filterSetting} style={{ alignItems: "center" }}>
        <label className={styles.hiddenLabel}>&nbsp;</label>
        <ButtonGroup>
          <ButtonGroup.Button
            isActive={viewMode === "day"}
            onClick={() => setViewMode("day")}
          >
            Tag
          </ButtonGroup.Button>
          <ButtonGroup.Button
            isActive={viewMode === "week"}
            onClick={() => setViewMode("week")}
          >
            Woche
          </ButtonGroup.Button>
          <ButtonGroup.Button
            isActive={viewMode === "month"}
            onClick={() => setViewMode("month")}
          >
            Monat
          </ButtonGroup.Button>
        </ButtonGroup>
      </div>
      <div className={styles.filterSetting} style={{ flexShrink: 0 }}>
        <label>Zeitraum</label>
        <PeriodSelect period={period} onChange={setPeriod} />
      </div>
    </div>
  );

  const renderLineChart = (
    selector: (data: SP.LineChartData) => SP.LineChartData,
    formatter: (value: number) => string
  ) => (
    <>
      {isSuccessLineChartData && lineChartData && (
        <>
          {!lineChartData?.rows.length ? (
            <div className={styles.status}>{emptyResultMessage}</div>
          ) : (
            <MetricsLineChart
              data={selector(lineChartData)}
              viewMode={viewMode}
              formatter={formatter}
            />
          )}
        </>
      )}
      {isValidatingLineChartData && (
        <div className={styles.status}>
          <LoadingSpinner />
        </div>
      )}
      {lineChartDataError && (
        <div className={styles.status}>
          <ErrorText text={errorMessage} />
        </div>
      )}
    </>
  );

  const renderPieChartFilters = (idPrefix: string) => (
    <div className={styles.filters}>
      <div className={styles.filterSetting}>
        <label htmlFor={`${idPrefix}Company`}>Betriebsauswahl</label>
        <CompanySelect
          id={`${idPrefix}Company`}
          className={styles.companySelect}
          isSingleSelect={true}
          allowedPermissions={[Permission.WebsiteMetricsRead]}
          value={companyId}
          onChange={(e) =>
            setCompanyId(
              e.target.value !== "all"
                ? parseInt(e.target.value, 10)
                : undefined
            )
          }
        />
      </div>
      <div className={styles.filterSetting}>
        <label>Zeitraum</label>
        <PeriodSelect period={period} onChange={setPeriod} />
      </div>
    </div>
  );

  const renderPieChart = (
    selector: (data: SP.KeyData) => SP.ComparisonPieChartData,
    formatter: (value: number) => string
  ) => (
    <>
      {isSuccessKeyData && keyData && (
        <>
          {keyData?.rows.every(({ value }) => value === null) ? (
            <div className={styles.status}>{emptyResultMessage}</div>
          ) : (
            <ComparisonPieChart
              data={selector(keyData!)}
              formatter={formatter}
            />
          )}
        </>
      )}
      {isValidatingKeyData && (
        <div className={styles.status}>
          <LoadingSpinner />
        </div>
      )}
      {keyDataError && (
        <div className={styles.status}>
          <ErrorText text={errorMessage} />
        </div>
      )}
    </>
  );

  return (
    <div className={styles.wwebsiteAnalysis}>
      <div className={styles.charts}>
        <Chart className={styles.chart} title="Nutzer">
          <Chart.Filter>{renderLineChartFilters("users")}</Chart.Filter>
          <Chart.Content>
            {renderLineChart(usersSelector, (value) =>
              formatAsNumberWith(0, value)
            )}
          </Chart.Content>
        </Chart>
        <Chart className={styles.chart} title="Sitzungen">
          <Chart.Filter>{renderLineChartFilters("sessions")}</Chart.Filter>
          <Chart.Content>
            {renderLineChart(sessionsSelector, (value) =>
              formatAsNumberWith(0, value)
            )}
          </Chart.Content>
        </Chart>
        <Chart className={styles.chart} title="Absprungrate">
          <Chart.Filter>{renderLineChartFilters("bounceRate")}</Chart.Filter>
          <Chart.Content>
            {renderLineChart(bounceRateSelector, (value) =>
              formatAsPercent(2, value / 100)
            )}
          </Chart.Content>
        </Chart>
        <Chart className={styles.chart} title="Durchschnittliche Sitzungsdauer">
          <Chart.Filter>
            {renderLineChartFilters("avgSessionDuration")}
          </Chart.Filter>
          <Chart.Content>
            {renderLineChart(avgSessionDurationSelector, (value) =>
              formatAsTime(value)
            )}
          </Chart.Content>
        </Chart>
        <Chart className={styles.chart} title="Seitenaufrufe pro Sitzung">
          <Chart.Filter>
            {renderLineChartFilters("pageViewsPerSession")}
          </Chart.Filter>
          <Chart.Content>
            {renderLineChart(pageViewsPerSessionSelector, (value) =>
              formatAsNumberWith(2, value)
            )}
          </Chart.Content>
        </Chart>
        <Chart className={styles.chart} title="Conversions">
          <Chart.Filter>{renderLineChartFilters("conversions")}</Chart.Filter>
          <Chart.Content>
            {renderLineChart(goalCompletionsAllSelector, (value) =>
              formatAsNumberWith(0, value)
            )}
          </Chart.Content>
        </Chart>
        <Chart className={styles.chart} title="Conversion-Rate">
          <Chart.Filter>
            {renderLineChartFilters("conversionRate")}
          </Chart.Filter>
          <Chart.Content>
            {renderLineChart(goalConversionRateAllSelector, (value) =>
              formatAsPercent(2, value / 100)
            )}
          </Chart.Content>
        </Chart>
      </div>
      <div className={styles.charts}>
        <Chart className={styles.chart} title="Herkunftsländer">
          <Chart.Filter>{renderPieChartFilters("countries")}</Chart.Filter>
          <Chart.Content>
            {renderPieChart(countriesSelector, (value) =>
              formatAsNumberWith(0, value)
            )}
          </Chart.Content>
        </Chart>
        <Chart className={styles.chart} title="Gerätekategorie">
          <Chart.Filter>{renderPieChartFilters("deviceCategory")}</Chart.Filter>
          <Chart.Content>
            {renderPieChart(deviceCategoriesSelector, (value) =>
              formatAsNumberWith(0, value)
            )}
          </Chart.Content>
        </Chart>
        <Chart className={styles.chart} title="Channels">
          <Chart.Filter>
            {renderPieChartFilters("channelGrouping")}
          </Chart.Filter>
          <Chart.Content>
            {renderPieChart(channelGroupingSelector, (value) =>
              formatAsNumberWith(0, value)
            )}
          </Chart.Content>
        </Chart>
      </div>
      <div className={styles.charts}>
        <Chart className={styles.table} title="Portale">
          <Chart.Content>
            {isSuccessReferralsTableData && referralsTableData && (
              <>
                {!referralsTableData?.rows.length ? (
                  <div className={styles.status}>{emptyResultMessage}</div>
                ) : (
                  <TopReferralsTable data={referralsTableData} />
                )}
              </>
            )}
            {isValidatingReferralsTableData && (
              <div className={styles.status}>
                <LoadingSpinner />
              </div>
            )}
            {referralsTableDataError && (
              <div className={styles.status}>
                <ErrorText text={errorMessage} />
              </div>
            )}
          </Chart.Content>
        </Chart>
      </div>
    </div>
  );
};

export default WebsiteAnalysis;
