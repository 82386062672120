import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import { FunctionComponent } from "react";
import styles from "./Chart.module.css";

type ChartProps = PropsWithChildren<{
  title: string;
  className?: string;
}>;

interface ChartSubComponents {
  Filter: React.FunctionComponent<FilterProps>;
  Content: React.FunctionComponent<ContentProps>;
}

const Chart: FunctionComponent<ChartProps> & ChartSubComponents = ({
  title,
  className,
  children,
}) => (
  <div className={classNames(styles.chart, className)}>
    <h3 className={styles.heading}>{title}</h3>
    {children}
  </div>
);

type FilterProps = PropsWithChildren<{
  className?: string;
}>;

const Filter = ({ className, children }: FilterProps) => (
  <div className={classNames(styles.filter, className)}>{children}</div>
);

Chart.Filter = Filter;

type ContentProps = PropsWithChildren;

const Content = ({ children }: ContentProps) => <>{children}</>;

Chart.Content = Content;

export default Chart;
