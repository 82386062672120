import { useMe } from "../http/serviceportalApi";
import DocumentsTab from "./documents/DocumentsTab";
import Alert from "./ui/Alert";
import Page from "./ui/Page";

const PrivateHouseholdDocuments = () => {
  const { data, isValidating } = useMe();

  return (
    <Page title="Dokumente" isLoading={isValidating}>
      <Alert showUntil={new Date(2024, 6, 23, 18, 0)}>
        Aufgrund von Wartungsarbeiten werden die Dokumente heute von 13:30 Uhr
        bis voraussichtlich 18:00 Uhr nicht einsehbar sein.
      </Alert>
      <DocumentsTab luid={data?.privateHousehold?.luid!} archive="payroll" />
    </Page>
  );
};

export default PrivateHouseholdDocuments;
