import { useCallback, useSyncExternalStore } from "react";

type MediaQuery = `(${string}:${string})`;

const getSnapshot = (query: MediaQuery) => window.matchMedia(query).matches;

const subscribe = (onChange: () => void, query: MediaQuery) => {
  const mql = window.matchMedia(query);
  try {
    mql.addEventListener("change", onChange);
  } catch (_) {
    try {
      // Safari < 14 does not support addEventListener on MediaQueryList objects
      mql.addListener(onChange);
    } catch (e) {
      console.error(e);
    }
  }

  return () => {
    try {
      mql.removeEventListener("change", onChange);
    } catch (_) {
      try {
        // Safari < 14 does not support removeEventListener on MediaQueryList objects
        mql.removeListener(onChange);
      } catch (e) {
        console.error(e);
      }
    }
  };
};

export const useMediaQuery = (query: MediaQuery) => {
  const subscribeMediaQuery = useCallback(
    (onChange: () => void) => subscribe(onChange, query),
    [query]
  );

  const matches = useSyncExternalStore(subscribeMediaQuery, () =>
    getSnapshot(query)
  );

  return matches;
};
