import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import useListFilters from "../../hooks/useListFilters";
import {
  SP,
  deleteAdminUser,
  useAdminUsers,
  useMe,
} from "../../http/serviceportalApi";
import { Permission } from "../../types";
import { getRemoteDataStatus } from "../../utils";
import SvgDelete from "../icons/Delete";
import ModalDialog from "../ui/ModalDialog";
import Page from "../ui/Page";
import Table from "../ui/Table";
import TableButton, { TableButtonCell } from "../ui/TableButton";
import styles from "./AdminUsers.module.css";

const AdminUsers = () => {
  const filters = useListFilters({ isSearchable: true });
  const aborter = useRef(new AbortController());
  const {
    data: adminUsers,
    isValidating,
    error,
    mutate,
  } = useAdminUsers(filters.state, aborter.current.signal);
  const items = adminUsers?.items ?? [];
  const total = adminUsers?.total ?? 0;
  const status = getRemoteDataStatus({ isValidating, error });

  const { data: me } = useMe();
  const canWrite = me && me.permissions.includes(Permission.UsersWrite);

  return (
    <Page title="Benutzer">
      <Table
        filters={filters}
        isTall={true}
        searchOptions={{
          placeholder: "Suche Benutzer",
          onChange: () => {
            aborter.current.abort();
            aborter.current = new AbortController();
          },
        }}
        head={
          <tr>
            <th align="left">Name</th>
            {canWrite && <th></th>}
          </tr>
        }
        body={items.map((user, index) => {
          const displayName = user.name
            ? user.name
            : `${user.firstName} ${user.lastName}`;
          return (
            <tr key={index}>
              <td>
                <Link to={user.id}>{displayName}</Link>
                <span className={styles.email}>{user.email}</span>
              </td>
              {canWrite && (
                <TableButtonCell>
                  <DeleteUserButton
                    user={user}
                    onSubmit={() =>
                      deleteAdminUser(user.id).then(() => {
                        if (!adminUsers) return;
                        mutate({
                          ...adminUsers,
                          total: total - 1,
                          items: adminUsers.items.filter(
                            (u) => u.id !== user.id
                          ),
                        });
                      })
                    }
                  />
                </TableButtonCell>
              )}
            </tr>
          );
        })}
        total={total}
        remoteDataStatus={status}
      />
    </Page>
  );
};

interface DeleteUserButtonProps {
  user: SP.User;
  companyName?: string;
  onSubmit: () => Promise<any>;
}

const DeleteUserButton = ({
  user: { email },
  onSubmit,
}: DeleteUserButtonProps) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  return (
    <>
      <ModalDialog
        title="Sind Sie sich sicher?"
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        actions={[
          {
            title: "Abbrechen",
            type: "secondary",
            onClick: () => setModalOpen(false),
          },
          {
            title: "Entfernen",
            type: "destructive",
            isSubmitting: isSubmitting,
            onClick: () => {
              setSubmitting(true);
              onSubmit()
                .then(() => setModalOpen(false))
                .catch(() => setSubmitting(false));
            },
          },
        ]}
      >
        <p>
          Wollen Sie <strong>{email}</strong> wirklich entfernen?
        </p>
      </ModalDialog>
      <TableButton glyph={SvgDelete} onClick={() => setModalOpen(true)} />
    </>
  );
};

export default AdminUsers;
