import classNames from "classnames";
import { PropsWithChildren } from "react";
import styles from "./ButtonGroup.module.css";

const ButtonGroup = ({ children }: PropsWithChildren) => (
  <div className={styles.buttonGroup}>{children}</div>
);

type ButtonProps = PropsWithChildren<{
  onClick?: () => void;
  isActive: boolean;
  disabled?: boolean;
}>;

const Button = ({
  onClick,
  isActive,
  disabled = false,
  children,
}: ButtonProps) => (
  <button
    className={classNames(styles.button, { [styles.active]: isActive })}
    type="button"
    disabled={disabled}
    onClick={onClick}
  >
    {children}
  </button>
);

ButtonGroup.Button = Button;

export default ButtonGroup;
