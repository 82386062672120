import { Evaluation } from "../../types";

interface Props {
  id?: string;
  value?: Evaluation;
  onChange: (value: Evaluation) => void;
}

const EvaluationSelect = ({ id, value, onChange }: Props) => (
  <select
    id={id}
    value={value}
    onChange={(e) => onChange(e.target.value as Evaluation)}
  >
    <option value="competence">Betriebswirtschaftlich</option>
    <option value="fiscal">Steuerrechtlich</option>
  </select>
);

export default EvaluationSelect;
