import classNames from "classnames";
import { useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { useMember } from "../../context/memberContext";
import {
  VV,
  getSignedAgreement,
  useAgreements,
} from "../../http/vertragsverwaltungApi";
import i18n from "../../i18n";
import { formatLongDate, getRemoteDataStatus } from "../../utils";
import SvgWarningAmber from "../icons/WarningAmber";
import Icon from "../ui/Icon";
import PdfBlobPreview from "../ui/PdfBlobPreview";
import Table from "../ui/Table";
import styles from "./AgreementsTable.module.css";

interface AgreementRowProps {
  memberId: VV.MemberId;
  agreement: VV.Agreement;
  canNavigateToDetail: (type: VV.AgreementType) => boolean;
}

const AgreementRow = ({
  memberId,
  agreement,
  canNavigateToDetail,
}: AgreementRowProps) => {
  const { id, version, name, signee, signedAt, type, status } = agreement;
  const [pdf, setPdf] = useState<Blob>();

  return (
    <tr className={classNames({ [styles.unread]: status === "Published" })}>
      <td>{status === "Published" && "•"}</td>
      <td>{name}</td>
      <td>{signee}</td>
      <td>{signedAt && formatLongDate(signedAt)}</td>
      <td>{i18n.getAgreementStatus(status)}</td>
      <td className={styles.actionCell}>
        {status === "Published" &&
          (canNavigateToDetail(type) ? (
            <NavLink
              to={`/vertraege-und-preise/${id}/${version}/${type === "Master"}`}
            >
              Lesen und Bestätigen
            </NavLink>
          ) : (
            <span
              className={styles.tableCellDisabled}
              title="Bitte bestätigen Sie zuerst den Rahmenvertrag."
            >
              Lesen und Bestätigen
              <Icon glyph={SvgWarningAmber} className={styles.icon} />
            </span>
          ))}
        {signedAt !== null && (
          <a
            href={"#"}
            onClick={async (e) => {
              e.preventDefault();
              const blob = await getSignedAgreement(
                memberId,
                id,
                version,
                name
              );
              setPdf(blob);
            }}
          >
            PDF
          </a>
        )}
        <PdfBlobPreview title={name} blob={pdf} />
      </td>
    </tr>
  );
};

const AgreementsTable = () => {
  const { member } = useMember();
  const aborter = useRef(new AbortController());
  const { data, isValidating, error } = useAgreements(
    member?.id,
    aborter.current.signal
  );
  // const isSuccess = !isValidating && !error;
  const agreements = data ?? [];

  return (
    <Table
      head={
        <tr>
          <th style={{ width: "1%" }}></th>
          <th align="left" style={{ width: "25%" }}>
            Vertrag
          </th>
          <th align="left" style={{ width: "30%" }}>
            Mitglied / Betrieb
          </th>
          <th align="left" style={{ width: "15%" }}>
            Bestätigt am
          </th>
          <th align="left" style={{ width: "15%" }}>
            Status
          </th>
          <th></th>
        </tr>
      }
      body={agreements.map((agreement) => (
        <AgreementRow
          key={agreement.id}
          memberId={member!.id}
          agreement={agreement}
          canNavigateToDetail={(type: VV.AgreementType) => {
            const masterAgreement = agreements.find(
              ({ type }) => type === "Master"
            );
            return type === "Master" || masterAgreement?.status !== "Published";
          }}
        />
      ))}
      total={agreements.length}
      remoteDataStatus={getRemoteDataStatus({ isValidating, error })}
    />
  );
};

export default AgreementsTable;
