import { useState } from "react";
import Page from "../ui/Page";
import ModalDialog from "../ui/ModalDialog";
import AgreementsTable from "./AgreementsTable";
import DocumentsTable from "./DocumentsTable";
import styles from "./Agreements.module.css"

const Agreements = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <Page
      title="Verträge &amp; Preise"
      description={
        <>
          In den nachfolgenden Tabellen finden Sie die aktuelle/n Preisliste/n,
          sowie jene Verträge, welche Ihnen von der Abteilung Web Marketing zur
          Nutzung verschiedener Produkte und Dienstleistungen freigeschaltet
          wurden. Neben den offenen Verträgen können Sie hier auch bereits
          bestätigte und gekündigte Verträge einsehen.
          <br />
          <br />
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setModalOpen(true);
            }}
          >
            Hinweis
          </a>
        </>
      }
    >
      <div className={styles.tables}>
        <DocumentsTable />
        <AgreementsTable />
      </div>
      <ModalDialog
        title="Hinweis"
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
      >
        <p>
          Bevor die Nutzungsverträge eingesehen und bestätigt werden können,
          muss der Rahmenvertrag gelesen und abgeschlossen werden. Dies erfolgt
          in telematischer Form, mit Klick auf den „Bestätige jetzt“. Der
          Rahmenvertrag beinhaltet die allgemeinen Vertragsbedingungen, die
          Nutzungsverträge regeln hingegen die Nutzung der verschiedenen
          Produkte und dazugehörigen Leistungen. Auch diese werden mit Klick auf
          „Bestätige jetzt“ abgeschlossen. Die entsprechenden Kosten können in
          der Preisliste eingesehen werden.
          <br />
          <br />
          Im Anschluss an die Bestätigung eines Vertrages erhalten Sie eine
          automatische E-Mail mit der entsprechenden Vertragskopie.
          <br />
          <br />
          Alle weiteren Details finden Sie im Rahmenvertrag sowie in den
          entsprechenden Nutzungsverträgen.
          <br />
          <br />
          Für weitere Informationen steht Ihnen die Abteilung Web Marketing des
          HGV Bozen gerne zur Verfügung!
        </p>
      </ModalDialog>
    </Page>
  );
};

export default Agreements;
