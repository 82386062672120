import { SP } from "../../http/serviceportalApi";
import Badge from "../ui/Badge";

interface Props {
  invite: SP.Invite;
}

const InviteStatus = ({ invite }: Props) => {
  const now = new Date();
  return invite.declinedAt ? (
    <Badge color="red">Abgelehnt</Badge>
  ) : new Date(invite.expiresAt) > now ? (
    <Badge color="yellow">Ausstehend</Badge>
  ) : (
    <Badge color="red">Abgelaufen</Badge>
  );
};

export default InviteStatus;
