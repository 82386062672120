import classNames from "classnames";
import { useState } from "react";
import i18n from "../../i18n";
import { App } from "../../types";
import SvgBullet from "../icons/Bullet";
import Button from "./Button";
import styles from "./Services.module.css";

interface Props {
  items: ServiceItemProps[];
}

const Services = ({ items }: Props) => (
  <div className={styles.services}>
    {items.map((item) => (
      <ServiceItem key={item.id} {...item} />
    ))}
  </div>
);

type ServiceItemProps = App;

const ServiceItem = ({
  name,
  department,
  description,
  isVisible,
  publishedAt,
  onNavigate,
}: ServiceItemProps) => {
  const [isLoading, setLoading] = useState(false);

  if (!isVisible) return null;

  const today = new Date();
  const twoMonthsAgo = new Date();
  twoMonthsAgo.setMonth(today.getMonth() - 2);

  const isRecentlyPublished = publishedAt && publishedAt >= twoMonthsAgo;

  return (
    <article className={classNames(styles.service, styles[department])}>
      <span className={styles.department}>
        <SvgBullet
          className={classNames(styles.departmentIcon, styles[department])}
        />
        {i18n.departments[department].title}
      </span>
      {isRecentlyPublished && <span className={styles.new}>Neu</span>}
      <h3 className={styles.heading}>{name}</h3>
      <div className={styles.description}>{description}</div>
      <div className={styles.button}>
        <a
          tabIndex={-1}
          onClick={async (e) => {
            e.preventDefault();
            setLoading(true);
            const result = onNavigate();
            if (result instanceof Promise) {
              await result;
            }
            setLoading(false);
          }}
        >
          <Button type="primary" isLoading={isLoading}>
            Öffnen
          </Button>
        </a>
      </div>
    </article>
  );
};

export default Services;
