import { PropsWithChildren, useRef } from "react";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import ModalRoot from "../ModalRoot";
import SvgClose from "../icons/Close";
import Button, { ButtonType } from "./Button";
import Icon from "./Icon";
import styles from "./ModalDialog.module.css";
import classNames from "classnames";

type Props = PropsWithChildren<{
  isOpen: boolean;
  onClose: () => void;
  title: string;
  actions?: ActionButton[];
  size?: "small" | "large";
}>;

interface ActionButton {
  title: string;
  type: ButtonType;
  isSubmitting?: boolean;
  onClick: () => void;
}

const ModalDialog = ({
  children,
  isOpen,
  onClose,
  title,
  actions,
  size = "small",
}: Props) => {
  const dialogRef = useRef<HTMLDialogElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const open = () => !dialogRef.current?.open && dialogRef.current?.showModal();
  const close = () => dialogRef.current?.open && dialogRef.current?.close();

  isOpen ? open() : close();

  useOnClickOutside(contentRef, close);

  return (
    <ModalRoot>
      <dialog
        ref={dialogRef}
        className={classNames(styles.dialog, {
          [styles.large]: size === "large",
        })}
        onClose={onClose}
        onClick={(e) => e.stopPropagation()}
      >
        <div ref={contentRef} className={styles.content}>
          <div className={styles.header}>
            <div className={styles.title}>{title}</div>
            <button className={styles.closeButton} onClick={close}>
              <Icon className={styles.icon} glyph={SvgClose} />
            </button>
          </div>

          {/* this condition causes the reset of the children's state on close */}
          {isOpen && children}

          {actions && (
            <div className={styles.buttons}>
              {actions.map(({ onClick, type, title, isSubmitting }, index) => (
                <Button
                  key={index}
                  type={type}
                  isLoading={isSubmitting}
                  buttonProps={{
                    disabled: isSubmitting,
                    onClick,
                  }}
                >
                  {title}
                </Button>
              ))}
            </div>
          )}
        </div>
      </dialog>
    </ModalRoot>
  );
};

export default ModalDialog;
