import welcomingSVG from "../../assets/welcoming.svg";
import { useApps } from "../../context/appsContext";
import LoadingSpinner from "../ui/LoadingSpinner";
import Page from "../ui/Page";
import Services from "../ui/Services";
import Footer from "./Footer";
import styles from "./Home.module.css";

const Home = () => {
  const { recentlyUsedApps, isValidating } = useApps();

  return (
    <Page
      title="Willkommen im HGV Serviceportal"
      description="Hier finden Kunden der HGV-Dienstleistungsabteilungen exklusive Serviceangebote & Dienstleistungen. Zudem können Sie rund um die Uhr Lohnauswertungen abrufen, individuelle Arbeitsverträge ausdrucken, den Umsatz und die Kostenentwicklung analysieren und alle Leistungskennzahlen Ihres Betriebes einsehen und vieles mehr."
    >
      <h2 className={styles.title}>{"Ihre zuletzt benutzten Anwendungen"}</h2>
      {isValidating ? (
        <div className={styles.status}>
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <Services items={recentlyUsedApps} />
          {recentlyUsedApps.length === 0 && (
            <div className={styles.noApps}>
              <img src={welcomingSVG} className={styles.illustration} />
              Hier werden Sie die Anwendungen finden, die Sie kürzlich verwendet
              haben.
            </div>
          )}
        </>
      )}
      <Footer />
    </Page>
  );
};

export default Home;
