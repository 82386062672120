import { SP } from "../http/serviceportalApi";
import {
  difference,
  indexed,
  isSubset,
  range,
  takeWhile,
  union,
} from "../utils";

const getLevelSortOrder = (level: SP.Level) => {
  switch (level) {
    case "level1":
      return 4;
    case "level2":
      return 3;
    case "level3":
      return 2;
    case "level4":
      return 1;
  }
};

export const getCollapsedRows = (data: SP.Return<any>[]) =>
  new Set(
    indexed(data)
      .filter(([x]) => getLevelSortOrder(x.level) < getLevelSortOrder("level2"))
      .map(([_, index]) => index)
  );

export const calculateCollapsedRows = (
  index: number,
  data: SP.Return<any>[],
  collapsedRows: Set<number>
) => {
  const indexedData = indexed(data);
  const indexedRow = indexedData[index];
  if (indexedRow) {
    const [row, idx] = indexedRow;
    const newItemsToCollapse =
      row.type === "sum" && row.level === "level3"
        ? new Set(
            [
              ...takeWhile(
                ([x]) =>
                  getLevelSortOrder(x.level) < getLevelSortOrder(row.level),
                indexedData.slice(idx + 1)
              ),
            ].map(([_, index]) => index)
          )
        : new Set(
            [
              ...takeWhile(
                ([x]) =>
                  getLevelSortOrder(x.level) < getLevelSortOrder(row.level),
                indexedData.slice(0, idx).reverse()
              ),
            ].map(([_, index]) => index)
          );
    if (isSubset(newItemsToCollapse, collapsedRows)) {
      return difference(collapsedRows, newItemsToCollapse);
    } else {
      return union(newItemsToCollapse, collapsedRows);
    }
  } else {
    return collapsedRows;
  }
};

export const isRowExpandable = (index: number, data: SP.Return<any>[]) => {
  const previous = data[index - 1];
  const current = data[index];
  const next = data[index + 1];
  if (current && next && current.level === "level3" && next.type === "row")
    return true;
  else if (
    current &&
    previous &&
    current.type === "sum" &&
    !(previous.type === "sum")
  )
    return true;
  else return false;
};

export const getExpandedSums = (data: SP.Return<any>[]) =>
  new Set(
    range(0, data.length - 1).filter((index) => isRowExpandable(index, data))
  );

export const calculateExpandableSums = (
  index: number,
  data: SP.Return<any>[],
  expandedSums: Set<number>
) => {
  if (!isRowExpandable(index, data)) {
    return expandedSums;
  } else if (expandedSums.has(index)) {
    expandedSums.delete(index);
    return expandedSums;
  } else {
    return expandedSums.add(index);
  }
};

export const getExpandedState = (
  isExpanded: boolean,
  isCollapsed: boolean,
  ret: SP.Return<any>
) => {
  const level = getLevelSortOrder(ret.level);
  switch (ret.type) {
    case "row":
      return level < getLevelSortOrder("level2") && !isCollapsed;
    case "sum":
      if (level <= getLevelSortOrder("level3") && !isCollapsed) return true;
      else return isExpanded;
  }
};
