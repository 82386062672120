import { Fragment, useEffect, useRef, useState } from "react";
import { useMember } from "../../context/memberContext";
import {
  SP,
  getMonthlyDataExcel,
  useMonthlyData,
} from "../../http/serviceportalApi";
import {
  calculateCollapsedRows,
  calculateExpandableSums,
  getCollapsedRows,
  getExpandedSums,
  isRowExpandable,
} from "../../selectors/profitLosses";
import { Evaluation, Permission } from "../../types";
import {
  configIncompleteMsg,
  downloadFile,
  getAccountingPeriodUntil,
  getLongMonthName,
  getRemoteDataStatus,
  indexed,
} from "../../utils";
import Button from "../ui/Button";
import CompanySelect from "../ui/CompanySelect";
import Table from "../ui/Table";
import ToggleSwitch from "../ui/ToggleSwitch";
import EvaluationSelect from "./EvaluationSelect";
import styles from "./MonthlyAnalysis.module.css";
import MonthlyAnalysisRow from "./MonthlyAnalysisRow";
import PeriodSelect from "./PeriodSelect";

const customPeriod = (): SP.Period => {
  const untilDate = getAccountingPeriodUntil();
  const fromDate = new Date(untilDate);
  fromDate.setMonth(fromDate.getMonth() - 5);
  return {
    from: { year: fromDate.getFullYear(), month: fromDate.getMonth() + 1 },
    until: { year: untilDate.getFullYear(), month: untilDate.getMonth() + 1 },
  };
};

const getYearMonthsFromPeriod = ({
  from: { year: fromYear, month: fromMonth },
  until: { year: untilYear, month: untilMonth },
}: SP.Period): SP.YearMonth2[] => {
  const from = new Date(fromYear, fromMonth - 1, 1);
  const until = new Date(untilYear, untilMonth - 1, 1);
  const res: SP.YearMonth2[] = [];
  for (let x = new Date(from); x <= until; x.setMonth(x.getMonth() + 1)) {
    res.push([x.getFullYear(), x.getMonth() + 1]);
  }
  return res;
};

const areAllValuesZero = (
  showPreviousYear: boolean,
  profitLoss: SP.MonthlyDataRow
) => {
  let isValueZero = ([value]: SP.ValuePercent) => value === 0;
  return [...profitLoss.range1, ...(showPreviousYear ? profitLoss.range2 : [])]
    .map(([_, snd]) => snd)
    .every(isValueZero);
};

const MonthlyAnalysis = () => {
  const { member } = useMember();
  const [period, setPeriod] = useState(customPeriod());
  const [yearMonths, setYearMonths] = useState<SP.YearMonth2[]>([]);
  const [evaluation, setEvaluation] = useState<Evaluation>("competence");
  const [companyId, setCompanyId] = useState<SP.CompanyId | undefined>(
    undefined
  );
  const [showAllRows, setShowAllRows] = useState(false);
  const [showPreviousYear, setShowPreviousYear] = useState(false);
  const [collapsedRows, setCollapsedRows] = useState<Set<number>>();
  const [expandedSums, setExpandedSums] = useState(new Set<number>());
  const [isExcelDownloading, setExcelDownloading] = useState(false);

  const aborter = useRef(new AbortController());
  const { data, isValidating, error } = useMonthlyData(
    member?.id,
    member?.seacNumber,
    companyId,
    period,
    true,
    evaluation === "competence",
    aborter.current.signal
  );
  const isSuccess = !isValidating && !error;
  const status = getRemoteDataStatus({ isValidating, error });
  const profitLosses = data ?? [];

  useEffect(() => {
    setYearMonths(getYearMonthsFromPeriod(period).reverse());
  }, [period]);

  useEffect(() => {
    if (!isSuccess) return;
    setCollapsedRows(getCollapsedRows(profitLosses));
  }, [isSuccess]);

  useEffect(() => {
    if (!isSuccess) return;
    if (collapsedRows === undefined) return;

    const collapsed =
      collapsedRows.size > 0
        ? new Set<number>()
        : getCollapsedRows(profitLosses);
    setCollapsedRows(collapsed);

    const expanded =
      collapsed.size > 0 ? new Set<number>() : getExpandedSums(profitLosses);
    setExpandedSums(expanded);
  }, [showAllRows]);

  if (!member) return null;

  return (
    <>
      {!member?.seacNumber ? (
        <div className={styles.status}>{configIncompleteMsg}</div>
      ) : (
        <div className={styles.monthlyAnalysis}>
          <div className={styles.filters}>
            <div className={styles.filtersTop}>
              <div className={styles.filterSetting}>
                <label htmlFor="company">Betriebsauswahl</label>
                <CompanySelect
                  id="company"
                  className={styles["company-select"]}
                  allowedPermissions={[Permission.AccountingsRead]}
                  value={companyId}
                  onChange={(e) =>
                    setCompanyId(
                      e.target.value !== "all"
                        ? parseInt(e.target.value, 10)
                        : undefined
                    )
                  }
                />
              </div>
              <div className={styles.filterSetting}>
                <label htmlFor="evaluation">Auswertung</label>
                <EvaluationSelect id="evaluation" onChange={setEvaluation} />
              </div>
              <div className={styles.filterSetting}>
                <label>Zeitraum</label>
                <PeriodSelect
                  period={period}
                  periodUntil={getAccountingPeriodUntil()}
                  onChange={setPeriod}
                />
              </div>
            </div>
            <div className={styles.filtersBottom}>
              <div className={styles.toggleSwitches}>
                <div className={styles.filterSetting}>
                  <label htmlFor="showAllRows">Zeige alle Zeilen</label>
                  <ToggleSwitch
                    id="showAllRows"
                    checked={showAllRows}
                    onChange={(e) => setShowAllRows(e.target.checked)}
                  />
                </div>
                <div className={styles.filterSetting}>
                  <label htmlFor="showPreviousYear">Zeige Vorjahr</label>
                  <ToggleSwitch
                    id="showPreviousYear"
                    checked={showPreviousYear}
                    onChange={(e) => setShowPreviousYear(e.target.checked)}
                  />
                </div>
              </div>
              <div>
                <Button
                  isLoading={isExcelDownloading}
                  buttonProps={{
                    className: styles.downloadButton,
                    disabled: !member.seacNumber,
                    onClick: async () => {
                      if (!member.seacNumber) return;
                      setExcelDownloading(true);
                      const res = await getMonthlyDataExcel(
                        member.id,
                        member.seacNumber,
                        companyId,
                        period,
                        true,
                        evaluation === "competence"
                      );
                      await downloadFile(res);
                      setExcelDownloading(false);
                    },
                  }}
                >
                  Excel-Dokument herunterladen
                </Button>
              </div>
            </div>
          </div>
          {collapsedRows !== undefined && (
            <Table
              head={
                <>
                  <tr>
                    <th rowSpan={2} style={{ width: "4px", padding: 0 }} />
                    <th rowSpan={2} align="left" className={styles.labelHeader}>
                      Bezeichnung
                    </th>
                    {yearMonths.map(([_, month]) => (
                      <th
                        key={month}
                        align="right"
                        colSpan={showPreviousYear ? 2 : 1}
                      >
                        {getLongMonthName(month)}
                      </th>
                    ))}
                  </tr>
                  {showPreviousYear && (
                    <tr>
                      {yearMonths.map(([year, month]) => (
                        <Fragment key={`${year}-${month}`}>
                          <th>{year}</th>
                          <th>{year - 1}</th>
                        </Fragment>
                      ))}
                    </tr>
                  )}
                </>
              }
              body={indexed(profitLosses)
                .filter(
                  ([x, index]) =>
                    !(
                      collapsedRows.has(index) ||
                      (x.valueType === "currency" &&
                        areAllValuesZero(showPreviousYear, x.value))
                    )
                )
                .map(([x, index]) => (
                  <MonthlyAnalysisRow
                    key={index}
                    isExpandable={isRowExpandable(index, profitLosses)}
                    isExpanded={expandedSums.has(index)}
                    isCollapsed={collapsedRows.has(index)}
                    expandCollapse={() => {
                      const expanded = calculateExpandableSums(
                        index,
                        profitLosses,
                        expandedSums
                      );
                      const collapsed = calculateCollapsedRows(
                        index,
                        profitLosses,
                        collapsedRows
                      );
                      setExpandedSums(expanded);
                      setCollapsedRows(collapsed);
                    }}
                    profitLoss={x}
                    showPreviousYear={showPreviousYear}
                    yearMonths={yearMonths}
                  />
                ))}
              total={profitLosses.length}
              remoteDataStatus={status}
            />
          )}
        </div>
      )}
    </>
  );
};

export default MonthlyAnalysis;
