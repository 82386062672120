import Page from "../ui/Page";

const ArbeitAufAbruf = () => {
  return (
    <Page
      title="Arbeit auf Abruf - Vorabmeldung des Abrufs"
      backLinkText="Zurück zu Personalberatung"
      narrow={true}
    >
      <div>
        <p>
          Für jede:n Beschäftigte:n, welcher mit dem Vertrag „Arbeit auf Abruf“
          eingestellt ist, muss jeweils vor Arbeitsbeginn eine zusätzliche
          Mitteilung an das Arbeitsministerium erfolgen. Diese muss vom
          Arbeitgeber selbst mittels{" "}
          <a
            href="https://assets-eu-01.kc-usercontent.com/a0e24cdf-7b6b-010e-94fe-bdbecae481ee/4ef41600-6f1e-453d-9c8c-1ebde5ddc422/UNI_Intermittenti.pdf"
            target="_blank"
          >
            Online-Formular „UNI_INTERMITTENTE“
          </a>{" "}
          als XML-File an die E-Mailadresse{" "}
          <a href="mailto:intermittenti@pec.lavoro.gov.it">
            intermittenti@pec.lavoro.gov.it
          </a>{" "}
          geschickt werden. Die Anleitung dafür finden Sie{" "}
          <a
            href="https://www.hgv.it/index.php?eID=dumpFile&t=f&f=740&token=28d28e8bd3f0ed0cc35ab3572252f3ba1d918e50"
            target="_blank"
          >
            hier
          </a>
          .
        </p>
        <p>
          Beispiel für die zusätzliche Meldung Der Arbeitsvertrag auf Abruf
          wurde am 1. August abgeschlossen und der:die Mitarbeiter:in scheint
          beim Arbeitsamt als regulär gemeldet auf. Am 22. August wird der:die
          Mitarbeiter:in ab 11.00 Uhr, und am 28. August ab 19.00 Uhr gerufen.
          In diesem Fall muss jeder einzelne Tag mit dem Online-Formular
          gemeldet werden.
        </p>
        <p>
          <b>Klarstellung:</b> Die Mitteilung, wann der:die Mitarbeiter:in seine
          Arbeit antritt, muss unmittelbar vor Arbeitsbeginn erfolgen. Ist z.B.
          der Arbeitsbeginn um 11.00 Uhr, muss die Mitteilung innerhalb 10.59
          Uhr erfolgen.
        </p>
        <p>
          <b>Annullierung:</b> Eine Annullierung, weil der:die Mitarbeiter:in
          kurzfristig abgesagt hat, und die Mitteilung bereits versendet wurde,
          muss ebenfalls unmittelbar am selben Arbeitstag (nicht erst am
          Folgetag) vorgenommen werden.
        </p>
        <p>
          <b>Strafen bei Unterlassung:</b> Für jede nicht erfolgte Mitteilung,
          wann der der:die Mitarbeiter:in seine Arbeit antritt bzw. nicht
          antritt, sieht das Gesetz Strafen in Höhe von 400 Euro bis 2.400 Euro
          vor.
        </p>
        <p>
          <b>Präsenzregister:</b> Die effektiv gearbeiteten Stunden müssen vom
          Arbeitgeber monatlich im Stundenregister eingetragen werden.
        </p>
      </div>
    </Page>
  );
};

export default ArbeitAufAbruf;
