import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { SP } from "../../http/serviceportalApi";
import { ViewMode } from "../../types";
import {
  formatLongDate,
  formatLongDateTime,
  formatShortCalendarWeek,
  formatShortDayMonth,
  formatShortYearMonth,
  getEndOfIsoWeek,
  getLastOfMonth,
  getStartOfIsoWeek,
  notAvailableMsg,
} from "../../utils";
import SvgBullet from "../icons/Bullet";
import ChartTooltip from "../ui/ChartTooltip";

interface Props {
  data: SP.LineChartData;
  formatter?: (value: number) => string;
  viewMode: ViewMode;
}

const getDateRange = (start: string, end: string) =>
  `${formatLongDateTime(start)} - ${formatLongDateTime(end)}`;

const getWeekRange = (date: string) =>
  getDateRange(getStartOfIsoWeek(date), getEndOfIsoWeek(date));

const getMonthRange = (date: string) =>
  getDateRange(date, getLastOfMonth(new Date(date)).toISOString());

const formatXAxisTick = (viewMode: ViewMode, date: string) => {
  if (date === "") return notAvailableMsg;
  switch (viewMode) {
    case "day":
      return formatShortDayMonth(date);
    case "week":
      return formatShortCalendarWeek(date);
    case "month":
    default:
      return formatShortYearMonth(date);
  }
};

const formatLabel = (viewMode: ViewMode, date: string) => {
  switch (viewMode) {
    case "day":
      return formatLongDate(date);
    case "week":
      return getWeekRange(date);
    case "month":
    default:
      return getMonthRange(date);
  }
};

const MetricsLineChart = ({ data: { rows }, formatter, viewMode }: Props) => (
  <ResponsiveContainer width="100%" height="100%">
    <LineChart data={rows}>
      <CartesianGrid
        vertical={false}
        stroke="var(--ci-light-grey)"
        strokeDasharray="3 3"
      />
      <XAxis
        dataKey="date"
        axisLine={{ stroke: "var(--ci-light-grey)" }}
        tickLine={{ stroke: "var(--ci-light-grey)" }}
        stroke="var(--ci-grey)"
        strokeWidth="2px"
        tickFormatter={(value) => formatXAxisTick(viewMode, value)}
      />
      <YAxis
        tickFormatter={(value) => formatter?.(value) ?? value}
        axisLine={{ stroke: "var(--ci-light-grey)" }}
        tickLine={{ stroke: "var(--ci-light-grey)" }}
        stroke="var(--ci-grey)"
        strokeWidth="2px"
        width={111}
      />
      <Tooltip
        content={
          <ChartTooltip
            labelFormatter={(label) =>
              label && formatLabel(viewMode, label.toString())
            }
            formatter={(value: number) => formatter?.(value) ?? value}
          />
        }
        cursor={{ stroke: "var(--ci-light-grey)" }}
      />
      <Legend
        iconSize={24}
        wrapperStyle={{ paddingTop: "1em" }}
        payload={[
          {
            value: "Betrieb",
            color: "var(--ci-grey)",
            legendIcon: <SvgBullet fill="var(--charts-3)" />,
          },
          {
            value: "Benchmark",
            color: "var(--ci-grey)",
            legendIcon: <SvgBullet fill="var(--charts-1)" />,
          },
        ]}
      />
      <Line
        dataKey="ownValue"
        name="Betrieb"
        stroke="var(--charts-3)"
        strokeWidth={2}
        dot={false}
      />
      <Line
        dataKey="filter1.average"
        name="Benchmark"
        stroke="var(--charts-1)"
        strokeWidth={2}
        dot={false}
      />
    </LineChart>
  </ResponsiveContainer>
);

export default MetricsLineChart;
