import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { SP } from "../../http/serviceportalApi";
import { colorCycle, range } from "../../utils";
import SvgBullet from "../icons/Bullet";
import ChartTooltip from "../ui/ChartTooltip";
interface Props {
  period: SP.Period;
  data: SP.AnnualComparisonRow<SP.TwoYearRow>[];
  formatter?: (value: number) => string;
}

const xAxisDataKey: keyof SP.AnnualComparisonRow<SP.TwoYearRow> = "label";

const AnnualComparisonBarChart = ({
  period: {
    from,
    until,
    until: { year: untilYear },
  },
  data,
  formatter,
}: Props) => {
  const yearsRange = range(0, until.year - from.year);
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart data={data} margin={{ top: 50 }}>
        <CartesianGrid
          vertical={false}
          stroke="var(--ci-light-grey)"
          strokeDasharray="3 3"
        />
        <XAxis
          dataKey={xAxisDataKey}
          axisLine={{ stroke: "var(--ci-light-grey)" }}
          tickLine={{ stroke: "var(--ci-light-grey)" }}
          stroke="var(--ci-grey)"
          strokeWidth="2px"
        />
        <YAxis
          tickFormatter={(value) => formatter?.(value) ?? value}
          axisLine={{ stroke: "var(--ci-light-grey)" }}
          tickLine={{ stroke: "var(--ci-light-grey)" }}
          stroke="var(--ci-grey)"
          strokeWidth="2px"
          width={111}
        />
        <Tooltip
          content={
            <ChartTooltip
              formatter={(value: number) => formatter?.(value) ?? value}
            />
          }
          cursor={{ fill: "var(--white-hover" }}
        />
        <Legend
          iconSize={24}
          wrapperStyle={{ paddingTop: "1em" }}
          payload={yearsRange.map((x) => ({
            key: x,
            value: untilYear - x,
            color: "var(--ci-grey)",
            legendIcon: <SvgBullet fill={colorCycle(x)} />,
          }))}
        />
        {yearsRange.map((x) => (
          <Bar
            key={x}
            name={"" + (untilYear - x)}
            dataKey={`value.year${x + 1}`}
            fill={colorCycle(x)}
          >
            <LabelList
              dataKey={`value.year${x + 1}`}
              formatter={(value: number) => formatter?.(value) ?? value}
              position="top"
              angle={yearsRange.length > 1 ? -90 : 0}
            />
          </Bar>
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default AnnualComparisonBarChart;
