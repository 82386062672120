import { useEffect, useReducer, useState } from "react";

import { useMember } from "../../context/memberContext";
import { SP, getWageCosts } from "../../http/serviceportalApi";
import {
  AverageNumberOfEmployeesFilter,
  getAverageNumberOfEmployees,
  getDaysAbsent,
  getTotalWageCosts,
  getWorkDays,
} from "../../selectors/wageCosts";
import {
  configIncompleteMsg,
  formatAsCurrencyWith,
  formatAsNumberWith,
  getWageCostPeriodUntil,
} from "../../utils";
import Chart from "../ui/Chart";
import ToggleSwitch from "../ui/ToggleSwitch";
import AnnualComparisonBarChart from "./AnnualComparisonBarChart";
import AnnualComparisonLineChart from "./AnnualComparisonLineChart";
import NumberOfYearsSelect from "./NumberOfYearsSelect";
import styles from "./WageCostCharts.module.css";

type State = {
  wageCosts: SP.WageCosts[] | undefined;
  showPreviousYear: boolean;
  numberOfYears: number;
  oneOrTwoYearPeriod: SP.Period;
  threeYearPeriod: SP.Period;
};

type Action =
  | { type: "SET_WAGE_COSTS"; wageCosts: SP.WageCosts[] }
  | { type: "TOGGLE_SHOW_PREVIOUS_YEAR" }
  | { type: "SET_NUMBER_OF_YEARS"; amount: number };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_WAGE_COSTS":
      return {
        ...state,
        wageCosts: action.wageCosts,
        oneOrTwoYearPeriod: state.showPreviousYear
          ? getNYearPeriod(2)
          : getNYearPeriod(1),
        threeYearPeriod: getNYearPeriod(3),
      };
    case "TOGGLE_SHOW_PREVIOUS_YEAR": {
      const showPreviousYear = !state.showPreviousYear;
      const {
        from: { year, month },
      } = state.oneOrTwoYearPeriod;
      return {
        ...state,
        showPreviousYear,
        oneOrTwoYearPeriod: {
          ...state.oneOrTwoYearPeriod,
          from: { year: showPreviousYear ? year - 1 : year + 1, month },
        },
      };
    }
    case "SET_NUMBER_OF_YEARS":
      const maxYear = getWageCostPeriodUntil().getFullYear();
      return {
        ...state,
        numberOfYears: action.amount,
        threeYearPeriod: {
          ...state.threeYearPeriod,
          from: {
            year: maxYear - action.amount,
            month: state.threeYearPeriod.until.month,
          },
        },
      };
    default:
      return state;
  }
};

const getNYearPeriod = (n: number) => {
  const until = getWageCostPeriodUntil();
  const year = until.getFullYear();
  const month = until.getMonth() + 1;
  return {
    from: { year: year - (n - 1), month: 1 },
    until: { year, month },
  };
};

const WageCostCharts = () => {
  const { member } = useMember();
  const [state, dispatch] = useReducer(reducer, {
    wageCosts: undefined,
    showPreviousYear: false,
    numberOfYears: 3,
    oneOrTwoYearPeriod: getNYearPeriod(1),
    threeYearPeriod: getNYearPeriod(3),
  });
  const [averageNumberOfEmployeesFilter, setAverageNumberOfEmployeesFilter] =
    useState<AverageNumberOfEmployeesFilter>("all");

  useEffect(() => {
    (async () => {
      if (!member?.giottoId) return;
      const wageCosts = await getWageCosts(
        member.id,
        member.giottoId,
        getNYearPeriod(3)
      );
      dispatch({ type: "SET_WAGE_COSTS", wageCosts });
    })();
  }, [member]);

  if (!member) return null;

  return (
    <>
      {!member.giottoId ? (
        <div className={styles.status}>{configIncompleteMsg}</div>
      ) : (
        <div className={styles.charts}>
          <Chart className={styles.chart} title="Aufstellung Lohnkosten">
            <Chart.Filter>
              <div />
              <div className={styles["show-previous-year"]}>
                <label htmlFor="showPreviousYear">Zeige Vorjahr</label>
                <ToggleSwitch
                  id="showPreviousYear"
                  checked={state.showPreviousYear}
                  onChange={() =>
                    dispatch({ type: "TOGGLE_SHOW_PREVIOUS_YEAR" })
                  }
                />
              </div>
            </Chart.Filter>
            <Chart.Content>
              {state.wageCosts && (
                <AnnualComparisonBarChart
                  period={state.oneOrTwoYearPeriod}
                  data={getTotalWageCosts(
                    state.oneOrTwoYearPeriod.until,
                    state.wageCosts
                  )}
                  formatter={(value) => formatAsCurrencyWith(2, value)}
                />
              )}
            </Chart.Content>
          </Chart>
          <Chart
            className={styles.chart}
            title="Durchschnittliche Anzahl Mitarbeiter pro Monat"
          >
            <Chart.Filter>
              <div className={styles["avg-number-of-employees-filters"]}>
                <div className={styles["avg-number-of-employees-filter"]}>
                  <label htmlFor="employee">Mitarbeiter</label>
                  <select
                    id="employee"
                    value={averageNumberOfEmployeesFilter}
                    onChange={(e) =>
                      setAverageNumberOfEmployeesFilter(
                        e.target.value as AverageNumberOfEmployeesFilter
                      )
                    }
                  >
                    <option value="all">Alle</option>
                    <option value="employees">Lohnabhängige Mitarbeiter</option>
                    <option value="owner_and_employedfamilymembers">
                      Gesellschafter / Familienmitglieder
                    </option>
                  </select>
                </div>
                <div className={styles["avg-number-of-employees-filter"]}>
                  <label htmlFor="avgEmployeesPeriod">Zeitraum</label>
                  <NumberOfYearsSelect
                    id="avgEmployeesPeriod"
                    period={state.threeYearPeriod}
                    onPeriodChange={(e) =>
                      dispatch({
                        type: "SET_NUMBER_OF_YEARS",
                        amount: e.until.year - e.from.year,
                      })
                    }
                  />
                </div>
              </div>
            </Chart.Filter>
            <Chart.Content>
              {state.wageCosts && (
                <AnnualComparisonLineChart
                  period={state.threeYearPeriod}
                  data={getAverageNumberOfEmployees(
                    state.threeYearPeriod.until,
                    state.wageCosts,
                    averageNumberOfEmployeesFilter
                  )}
                  formatter={(value) => formatAsNumberWith(2, value)}
                />
              )}
            </Chart.Content>
          </Chart>
          <Chart className={styles.chart} title="Arbeitstage pro Monat">
            <Chart.Filter>
              <div />
              <div className={styles.filter}>
                <label htmlFor="workDaysPeriod">Zeitraum</label>
                <NumberOfYearsSelect
                  id="workDaysPeriod"
                  period={state.threeYearPeriod}
                  onPeriodChange={(e) =>
                    dispatch({
                      type: "SET_NUMBER_OF_YEARS",
                      amount: e.until.year - e.from.year,
                    })
                  }
                />
              </div>
            </Chart.Filter>
            <Chart.Content>
              {state.wageCosts && (
                <AnnualComparisonLineChart
                  period={state.threeYearPeriod}
                  data={getWorkDays(
                    state.threeYearPeriod.until,
                    state.wageCosts
                  )}
                  formatter={(value) => formatAsNumberWith(0, value)}
                />
              )}
            </Chart.Content>
          </Chart>
          <Chart className={styles.chart} title="Fehltage pro Monat">
            <Chart.Filter>
              <div />
              <div className={styles.filter}>
                <label htmlFor="absencesPeriod">Zeitraum</label>
                <NumberOfYearsSelect
                  id="absencesPeriod"
                  period={state.threeYearPeriod}
                  onPeriodChange={(e) =>
                    dispatch({
                      type: "SET_NUMBER_OF_YEARS",
                      amount: e.until.year - e.from.year,
                    })
                  }
                />
              </div>
            </Chart.Filter>
            <Chart.Content>
              {state.wageCosts && (
                <AnnualComparisonLineChart
                  period={state.threeYearPeriod}
                  data={getDaysAbsent(
                    state.threeYearPeriod.until,
                    state.wageCosts
                  )}
                  formatter={(value) => formatAsNumberWith(0, value)}
                />
              )}
            </Chart.Content>
          </Chart>
        </div>
      )}
    </>
  );
};

export default WageCostCharts;
