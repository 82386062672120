import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useListFilters from "../../hooks/useListFilters";
import { SP, getDocument, useDocuments } from "../../http/serviceportalApi";
import { DocumentFilterOptions, DocumentFilterState } from "../../types";
import { getRemoteDataStatus } from "../../utils";
import DocumentsDownload from "./DocumentsDownload";
import DocumentsFilters from "./DocumentsFilters";
import styles from "./DocumentsList.module.css";
import DocumentsTable from "./DocumentsTable";

interface Props {
  archive: SP.DocumentArchive;
  luid: SP.Luid;
  set: SP.DocumentSet;
  setUnreadCount: (count: number) => void;
  onDownloadAllSuccess: () => void;
}

const DocumentsList = ({
  archive,
  luid,
  set: { type: setType, unreadCount },
  setUnreadCount,
  onDownloadAllSuccess,
}: Props) => {
  const [searchParams] = useSearchParams();
  const paginationFilters = useListFilters();
  const docFilters: DocumentFilterState = {
    type: searchParams.get("dokument") ?? undefined,
    date: searchParams.get("datum") ?? undefined,
    employee: searchParams.get("mitarbeiter") ?? undefined,
    customer: searchParams.get("kunde") ?? undefined,
    company: searchParams.get("betrieb") ?? undefined,
    department: (searchParams.get("abteilung") as SP.Department) ?? undefined,
    member: searchParams.get("mitglied") ?? undefined,
  };

  const { data, isValidating, error, mutate } = useDocuments(
    archive,
    luid,
    setType,
    {
      ...paginationFilters.state,
      ...docFilters,
    }
  );
  const documents = data?.items ?? [];
  const total = data?.total ?? 0;

  const [filterOptions, setFilterOptions] = useState<DocumentFilterOptions>({
    typeOptions: data?.typeOptions ?? [],
    dateOptions: data?.dateOptions ?? [],
    customerOptions: data?.customerOptions ?? [],
    companyOptions: data?.companyOptions ?? [],
    departmentOptions: data?.departmentOptions ?? [],
  });
  const [downloadLinks, setDownloadLinks] = useState<
    SP.DownloadLinks | undefined
  >(data?.downloadLinks);

  const isRundschreiben =
    setType === "lohn-rundschreiben" || setType === "steuer-rundschreiben";

  useEffect(() => {
    if (!data) return;
    setFilterOptions({
      typeOptions: data.typeOptions,
      dateOptions: data.dateOptions,
      customerOptions: data.customerOptions,
      companyOptions: data.companyOptions,
      departmentOptions: data.departmentOptions,
      memberOptions: data.memberOptions,
    });
    setDownloadLinks(data.downloadLinks);
  }, [data]);

  return (
    <div className={styles.list}>
      {(filterOptions.typeOptions.length > 0 ||
        filterOptions.dateOptions.length > 0) && (
        <DocumentsFilters
          filterOptions={filterOptions}
          documentFilters={docFilters}
        />
      )}

      {downloadLinks && !isRundschreiben && (
        <DocumentsDownload
          links={downloadLinks}
          fetchDocument={getDocument}
          onDownloadSuccess={() => {
            onDownloadAllSuccess();
            if (!data) return;
            mutate({
              ...data,
              items: data.items.map((doc) => ({
                ...doc,
                readByCurrentUser: true,
              })),
            });
          }}
        />
      )}

      <DocumentsTable
        setType={setType}
        paginationFilters={paginationFilters}
        documents={documents}
        total={total}
        remoteStatus={getRemoteDataStatus({ isValidating, error })}
        fetchDocument={getDocument}
        markAsRead={(id) => {
          setUnreadCount(unreadCount - 1);
          if (!data) return;
          mutate(
            {
              ...data,
              items: data.items.map((doc) =>
                doc.id === id ? { ...doc, readByCurrentUser: true } : doc
              ),
            },
            { revalidate: false }
          );
        }}
      />
    </div>
  );
};

export default DocumentsList;
