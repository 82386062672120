import i18n from "../../i18n";
import DepartmentApps from "../DepartmentApps";
import Page from "../ui/Page";
import PageSection from "../ui/PageSection";

const WebMarketing = () => (
  <Page
    title={i18n.departments.webMarketing.title}
    description={
      <>
        Die Abteilung Web Marketing ist in der Kundenberatung rund um das Thema
        Online Marketing / Online Buchbarkeit tätig und mit der Entwicklung
        diverser Tools und Portale stets darum bemüht, Ihnen die
        Betriebsorganisation zu erleichtern und Sie bei Ihrem Auftritt im Web
        optimal zu unterstützen. Einen Überblick über unsere Produkte und
        Dienstleistungen finden Sie auf unserer{" "}
        <a href="https://www.hotelhgv.it/de">Demoseite</a>.
      </>
    }
  >
    <PageSection title="Anwendungen">
      <DepartmentApps department="webMarketing" />
    </PageSection>
  </Page>
);

export default WebMarketing;
