import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { useMember } from "../../context/memberContext";
import { SP } from "../../http/serviceportalApi";
import i18n from "../../i18n";
import { includesAny } from "../../utils";
import {
  accountingDocumentPermissions,
  payrollDocumentPermissions,
} from "../member/DocumentPermissions";
import Alert from "../ui/Alert";
import NavTabs from "../ui/NavTabs";
import Page from "../ui/Page";
import Select from "../ui/Select";
import styles from "./Documents.module.css";
import DocumentsTab from "./DocumentsTab";

interface Option {
  luid: SP.Luid;
  name: string;
  permissions: string[];
}

const Documents = () => {
  const { member } = useMember();
  const navigate = useNavigate();

  const options: Option[] = [
    ...(member?.permissions &&
    includesAny(member.permissions, [
      ...accountingDocumentPermissions,
      ...payrollDocumentPermissions,
    ]) &&
    member.luid
      ? [
          {
            luid: member.luid,
            name: i18n.allCompanies,
            permissions: member.permissions,
          },
        ]
      : []),
    ...(member?.companies
      ?.sort((a, b) => a.name.localeCompare(b.name))
      .reduce((state: Option[], value) => {
        if (
          !value.permissions ||
          !includesAny(value.permissions, [
            ...accountingDocumentPermissions,
            ...payrollDocumentPermissions,
          ]) ||
          !value.luid
        )
          return state;

        return [
          ...state,
          {
            id: String(value.id),
            luid: value.luid,
            name: value.name,
            permissions: value.permissions,
          },
        ];
      }, []) ?? []),
  ];
  const firstOption = options[0];

  const selectedOption = options.find(
    (o) => location.pathname.includes(`/${o.luid}`) ?? 0
  );

  return (
    <Page title="Dokumente">
      <Alert showUntil={new Date(2024, 6, 23, 18, 0)}>
        Aufgrund von Wartungsarbeiten werden die Dokumente heute von 13:30 Uhr
        bis voraussichtlich 18:00 Uhr nicht einsehbar sein.
      </Alert>
      {options.length > 1 && (
        <Select
          className={styles.optionSelect}
          value={selectedOption?.luid}
          onChange={(e) => {
            const selected = options.find(
              (o) => String(o.luid) === e.target.value
            );
            navigate(`${selected?.luid}`);
          }}
        >
          {options.map(({ luid, name }) => (
            <option key={luid} value={luid}>
              {name}
            </option>
          ))}
        </Select>
      )}
      <Routes>
        {options.map((o) => (
          <Route
            key={o.luid}
            path={`${o.luid}/*`}
            element={<OptionDocuments option={o} />}
          />
        ))}
        {firstOption && (
          <Route
            path="*"
            element={<Navigate to={`${firstOption.luid}`} replace={true} />}
          />
        )}
      </Routes>
    </Page>
  );
};

interface OptionDocumentsProps {
  option: Option;
}

const OptionDocuments = ({ option }: OptionDocumentsProps) => {
  const archives = getArchives(option?.permissions ?? []);
  const firstArchive = archives[0];

  return (
    <>
      <NavTabs
        tabs={archives.map((a) => ({
          to: i18n.departments[a].slug,
          text: i18n.departments[a].title,
        }))}
      />

      <Routes>
        {archives.map((a) => (
          <Route
            key={a}
            path={`${i18n.departments[a].slug}/*`}
            element={<DocumentsTab luid={option.luid} archive={a} />}
          />
        ))}
        {firstArchive && (
          <Route
            path="*"
            element={
              <Navigate
                to={i18n.departments[firstArchive].slug}
                replace={true}
              />
            }
          />
        )}
      </Routes>
    </>
  );
};

const getArchives = (permissions: string[]) => {
  const archives: SP.DocumentArchive[] = [];
  if (includesAny(permissions, payrollDocumentPermissions))
    archives.push("payroll");
  if (includesAny(permissions, accountingDocumentPermissions))
    archives.push("accounting");
  return archives;
};

export default Documents;
