import { useMember } from "../../context/memberContext";
import Page from "../ui/Page";
import PayrollOfficeEmailAddress from "./PayrollOfficeEmailAddress";

const UniUrg = () => {
  const { member } = useMember();

  if (!member) {
    return null;
  }

  return (
    <Page
      title="UniUrg – dringende Anmeldungen außerhalb der Öffnungszeiten"
      backLinkText="Zurück zu Personalberatung"
      narrow={true}
    >
      <div>
        <p>
          Sollte außerhalb der Bürozeiten aufgrund unvorhersehbarer Ereignisse
          zusätzliches Personal angemeldet werden müssen, so kann in diesen
          Ausnahmefällen die Anmeldung anhand des{" "}
          <a
            href="https://assets-eu-01.kc-usercontent.com/a0e24cdf-7b6b-010e-94fe-bdbecae481ee/509ecddb-2420-4870-a95f-36cc85f1ac31/UniUrg.pdf"
            target="_blank"
          >
            UniUrg Formulars
          </a>{" "}
          per E-Mail an{" "}
          <a href="mailto:notel@provinz.bz.it">notel@provinz.bz.it</a>{" "}
          übermittelt werden.{" "}
        </p>
        <p>
          Die Versandbestätigung des Formulars UniUrg, sowie die entsprechende
          Meldungsnummer, die vom Amt nach erfolgter Übermittlung zugewiesen
          wird, sind vom Kunden unverzüglich an den/die jeweilige:n
          Ansprechpartner:in der HGV-Personalberatung weiterzuleiten (
          <PayrollOfficeEmailAddress member={member} />
          ), damit die ordentliche UniLav-Anmeldung, sowie die Ausarbeitung des
          Arbeitsvertrages am erstmöglichen Tag erfolgen kann.
        </p>
      </div>
    </Page>
  );
};

export default UniUrg;
