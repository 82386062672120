import classNames from "classnames";
import { Fragment } from "react";

import styles from "./MonthlyAnalysisRow.module.css";
import { SP } from "../../http/serviceportalApi";
import {
  formatAsCurrencyWith,
  formatAsNumberWith,
  formatAsPercent,
} from "../../utils";
import TableCell from "../ui/TableCell";
import { getExpandedState } from "../../selectors/profitLosses";

interface Props {
  isExpandable: boolean;
  isExpanded: boolean;
  isCollapsed: boolean;
  expandCollapse: () => void;
  profitLoss: SP.Return<SP.MonthlyDataRow>;
  showPreviousYear: boolean;
  yearMonths: SP.YearMonth2[];
}

const MonthlyAnalysisRow = ({
  isExpandable,
  isExpanded,
  isCollapsed,
  expandCollapse,
  profitLoss,
  showPreviousYear,
  yearMonths,
}: Props) => {
  let valueFormatter = (type: SP.ValueType, value: number) => {
    switch (type) {
      case "absolute":
        return formatAsNumberWith(0, value);
      case "currency":
        return formatAsCurrencyWith(0, value);
      case "percentage":
        return formatAsPercent(0, value);
    }
  };

  let range = (
    [year, month]: SP.YearMonth2,
    range: [SP.YearMonth2, SP.ValuePercent][]
  ) => {
    let [_, [value]] = range.find(
      ([[y, m], _]) => year === y && month === m
    ) ?? [
      [year, month],
      [0, 0],
    ];
    return (
      <TableCell
        CellType="td"
        value={value}
        formatter={(value) => valueFormatter(profitLoss.valueType, value)}
      />
    );
  };

  return (
    <tr
      className={classNames(styles.monthlyAnalysisRow, {
        [styles.level1]: profitLoss.level === "level1",
        [styles.level2]: profitLoss.level === "level2",
        [styles.level4]: profitLoss.level === "level4",
        [styles.expandable]: isExpandable,
      })}
      onClick={() => isExpandable && expandCollapse()}
    >
      <td
        className={classNames({
          [styles.isInfo]: getExpandedState(
            isExpanded,
            isCollapsed,
            profitLoss
          ),
        })}
      />
      <td>{profitLoss.label}</td>
      {yearMonths.map(([year, month]) => (
        <Fragment key={`${year}-${month}`}>
          {range([year, month], profitLoss.value.range1)}
          {showPreviousYear &&
            range([year - 1, month], profitLoss.value.range2)}
        </Fragment>
      ))}
    </tr>
  );
};

export default MonthlyAnalysisRow;
