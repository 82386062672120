import { Navigate, Route, Routes } from "react-router-dom";
import { SP, useDocumentSets } from "../../http/serviceportalApi";
import i18n from "../../i18n";
import { shouldShowUnreadCount, sort } from "../../selectors/documents";
import { getRemoteDataStatus } from "../../utils";
import ErrorText from "../ui/ErrorText";
import LoadingSpinner from "../ui/LoadingSpinner";
import DocumentsList from "./DocumentsList";
import styles from "./DocumentsTab.module.css";
import Nav from "./Nav";

interface Props {
  luid: SP.Luid;
  archive: SP.DocumentArchive;
  showSets?: boolean;
}

const DocumentsTab = ({ luid, archive, showSets = true }: Props) => {
  const { data, isValidating, error, mutate } = useDocumentSets(archive, luid);
  const sets = sort(data ?? [], archive);
  const status = getRemoteDataStatus({ isValidating, error });
  const firstSet = sets[0];

  return (
    <>
      {status === "success" && (
        <div className={styles.documents}>
          {showSets && (
            <Nav className={styles.nav}>
              {sets.map(({ type, unreadCount, month }) => (
                <Nav.Item
                  key={type}
                  title={i18n.getDocumentSetName(type)}
                  to={`${type}${month ? `?datum=${month}` : ""}`}
                  unread={shouldShowUnreadCount(type) ? unreadCount : undefined}
                />
              ))}
            </Nav>
          )}

          <Routes>
            {sets.map((set) => (
              <Route
                key={set.type}
                path={set.type}
                element={
                  <DocumentsList
                    key={set.type}
                    archive={archive}
                    luid={luid}
                    set={set}
                    setUnreadCount={(unreadCount) =>
                      mutate(
                        data?.map((s) =>
                          s.type === set.type ? { ...s, unreadCount } : s
                        ),
                        { revalidate: false }
                      )
                    }
                    onDownloadAllSuccess={() => mutate()}
                  />
                }
              />
            ))}
            {firstSet && (
              <Route
                index
                element={<Navigate to={firstSet.type} replace={true} />}
              />
            )}
          </Routes>
        </div>
      )}

      {status === "validating" && (
        <div className={styles.status}>
          <LoadingSpinner />
        </div>
      )}

      {status === "failure" && (
        <div className={styles.status}>
          <ErrorText text="Fehler beim Laden der Dokumente." />
        </div>
      )}
    </>
  );
};

export default DocumentsTab;
