import classNames from "classnames";
import SvgClose from "../icons/Close";
import SvgSearch from "../icons/Search";
import Icon from "./Icon";
import styles from "./SearchBar.module.css";

interface Props {
  placeholder?: string;
  value: string;
  onChange?: (s: string) => void;
  className?: string;
  id?: string;
}

const SearchBar = ({ placeholder, value, onChange, className, id }: Props) => (
  <div className={classNames(styles.search, className)}>
    <input
      id={id}
      type="search"
      placeholder={placeholder ?? ""}
      value={value}
      onChange={(e) => onChange?.(e.target.value)}
    />
    {value === "" && <Icon glyph={SvgSearch} className={styles.searchIcon} />}
    {value !== "" && (
      <Icon
        glyph={SvgClose}
        className={styles.clearIcon}
        onClick={() => onChange?.("")}
      />
    )}
  </div>
);

export default SearchBar;
