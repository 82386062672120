import { Fragment, useContext } from "react";
import validator from "validator";
import NotificationDispatch, {
  showErrorNotification,
  showSuccessNotification,
} from "../../context/notificationContext";
import {
  SP,
  putQuestionnaire,
  useQuestionnaire,
} from "../../http/serviceportalApi";
import { getRemoteDataStatus } from "../../utils";
import Alert from "../ui/Alert";
import ErrorText from "../ui/ErrorText";
import Form from "../ui/Form";
import FormField from "../ui/FormField";
import Input from "../ui/Input";
import LoadingSpinner from "../ui/LoadingSpinner";
import Panel from "../ui/Panel";
import ToggleSwitch from "../ui/ToggleSwitch";
import styles from "./QuestionnaireTab.module.css";

interface Props {
  luid: SP.Luid;
}

const QuestionnaireTab = ({ luid }: Props) => {
  const date = new Date();
  const year = date.getFullYear() - 1;
  const disabled = date.getMonth() + 1 > 8;
  const { data, isValidating, error, mutate } = useQuestionnaire(luid, year);
  const status = getRemoteDataStatus({ isValidating, error });
  const dispatch = useContext(NotificationDispatch);

  return (
    <>
      {status === "success" && (
        <>
          <div className={styles.model}>
            {disabled && (
              <div className={styles.disabled}>
                Die Eingabe ist nicht mehr möglich. Bitte wenden Sie sich an
                Ihre HGV-Personalberatung:
                <ul className={styles.list}>
                  <li>
                    Bozen:{" "}
                    <a href="mailto:personalberatung.bozen@hgv.it">
                      personalberatung.bozen@hgv.it
                    </a>
                  </li>
                  <li>
                    Meran:{" "}
                    <a href="mailto:personalberatung.meran@hgv.it">
                      personalberatung.meran@hgv.it
                    </a>
                  </li>
                  <li>
                    Brixen:{" "}
                    <a href="mailto:personalberatung.brixen@hgv.it">
                      personalberatung.brixen@hgv.it
                    </a>
                  </li>
                  <li>
                    Bruneck:{" "}
                    <a href="mailto:personalberatung.bruneck@hgv.it">
                      personalberatung.bruneck@hgv.it
                    </a>
                  </li>
                  <li>
                    Schlanders:{" "}
                    <a href="mailto:personalberatung.schlanders@hgv.it">
                      personalberatung.schlanders@hgv.it
                    </a>
                  </li>
                </ul>
              </div>
            )}
            {!disabled && (
              <Alert>
                Bitte bestätigen Sie die Eingabe innerhalb 31. August indem Sie
                auf „Bestätigen“ klicken, auch wenn Sie keine Kontrollkästchen
                aktiviert haben.
              </Alert>
            )}
            {!data?.customers.length ? (
              <div className={styles.disabled}>
                Keine Kundenliste vorhanden.
              </div>
            ) : (
              <Form<SP.PutQuestionnaireBody>
                defaultValues={{ year, customers: data.customers }}
                disabled={disabled}
                submitText="Bestätigen"
                onSubmit={() => (formData) => {
                  const body: SP.PutQuestionnaireBody = {
                    year,
                    customers: formData.customers.map((c, i) => ({
                      name: data.customers[i]?.name ?? "",
                      freelanceInvoices: c.freelanceInvoices,
                      taxCode: c.taxCode,
                    })),
                  };

                  putQuestionnaire(luid, body)
                    .then((updated) => {
                      mutate(updated, { revalidate: false });
                      dispatch(showSuccessNotification());
                    })
                    .catch((err) => dispatch(showErrorNotification(err)));
                }}
              >
                {({
                  register,
                  watch,
                  setValue,
                  clearErrors,
                  formState: { errors },
                }) => (
                  <section className={styles.section}>
                    <Panel>
                      <div className={styles.gridWrapper}>
                        <div className={styles.grid}>
                          <div className={styles.title}>Name</div>
                          <div className={styles.title}>
                            Freiberuflerrechnung
                          </div>
                          <div className={styles.title}>Steuernummer</div>
                          {data.customers.map((customer, i) => (
                            <Fragment key={i}>
                              <div>{customer.name}</div>
                              <div>
                                <ToggleSwitch
                                  {...register(
                                    `customers.${i}.freelanceInvoices`,
                                    {
                                      onChange: ({ target }) => {
                                        if (target.checked) return;
                                        setValue(
                                          `customers.${i}.taxCode`,
                                          undefined
                                        );
                                        clearErrors(`customers.${i}.taxCode`);
                                      },
                                    }
                                  )}
                                  disabled={disabled}
                                />
                              </div>
                              <FormField error={errors.customers?.[i]?.taxCode}>
                                {({ isInvalid }) => (
                                  <Input
                                    className={styles.uppercase}
                                    disabled={
                                      !watch(
                                        `customers.${i}.freelanceInvoices`
                                      ) || disabled
                                    }
                                    isInvalid={isInvalid}
                                    {...register(`customers.${i}.taxCode`, {
                                      validate: (v) =>
                                        v &&
                                        (validator.isTaxID(v, "it-IT") ||
                                          validator.isVAT(`IT${v}`, "IT")),
                                      required: watch(
                                        `customers.${i}.freelanceInvoices`
                                      ),
                                    })}
                                  />
                                )}
                              </FormField>
                            </Fragment>
                          ))}
                        </div>
                      </div>
                    </Panel>
                  </section>
                )}
              </Form>
            )}
          </div>
        </>
      )}

      {status === "validating" && (
        <div className={styles.status}>
          <LoadingSpinner />
        </div>
      )}

      {status === "failure" && (
        <div className={styles.status}>
          <ErrorText text="Fehler beim Laden der Daten." />
        </div>
      )}
    </>
  );
};

export default QuestionnaireTab;
