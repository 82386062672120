import { useNavigate } from "react-router-dom";
import { useMember } from "../../context/memberContext";
import { SP } from "../../http/serviceportalApi";
import i18n from "../../i18n";
import { Permission } from "../../types";
import { includesAny } from "../../utils";
import DepartmentApps from "../DepartmentApps";
import SvgDescription from "../icons/Description";
import Page from "../ui/Page";
import PageSection from "../ui/PageSection";
import QuickAccess from "../ui/QuickAccess";

const Payroll = () => {
  const navigate = useNavigate();
  const { member, status } = useMember();

  const arbeitsvertraegeLuid = getLuid(member, [
    Permission.DocumentsBetriebOnlineServiceRead,
    Permission.DocumentsBetriebStandardRead,
    Permission.DocumentsArbeitsvertraegeRead,
  ]);
  const lohnRundschreibenLuid = getLuid(member, [
    Permission.DocumentsBetriebStandardRead,
    Permission.DocumentsLohnRundschreibenRead,
  ]);
  const lohnauswertungLuid = getLuid(member, [
    Permission.DocumentsBetriebOnlineServiceRead,
    Permission.DocumentsBetriebStandardRead,
    Permission.DocumentsPrivateHaushalteRead,
    Permission.DocumentsAufstellungNettoloehneRead,
    Permission.DocumentsBuchungsbelegeRead,
    Permission.DocumentsEinbehalt15Read,
    Permission.DocumentsLaborfondMonatlichRead,
    Permission.DocumentsLaborfondTrimestralRead,
    Permission.DocumentsLohnstreifenRead,
    Permission.DocumentsF24Read,
    Permission.DocumentsSchulgeldLehrlingeRead,
    Permission.DocumentsUeberweisungslisteRead,
  ]);
  const provlohnstreifenLuid = getLuid(member, [
    Permission.DocumentsBetriebStandardRead,
    Permission.DocumentsPrivateHaushalteRead,
    Permission.DocumentsProvisorischerLohnstreifenRead,
  ]);

  const quickAccessItems = [
    ...(arbeitsvertraegeLuid
      ? [
          {
            name: i18n.getDocumentSetName("arbeitsvertraege"),
            type: "Dokumente",
            glyph: SvgDescription,
            onNavigate: () =>
              navigate(
                `/dokumente/${arbeitsvertraegeLuid}/personalberatung/arbeitsvertraege`
              ),
          },
        ]
      : []),
    ...(lohnRundschreibenLuid
      ? [
          {
            name: i18n.getDocumentSetName("lohn-rundschreiben"),
            type: "Dokumente",
            glyph: SvgDescription,
            onNavigate: () =>
              navigate(
                `/dokumente/${lohnRundschreibenLuid}/personalberatung/lohn-rundschreiben`
              ),
          },
        ]
      : []),
    ...(lohnauswertungLuid
      ? [
          {
            name: i18n.getDocumentSetName("lohnauswertung"),
            type: "Dokumente",
            glyph: SvgDescription,
            onNavigate: () =>
              navigate(
                `/dokumente/${lohnauswertungLuid}/personalberatung/lohnauswertung`
              ),
          },
        ]
      : []),
    ...(provlohnstreifenLuid
      ? [
          {
            name: i18n.getDocumentSetName("prov-lohnstreifen"),
            type: "Dokumente",
            glyph: SvgDescription,
            onNavigate: () =>
              navigate(
                `/dokumente/${provlohnstreifenLuid}/personalberatung/prov-lohnstreifen`
              ),
          },
        ]
      : []),
  ];

  return (
    <Page
      title={i18n.departments.payroll.title}
      description="Die HGV-Personalberatung ist Ihr zuverlässiger und kompetenter Ansprechpartner, wenn es um Ihre Mitarbeiter:innen geht. Hier können Sie schnell und unkompliziert auf sämtliche Dokumente und Anwendungen zur Verwaltung Ihres Personals zugreifen."
      isLoading={status === "validating"}
    >
      {quickAccessItems.length > 0 && (
        <PageSection title="Schnellzugriff">
          <QuickAccess items={quickAccessItems} />
        </PageSection>
      )}

      <PageSection title="Anwendungen">
        <DepartmentApps department="payroll" />
      </PageSection>
    </Page>
  );
};

const getLuid = (member: SP.Member | undefined, permissions: Permission[]) => {
  if (!member) return;
  return includesAny(member.permissions ?? [], permissions)
    ? member.luid
    : member.companies?.find((c) =>
        includesAny(c.permissions ?? [], permissions)
      )?.luid;
};

export default Payroll;
