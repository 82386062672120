import { Link } from "react-router-dom";
import serviceportalSVG from "../assets/serviceportal.svg";
import AccountButton from "./AccountButton";
import styles from "./Header.module.css";
import MemberSelect from "./member/MemberSelect";
import Hamburger from "./ui/Hamburger";
import AppMenu from "./ui/AppMenu";

interface Props {
  hamburger: {
    isOpen: boolean;
    onToggle: () => void;
  };
}

const Header = ({ hamburger }: Props) => (
  <header className={styles.header}>
    <div className={styles.aside}>
      <Link to="/" className={styles.logo}>
        <img src={serviceportalSVG} alt="HGV Serviceportal" />
      </Link>
      <Hamburger
        isOpen={hamburger.isOpen}
        className={styles.hamburger}
        onClick={hamburger.onToggle}
      />
      <MemberSelect className={styles.memberSelect} />
    </div>
    <AppMenu />
    <AccountButton />
  </header>
);

export default Header;
