import { useState } from "react";
import { useMember } from "../../context/memberContext";
import { SP } from "../../http/serviceportalApi";
import { Evaluation, Permission } from "../../types";
import {
  configIncompleteMsg,
  getAccountingPeriodUntil,
  getDefaultPeriod,
} from "../../utils";
import ButtonGroup from "../ui/ButtonGroup";
import CompanySelect from "../ui/CompanySelect";
import ToggleSwitch from "../ui/ToggleSwitch";
import styles from "./AccountingCharts.module.css";
import Chart1 from "./Chart1";
import Chart2 from "./Chart2";
import Chart3 from "./Chart3";
import Chart4 from "./Chart4";
import Chart5 from "./Chart5";
import EvaluationSelect from "./EvaluationSelect";
import PeriodSelect from "./PeriodSelect";

const AccountingCharts = () => {
  const { member } = useMember();
  const [companyId, setCompanyId] = useState<SP.CompanyId>();
  const [evaluation, setEvaluation] = useState<Evaluation>("competence");
  const [period, setPeriod] = useState(
    getDefaultPeriod(getAccountingPeriodUntil())
  );
  const [showVerwalterentschaedigung, setShowVerwalterentschaedigung] =
    useState(true);
  const [valueTypeChart1, setValueTypeChart1] =
    useState<SP.ValueType>("percentage");
  const [valueTypeChart2, setValueTypeChart2] =
    useState<SP.ValueType>("percentage");

  const renderFiltersTop = (idPrefix: string) => (
    <div className={styles.filtersTop}>
      <div className={styles.filter}>
        <label htmlFor={`${idPrefix}Company`}>Betriebsauswahl</label>
        <CompanySelect
          id={`${idPrefix}Company`}
          className={styles.companySelect}
          allowedPermissions={[Permission.AccountingsRead]}
          value={companyId}
          onChange={(e) =>
            setCompanyId(
              e.target.value !== "all"
                ? parseInt(e.target.value, 10)
                : undefined
            )
          }
        />
      </div>
      <div className={styles.filter}>
        <label htmlFor={`${idPrefix}Evaluation`}>Auswertung</label>
        <EvaluationSelect
          id={`${idPrefix}Evaluation`}
          value={evaluation}
          onChange={setEvaluation}
        />
      </div>
      <div className={styles.filter}>
        <label>Zeitraum</label>
        <PeriodSelect
          period={period}
          periodUntil={getAccountingPeriodUntil()}
          onChange={setPeriod}
        />
      </div>
    </div>
  );

  if (!member) return null;

  const renderFiltersBottom = (
    idPrefix: string,
    valueType: SP.ValueType,
    setValueType: (valueType: SP.ValueType) => void
  ) => (
    <div className={styles.filtersBottom}>
      <div className={styles.valueTypeButtonGroup}>
        <ButtonGroup>
          <ButtonGroup.Button
            isActive={valueType === "currency"}
            onClick={() => setValueType("currency")}
          >
            €
          </ButtonGroup.Button>
          <ButtonGroup.Button
            isActive={valueType === "percentage"}
            onClick={() => setValueType("percentage")}
          >
            %
          </ButtonGroup.Button>
        </ButtonGroup>
      </div>
      <div className={styles.showVerwalterentschaedigungSwitch}>
        <label htmlFor={`${idPrefix}ShowVerwalterentschaedigung`}>
          Verwalterentschädigung ausblenden
        </label>
        <ToggleSwitch
          id={`${idPrefix}ShowVerwalterentschaedigung`}
          checked={!showVerwalterentschaedigung}
          onChange={() =>
            setShowVerwalterentschaedigung(!showVerwalterentschaedigung)
          }
        />
      </div>
    </div>
  );

  return (
    <>
      {!member.seacNumber ? (
        <div className={styles.status}>{configIncompleteMsg}</div>
      ) : (
        <div className={styles.charts}>
          <Chart1
            className={styles.chart}
            memberId={member.id}
            seacNumber={member.seacNumber}
            companyId={companyId}
            evaluation={evaluation}
            period={period}
            showVerwalterentschaedigung={showVerwalterentschaedigung}
            valueType={valueTypeChart1}
            filters={
              <div className={styles.filters}>
                {renderFiltersTop("chart1")}
                {renderFiltersBottom(
                  "chart1",
                  valueTypeChart1,
                  setValueTypeChart1
                )}
              </div>
            }
          />
          <Chart2
            className={styles.chart}
            memberId={member.id}
            seacNumber={member.seacNumber}
            companyId={companyId}
            evaluation={evaluation}
            period={period}
            showVerwalterentschaedigung={showVerwalterentschaedigung}
            valueType={valueTypeChart2}
            filters={
              <div className={styles.filters}>
                {renderFiltersTop("chart2")}
                {renderFiltersBottom(
                  "chart2",
                  valueTypeChart2,
                  setValueTypeChart2
                )}
              </div>
            }
          />
          <Chart3
            className={styles.chart}
            memberId={member.id}
            seacNumber={member.seacNumber}
            companyId={companyId}
            evaluation={evaluation}
            period={period}
            filters={
              <div className={styles.filter}>
                <label htmlFor="chart3Company">Betriebsauswahl</label>
                <CompanySelect
                  id="chart3Company"
                  value={companyId}
                  allowedPermissions={[Permission.AccountingsRead]}
                  onChange={(e) =>
                    setCompanyId(
                      e.target.value !== "all"
                        ? parseInt(e.target.value, 10)
                        : undefined
                    )
                  }
                />
              </div>
            }
          />
          <Chart4
            className={styles.chart}
            seacNumber={member.seacNumber}
            companyId={companyId}
            period={period}
            filters={
              <div className={styles.filter}>
                <label htmlFor="chart4Company">Betriebsauswahl</label>
                <CompanySelect
                  id="chart4Company"
                  value={companyId}
                  allowedPermissions={[Permission.AccountingsRead]}
                  onChange={(e) =>
                    setCompanyId(
                      e.target.value !== "all"
                        ? parseInt(e.target.value, 10)
                        : undefined
                    )
                  }
                />
              </div>
            }
          />
          <Chart5
            className={styles.chart}
            seacNumber={member.seacNumber}
            companyId={companyId}
            evaluation={evaluation}
            period={period}
            filters={
              <div className={styles.filters}>{renderFiltersTop("chart5")}</div>
            }
          />
        </div>
      )}
    </>
  );
};

export default AccountingCharts;
