import { useEffect, useRef, useState } from "react";
import { useMember } from "../../context/memberContext";
import {
  SP,
  getProfitLossesExcel,
  useProfitLosses,
} from "../../http/serviceportalApi";
import {
  calculateCollapsedRows,
  calculateExpandableSums,
  getCollapsedRows,
  getExpandedSums,
  isRowExpandable,
} from "../../selectors/profitLosses";
import { Evaluation, Permission } from "../../types";
import {
  configIncompleteMsg,
  downloadFile,
  getAccountingPeriodUntil,
  getDefaultPeriod,
  getRemoteDataStatus,
  getYearFromPeriod,
  indexed,
} from "../../utils";
import Button from "../ui/Button";
import CompanySelect from "../ui/CompanySelect";
import Table from "../ui/Table";
import ToggleSwitch from "../ui/ToggleSwitch";
import EvaluationSelect from "./EvaluationSelect";
import PeriodSelect from "./PeriodSelect";
import styles from "./ThreeYearAnalysis.module.css";
import ThreeYearAnalysisRow from "./ThreeYearAnalysisRow";

const areAllValuesZero = (
  valueType: SP.ValueType,
  profitLoss: SP.ThreeYearRow
) => {
  let isValueZero = ([value]: SP.ValuePercent) =>
    valueType === "currency" && value === 0;
  return [profitLoss.range1, profitLoss.range2, profitLoss.range3].every(
    isValueZero
  );
};

const ThreeYearAnalysis = () => {
  const { member } = useMember();
  const [period, setPeriod] = useState(
    getDefaultPeriod(getAccountingPeriodUntil())
  );
  const [evaluation, setEvaluation] = useState<Evaluation>("competence");
  const [companyId, setCompanyId] = useState<SP.CompanyId | undefined>(
    undefined
  );
  const [showAllRows, setShowAllRows] = useState(false);
  const [showWholeYear, setShowWholeYear] = useState(false);
  const [showDifferences, setShowDifferences] = useState(false);
  const [collapsedRows, setCollapsedRows] = useState<Set<number>>();
  const [expandedSums, setExpandedSums] = useState(new Set<number>());
  const [isExcelDownloading, setExcelDownloading] = useState(false);

  const aborter = useRef(new AbortController());
  const { data, isValidating, error } = useProfitLosses(
    member?.id,
    member?.seacNumber,
    companyId,
    period,
    true,
    evaluation === "competence",
    aborter.current.signal
  );
  const isSuccess = !isValidating && !error;
  const status = getRemoteDataStatus({ isValidating, error });
  const profitLosses = data ?? [];

  useEffect(() => {
    if (!isSuccess) return;
    setCollapsedRows(getCollapsedRows(profitLosses));
  }, [isSuccess]);

  useEffect(() => {
    if (!isSuccess) return;
    if (collapsedRows === undefined) return;

    const collapsed =
      collapsedRows.size > 0
        ? new Set<number>()
        : getCollapsedRows(profitLosses);
    setCollapsedRows(collapsed);

    const expanded =
      collapsed.size > 0 ? new Set<number>() : getExpandedSums(profitLosses);
    setExpandedSums(expanded);
  }, [showAllRows]);

  const cells = (
    <>
      <th align="right" title="Die Summe der Beträge.">
        Summe
      </th>
      <th align="right" title="Prozentueller Anteil vom Ertrag.">
        Prozent
      </th>
      {showWholeYear && (
        <>
          <th
            align="right"
            title="Summe gerechnet auf die Daten des kompletten Jahres."
          >
            Jahr als Summe
          </th>
          <th
            align="right"
            title="Prozent gerechnet auf die Daten des kompletten Jahres."
          >
            Jahr in Prozent
          </th>
        </>
      )}
    </>
  );

  const differenceCell = (
    <th align="right" title="Abweichung in Prozent zum vorherigen Jahr.">
      Abw. %
    </th>
  );

  if (!member) return null;

  return (
    <>
      {!member.seacNumber ? (
        <div className={styles.status}>{configIncompleteMsg}</div>
      ) : (
        <div className={styles.threeYearAnalysis}>
          <div className={styles.filters}>
            <div className={styles.filtersTop}>
              <div className={styles.filterSetting}>
                <label htmlFor="company">Betriebsauswahl</label>
                <CompanySelect
                  id="company"
                  className={styles.companySelect}
                  allowedPermissions={[Permission.AccountingsRead]}
                  value={companyId}
                  onChange={(e) =>
                    setCompanyId(
                      e.target.value !== "all"
                        ? parseInt(e.target.value, 10)
                        : undefined
                    )
                  }
                />
              </div>
              <div className={styles.filterSetting}>
                <label htmlFor="evaluation">Auswertung</label>
                <EvaluationSelect id="evaluation" onChange={setEvaluation} />
              </div>
              <div className={styles.filterSetting}>
                <label>Zeitraum</label>
                <PeriodSelect
                  period={period}
                  periodUntil={getAccountingPeriodUntil()}
                  onChange={setPeriod}
                />
              </div>
            </div>
            <div className={styles.filtersBottom}>
              <div className={styles.toggleSwitches}>
                <div className={styles.filterSetting}>
                  <label htmlFor="showAllRows">Zeige alle Zeilen</label>
                  <ToggleSwitch
                    id="showAllRows"
                    checked={showAllRows}
                    onChange={(e) => setShowAllRows(e.target.checked)}
                  />
                </div>
                <div className={styles.filterSetting}>
                  <label htmlFor="showWholeYear">Zeige gesamtes Jahr</label>
                  <ToggleSwitch
                    id="showWholeYear"
                    checked={showWholeYear}
                    onChange={(e) => setShowWholeYear(e.target.checked)}
                  />
                </div>
                <div className={styles.filterSetting}>
                  <label htmlFor="showDifferences">
                    Zeige Abweichung in Prozent zum Vorjahr
                  </label>
                  <ToggleSwitch
                    id="showDifferences"
                    checked={showDifferences}
                    onChange={(e) => setShowDifferences(e.target.checked)}
                  />
                </div>
              </div>
              <div>
                <Button
                  isLoading={isExcelDownloading}
                  buttonProps={{
                    className: styles.downloadButton,
                    disabled: !member.seacNumber,
                    onClick: async () => {
                      if (!member.seacNumber) return;
                      setExcelDownloading(true);
                      const res = await getProfitLossesExcel(
                        member.id,
                        member.seacNumber,
                        companyId,
                        period,
                        true,
                        evaluation === "competence"
                      );
                      await downloadFile(res);
                      setExcelDownloading(false);
                    },
                  }}
                >
                  Excel-Dokument herunterladen
                </Button>
              </div>
            </div>
          </div>
          {collapsedRows !== undefined && (
            <Table
              head={
                <>
                  <tr>
                    <th rowSpan={2} style={{ width: "4px", padding: 0 }} />
                    <th rowSpan={2} align="left" className={styles.labelHeader}>
                      Bezeichnung
                    </th>
                    <th colSpan={2 + +showWholeYear * 2 + +showDifferences}>
                      {getYearFromPeriod(0, period)}
                    </th>
                    <th colSpan={2 + +showWholeYear * 2 + +showDifferences}>
                      {getYearFromPeriod(-1, period)}
                    </th>
                    <th colSpan={2 + +showWholeYear * 2}>
                      {getYearFromPeriod(-2, period)}
                    </th>
                  </tr>
                  <tr>
                    {cells}
                    {showDifferences && differenceCell}
                    {cells}
                    {showDifferences && differenceCell}
                    {cells}
                  </tr>
                </>
              }
              body={indexed(profitLosses)
                .filter(
                  ([x, index]) =>
                    !(
                      collapsedRows.has(index) ||
                      areAllValuesZero(x.valueType, x.value)
                    )
                )
                .map(([x, index]) => (
                  <ThreeYearAnalysisRow
                    key={index}
                    isExpandable={isRowExpandable(index, profitLosses)}
                    isExpanded={expandedSums.has(index)}
                    isCollapsed={collapsedRows.has(index)}
                    expandCollapse={() => {
                      const expanded = calculateExpandableSums(
                        index,
                        profitLosses,
                        expandedSums
                      );
                      const collapsed = calculateCollapsedRows(
                        index,
                        profitLosses,
                        collapsedRows
                      );
                      setExpandedSums(expanded);
                      setCollapsedRows(collapsed);
                    }}
                    profitLoss={x}
                    showWholeYear={showWholeYear}
                    showDifferences={showDifferences}
                  />
                ))}
              total={profitLosses.length}
              remoteDataStatus={status}
            />
          )}
        </div>
      )}
    </>
  );
};

export default ThreeYearAnalysis;
