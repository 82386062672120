import classNames from "classnames";
import { useCombobox } from "downshift";
import { forwardRef, useContext, useEffect, useRef, useState } from "react";
import { useMember } from "../../context/memberContext";
import NotificationDispatch, {
  showErrorNotification,
} from "../../context/notificationContext";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import useToggle from "../../hooks/useToggle";
import { SP, useMembers } from "../../http/serviceportalApi";
import SvgClose from "../icons/Close";
import SvgExpandLess from "../icons/ExpandLess";
import SvgExpandMore from "../icons/ExpandMore";
import SvgSearch from "../icons/Search";
import ErrorText from "../ui/ErrorText";
import HighlightText from "../ui/HighlightText";
import Icon from "../ui/Icon";
import LoadingSpinner from "../ui/LoadingSpinner";
import styles from "./MemberSelect.module.css";

interface Props {
  className?: string;
  theme?: "light" | "dark";
}

const MemberSelect = ({ className, theme = "light" }: Props) => {
  const dispatch = useContext(NotificationDispatch);
  const { member, hasOnlyOneMember, status, error, setMember } = useMember();
  const menu = useToggle();
  const searchRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useOnClickOutside(searchRef, menu.close);

  useEffect(() => {
    if (!menu.isOpen) return;

    inputRef.current?.focus();

    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape" || e.key === "Tab") menu.close();
    };
    document.addEventListener("keydown", onKeyDown);

    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [menu.isOpen]);

  useEffect(() => {
    if (status !== "failure") return;
    dispatch(
      showErrorNotification(error, "Fehler beim Laden der Mitgliedsdaten.")
    );
  }, [status]);

  const memberLabel = (
    <div className={styles.label}>
      <span className={styles.memberName} title={member?.name}>
        {member?.name ?? "Wählen Sie ein Mitglied aus …"}
      </span>
    </div>
  );

  return (
    <div
      className={classNames(styles.memberSelect, className, {
        [styles.memberSelectDark]: theme === "dark",
        [styles.memberSelectOpen]: menu.isOpen,
        [styles.memberSelectOpenDark]: menu.isOpen && theme === "dark",
      })}
      ref={searchRef}
    >
      {status === "validating" ? (
        <LoadingSpinner
          className={styles.loadingSpinner}
          delayed={175}
          size="small"
        />
      ) : member && hasOnlyOneMember ? (
        memberLabel
      ) : (
        <button
          className={classNames(styles.button, {
            [styles.buttonOpen]: menu.isOpen,
          })}
          onClick={menu.toggle}
        >
          {memberLabel}
          <Icon
            glyph={menu.isOpen ? SvgExpandLess : SvgExpandMore}
            className={styles.expandIcon}
          />
        </button>
      )}
      {menu.isOpen && (
        <MemberSelectMenu
          ref={inputRef}
          onSelectedItemChange={(m) => {
            menu.close();
            setMember(m);
          }}
        />
      )}
    </div>
  );
};

interface MemberSelectMenuProps {
  onSelectedItemChange: (selectedItem: SP.Member) => void;
}

const MemberSelectMenu = forwardRef<HTMLInputElement, MemberSelectMenuProps>(
  ({ onSelectedItemChange }: MemberSelectMenuProps, ref) => {
    const [inputValue, setInputValue] = useState("");

    const aborter = useRef(new AbortController());
    const { data, isValidating, error } = useMembers(
      { q: inputValue, page: 1, limit: 10 },
      aborter.current.signal
    );
    const isSuccess = !isValidating && !error;

    const items = data?.items ?? [];

    const { getMenuProps, getInputProps, highlightedIndex, getItemProps } =
      useCombobox({
        defaultIsOpen: true,
        items,
        itemToString: (item) => (item ? item.name : ""),
        inputValue,
        onStateChange: ({ inputValue, type, selectedItem }) => {
          switch (type) {
            case useCombobox.stateChangeTypes.InputChange:
              aborter.current.abort();
              aborter.current = new AbortController();
              setInputValue(inputValue ?? "");
              break;
            case useCombobox.stateChangeTypes.InputKeyDownEnter:
            case useCombobox.stateChangeTypes.ItemClick:
            case useCombobox.stateChangeTypes.InputBlur:
              if (!selectedItem) return;
              setInputValue(selectedItem.name);
              onSelectedItemChange?.(selectedItem);
              break;
            default:
              break;
          }
        },
      });

    return (
      <div className={styles.menu}>
        <hr className={styles.separator} />
        <div className={styles.search}>
          <div className={styles.combobox}>
            <input
              {...getInputProps({ ref })}
              type="search"
              placeholder="Suche Mitglieder …"
              spellCheck="false"
            />
          </div>
          {inputValue === "" && (
            <Icon glyph={SvgSearch} className={styles.searchIcon} />
          )}
          {inputValue !== "" && (
            <Icon
              glyph={SvgClose}
              className={styles.clearIcon}
              onClick={() => setInputValue("")}
            />
          )}
        </div>
        <span className={styles.title}>Mitglieder</span>
        <dl {...getMenuProps()} className={styles.suggestions}>
          {isSuccess && items.length === 0 && (
            <p className={styles.emptyText}>
              {inputValue !== "" ? (
                <span>
                  Es wurden keine mit Ihrer Suchanfrage -{" "}
                  <strong>{inputValue}</strong> - übereinstimmende Mitglieder
                  gefunden.
                </span>
              ) : (
                <span>Keine Mitglieder gefunden.</span>
              )}
            </p>
          )}
          {isValidating && (
            <div className={styles.status}>
              <LoadingSpinner delayed={175} />
            </div>
          )}
          {error && (
            <div className={styles.status}>
              <ErrorText text="Fehler beim Laden der Mitglieder" />
            </div>
          )}
          {data?.items.map((item, index) => (
            <dd
              className={classNames(styles.suggestion, {
                [styles.highlightedSuggestion]: index === highlightedIndex,
              })}
              key={index}
              {...getItemProps?.({
                item,
                index,
              })}
            >
              <span className={styles.memberRow}>
                <HighlightText
                  className={styles.memberName}
                  text={item.name}
                  highlight={inputValue}
                  showTitle={true}
                />
                <HighlightText
                  className={styles.memberId}
                  text={item.id.toString()}
                  highlight={inputValue}
                />
              </span>
            </dd>
          ))}
        </dl>
      </div>
    );
  }
);

export default MemberSelect;
