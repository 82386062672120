import { useRef, useState } from "react";
import { useMember } from "../../context/memberContext";
import {
  VV,
  getPricelistPdf,
  getPrivacyPolicyPdf,
  usePricelists,
} from "../../http/vertragsverwaltungApi";
import { getRemoteDataStatus } from "../../utils";
import SvgPdf from "../icons/Pdf";
import Icon from "../ui/Icon";
import PdfBlobPreview from "../ui/PdfBlobPreview";
import Table from "../ui/Table";
import styles from "./DocumentsTable.module.css";

const chunks = 2;

interface DocumentRowProps {
  memberId: VV.MemberId;
  colSpan: number;
  document: VV.Document;
}

const DocumentCell = ({ memberId, colSpan, document }: DocumentRowProps) => {
  const [blob, setBlob] = useState<Blob>();

  return (
    <>
      <td width="1%" className={styles.iconCell}>
        <Icon glyph={SvgPdf} className={styles.icon} />
      </td>
      <td colSpan={colSpan} className={styles.linkCell}>
        {document.type === "pricelist" && (
          <a
            href={"#"}
            onClick={async (e) => {
              e.preventDefault();
              const blob = await getPricelistPdf(
                memberId,
                document.value.id,
                document.value.name
              );
              setBlob(blob);
            }}
          >
            {document.value.name}
          </a>
        )}
        {document.type === "link" && (
          <a
            href={"#"}
            onClick={async (e) => {
              e.preventDefault();
              const blob = await document.value.link();
              setBlob(blob);
            }}
          >
            {document.value.name}
          </a>
        )}
        <PdfBlobPreview title={document.value.name} blob={blob} />
      </td>
    </>
  );
};

const DocumentsTable = () => {
  const { member } = useMember();
  const aborter = useRef(new AbortController());
  const { data, isValidating, error } = usePricelists(
    member?.id,
    aborter.current.signal
  );
  const isSuccess = !isValidating && !error;
  const pricelists = [
    ...(data?.map((pricelist) => ({
      type: "pricelist",
      value: pricelist,
    })) ?? []),
    {
      type: "link",
      value: {
        link: () => getPrivacyPolicyPdf(member!.id),
        name: "Datenschutzerklärung",
      },
    },
  ] as VV.Document[];

  const renderDocuments = (documents: VV.Document[]) =>
    Array.from(
      { length: Math.ceil(documents.length / chunks) },
      (_, i) => i
    ).map((i) => (
      <tr key={`document-row-${i}`}>
        {documents.slice(i * chunks, i * chunks + chunks).map((document, j) => (
          <DocumentCell
            key={`document-cell-${i}-${j}`}
            colSpan={
              // |h|h|
              // |c|c|
              // |c| | <- fill empty cell if necessary
              Math.ceil(documents.length / chunks) - 1 === i &&
              (documents.length % chunks) - 1 === j
                ? chunks - (documents.length % chunks) + 1 * 2
                : 1
            }
            memberId={member!.id}
            document={document}
          />
        ))}
      </tr>
    ));

  return (
    <Table
      isSelectable={false}
      head={
        <tr>
          <th align="left" colSpan={chunks * 2}>
            Dokumente Web Marketing
          </th>
        </tr>
      }
      body={member && isSuccess && renderDocuments(pricelists)}
      total={pricelists.length}
      remoteDataStatus={getRemoteDataStatus({ isValidating, error })}
    />
  );
};

export default DocumentsTable;
