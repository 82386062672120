import { useEffect, useRef, useState } from "react";
import { useMember } from "../../context/memberContext";
import { useWageCosts } from "../../http/serviceportalApi";
import { getThreeYearComparisonOperationRows } from "../../selectors/wageCosts";
import {
  configIncompleteMsg,
  getFullYearPeriod,
  getLongMonthName,
  getRemoteDataStatus,
  getWageCostPeriodUntil,
  range,
} from "../../utils";
import AnnualComparisonTable from "../bilanzOnline/AnnualComparisonTable";
import Chart from "../ui/Chart";
import ToggleSwitch from "../ui/ToggleSwitch";
import styles from "./WageCostAnalysis.module.css";

const getThreeYearPeriod = () => {
  const {
    from: { month: fromMonth, year: fromYear },
    until,
  } = getFullYearPeriod(getWageCostPeriodUntil());
  return {
    from: { year: fromYear - 2, month: fromMonth },
    until,
  };
};

const fullThreeYearPeriod = getThreeYearPeriod();

const WageCostAnalysis = () => {
  const { member } = useMember();
  const [showWholeYear, setShowWholeYear] = useState(false);
  const until = getWageCostPeriodUntil();
  const untilYear = until.getFullYear();
  const untilMonth = until.getMonth() + 1;
  const maxYearMonth = { year: untilYear, month: untilMonth };
  const [selectedMonth, setSelectedMonth] = useState<number>(untilMonth);

  const aborter = useRef(new AbortController());
  const { data, isValidating, error } = useWageCosts(
    member?.id,
    member?.giottoId,
    fullThreeYearPeriod,
    aborter.current.signal
  );
  const wageCosts = data ?? [];
  const status = getRemoteDataStatus({ isValidating, error });

  useEffect(() => setSelectedMonth(untilMonth), [data]);

  if (!member) return null;

  return (
    <>
      {!member.giottoId ? (
        <div className={styles.status}>{configIncompleteMsg}</div>
      ) : (
        <div className={styles.analyses}>
          <Chart className={styles.chart} title="Jahresübersicht">
            <Chart.Filter>
              <div />
              <div className={styles.showWholeYear}>
                <label htmlFor="showWholeYear">Zeige gesamtes Jahr</label>
                <ToggleSwitch
                  id="showWholeYear"
                  checked={showWholeYear}
                  onChange={(e) => setShowWholeYear(e.target.checked)}
                />
              </div>
            </Chart.Filter>
            <Chart.Content>
              {maxYearMonth && (
                <AnnualComparisonTable
                  period={fullThreeYearPeriod}
                  rows={getThreeYearComparisonOperationRows(
                    {
                      ...fullThreeYearPeriod,
                      until: showWholeYear
                        ? fullThreeYearPeriod.until
                        : maxYearMonth,
                    },
                    wageCosts
                  )}
                  remoteDataStatus={status}
                  title="Jahresübersicht"
                  caption={
                    <span>*Prämien / Spesenverg. / ausbez.Resturlaube</span>
                  }
                />
              )}
            </Chart.Content>
          </Chart>
          <Chart className={styles.chart} title="Monatsübersicht">
            <Chart.Filter>
              <div />
              <div className={styles.monthSelect}>
                <label htmlFor="month">Monat</label>
                <select
                  id="month"
                  value={selectedMonth}
                  onChange={(e) => setSelectedMonth(+e.target.value)}
                >
                  {maxYearMonth &&
                    range(1, maxYearMonth.month).map((x) => (
                      <option key={x} value={x}>
                        {getLongMonthName(x)}
                      </option>
                    ))}
                </select>
              </div>
            </Chart.Filter>
            <Chart.Content>
              {maxYearMonth && (
                <AnnualComparisonTable
                  period={fullThreeYearPeriod}
                  rows={getThreeYearComparisonOperationRows(
                    {
                      from: {
                        year: fullThreeYearPeriod.from.year,
                        month: selectedMonth,
                      },
                      until: {
                        year: fullThreeYearPeriod.until.year,
                        month: selectedMonth,
                      },
                    },
                    wageCosts
                  )}
                  caption={
                    <span>*Prämien / Spesenverg. / ausbez.Resturlaube</span>
                  }
                  remoteDataStatus={status}
                />
              )}
            </Chart.Content>
          </Chart>
        </div>
      )}
    </>
  );
};

export default WageCostAnalysis;
