import { useRef, useState } from "react";
import { useMember } from "../../context/memberContext";
import useListFilters from "../../hooks/useListFilters";
import {
  SP,
  acceptInvite,
  declineInvite,
  getMember,
  useInvites,
} from "../../http/serviceportalApi";
import i18n from "../../i18n";
import { formatLongDateTime, getRemoteDataStatus } from "../../utils";
import SvgCheck from "../icons/Check";
import SvgClose from "../icons/Close";
import Page from "../ui/Page";
import Table from "../ui/Table";
import TableButton, { TableButtonCell } from "../ui/TableButton";

const Invites = () => {
  const filters = useListFilters();
  const aborter = useRef(new AbortController());
  const { data, isValidating, error, mutate } = useInvites(
    filters.state,
    aborter.current.signal
  );
  const items = data?.items ?? [];
  const total = data?.total ?? 0;
  const status = getRemoteDataStatus({ isValidating, error });

  const { setMember } = useMember();

  return (
    <Page title="Einladungen">
      <Table
        filters={filters}
        head={
          <tr>
            <th align="left">Mitglied</th>
            <th align="left">Betriebsstätten</th>
            <th align="left">Gültig bis</th>
            <th></th>
            <th></th>
          </tr>
        }
        body={items.map((item, index) => (
          <Row
            key={index}
            invite={item}
            onAccept={(invite) =>
              acceptInvite(invite.id).then(() =>
                getMember(invite.memberId).then((m) => {
                  setMember(m);
                  window.location.href = window.location.origin;
                })
              )
            }
            onDecline={(invite) =>
              declineInvite(invite.id).then(() =>
                mutate({
                  ...data!,
                  items: [...items.filter((inv) => inv.id !== item.id)],
                })
              )
            }
          />
        ))}
        total={total}
        remoteDataStatus={status}
      />
    </Page>
  );
};

interface RowProps {
  invite: SP.Invite;
  onAccept: (invite: SP.Invite) => Promise<any>;
  onDecline: (invite: SP.Invite) => Promise<any>;
}

const Row = ({ invite, onAccept, onDecline }: RowProps) => {
  const [isAccepting, setAccepting] = useState(false);
  const [isDeclining, setDeclining] = useState(false);
  return (
    <tr>
      <td>{invite.memberName}</td>
      <td>{invite.companyId ? invite.companyName : i18n.allCompanies}</td>
      <td>{formatLongDateTime(invite.expiresAt)}</td>
      <TableButtonCell>
        <TableButton
          glyph={SvgCheck}
          title="Einladung annehmen"
          loading={isAccepting}
          onClick={() => {
            setAccepting(true);
            onAccept(invite).finally(() => setAccepting(false));
          }}
        />
      </TableButtonCell>
      <TableButtonCell>
        <TableButton
          glyph={SvgClose}
          title="Einladung ablehnen"
          loading={isDeclining}
          onClick={() => {
            setDeclining(true);
            onDecline(invite).finally(() => setDeclining(false));
          }}
        />
      </TableButtonCell>
    </tr>
  );
};

export default Invites;
