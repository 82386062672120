import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { useMember } from "../../context/memberContext";
import { Permission } from "../../types";
import SvgAdd from "../icons/Add";
import Button from "../ui/Button";
import NavTabs from "../ui/NavTabs";
import Page from "../ui/Page";
import MemberInvites from "./MemberInvites";
import MemberUsers from "./MemberUsers";

const MemberSettings = () => {
  const navigate = useNavigate();
  const { member } = useMember();
  const canWrite = member?.permissions?.includes(Permission.MemberUsersWrite);

  return (
    <Page
      title="Benutzer"
      description="Hier finden Sie eine Auflistung der Benutzer, die Einsicht in die Daten einer oder mehrerer Ihrer Betriebsstätten haben."
      actions={
        canWrite ? (
          <Button
            glyph={SvgAdd}
            buttonProps={{ onClick: () => navigate("benutzer/neu") }}
          >
            Benutzer einladen
          </Button>
        ) : undefined
      }
    >
      <NavTabs
        tabs={[
          { to: "benutzer", text: "Benutzer" },
          { to: "einladungen", text: "Einladungen" },
        ]}
      />
      <Routes>
        <Route path="benutzer" element={<MemberUsers />} />
        <Route path="einladungen" element={<MemberInvites />} />
        <Route path="*" element={<Navigate to="benutzer" replace />} />
      </Routes>
    </Page>
  );
};

export default MemberSettings;
