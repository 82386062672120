import classNames from "classnames";
import styles from "./TableCell.module.css";

interface Props {
  CellType?: "th" | "td";
  value: number;
  formatter: (value: number) => string;
}

const TableCell = ({ CellType = "td", value, formatter }: Props) => (
  <CellType
    align="right"
    className={classNames({
      [styles.isZero]: value === 0,
      [styles.isNegative]: value < 0,
    })}
  >
    {formatter(value)}
  </CellType>
);

export default TableCell;
