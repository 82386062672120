import { useMemo } from "react";
import { To, useLocation, useNavigate } from "react-router-dom";
import styles from "./NavTabs.module.css";
import Tab from "./Tab";

interface Tab {
  to: To;
  text: string;
}

interface Props {
  tabs: Tab[];
}

const NavTabs = ({ tabs }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const selectedIndex = useMemo(
    () => tabs.findIndex((t) => location.pathname.includes(`/${t.to}`) ?? 0),
    [tabs, location.pathname]
  );

  return (
    <Tab.Group
      selectedIndex={selectedIndex}
      onChange={(i) => navigate(`${tabs[i]?.to}`)}
    >
      <Tab.List className={styles.tabs}>
        {tabs.map((link) => (
          <Tab key={link.to.toString()}>{link.text}</Tab>
        ))}
      </Tab.List>
    </Tab.Group>
  );
};

export default NavTabs;
