import { Navigate, Route, Routes } from "react-router-dom";
import { useMember } from "../../context/memberContext";
import { Permission } from "../../types";
import { flattenPermissions } from "../../utils";
import NavTabs from "../ui/NavTabs";
import Page from "../ui/Page";
import AccountingCharts from "./AccountingCharts";
import MonthlyAnalysis from "./MonthlyAnalysis";
import ThreeYearAnalysis from "./ThreeYearAnalysis";
import WageCostAnalysis from "./WageCostAnalysis";
import WageCostCharts from "./WageCostCharts";
import WebsiteAnalysis from "./WebsiteAnalysis";

const BilanzOnline = () => {
  const { member } = useMember();
  const memberUserPermissions = member ? flattenPermissions(member) : [];

  const hasWageCosts = memberUserPermissions.includes(Permission.WageCostsRead);
  const hasAccountings = memberUserPermissions.includes(
    Permission.AccountingsRead
  );
  const hasWebsiteMetrics = memberUserPermissions.includes(
    Permission.WebsiteMetricsRead
  );

  const tabs = [
    ...(hasWageCosts
      ? [
          {
            to: "lohnkosten-aufstellung",
            text: "Aufstellung Lohnkosten",
          },
          {
            to: "lohnkosten-grafiken",
            text: "Grafische Darstellung Lohnkosten",
          },
        ]
      : []),
    ...(hasAccountings
      ? [
          {
            to: "buchhalterische-drei-jahres-analyse",
            text: "Buchhalterische Drei-Jahres-Analyse",
          },
          {
            to: "buchhalterische-monats-analyse",
            text: "Buchhalterische Monats-Analyse",
          },
          {
            to: "buchhalterische-kennzahlen",
            text: "Buchhalterische Kennzahlen",
          },
        ]
      : []),
    ...(hasWebsiteMetrics
      ? [
          {
            to: "webseitenanalyse",
            text: "Webseitenanalyse",
          },
        ]
      : []),
  ];
  const firstTab = tabs[0];

  return (
    <Page
      title="Kennzahlen"
      description="Hier können Sie jederzeit Ihre aktuellen Daten abrufen und analysieren sowie Vergleichszeiträume erstellen und Ihre Kennzahlen prüfen."
    >
      <NavTabs tabs={tabs} />
      <Routes>
        {hasWageCosts && (
          <>
            <Route
              path="lohnkosten-aufstellung"
              element={<WageCostAnalysis />}
            />
            <Route path="lohnkosten-grafiken" element={<WageCostCharts />} />
          </>
        )}
        {hasAccountings && (
          <>
            <Route
              path="buchhalterische-drei-jahres-analyse"
              element={<ThreeYearAnalysis />}
            />
            <Route
              path="buchhalterische-monats-analyse"
              element={<MonthlyAnalysis />}
            />
            <Route
              path="buchhalterische-kennzahlen"
              element={<AccountingCharts />}
            />
          </>
        )}
        {hasWebsiteMetrics && (
          <Route path="webseitenanalyse" element={<WebsiteAnalysis />} />
        )}
        {firstTab && (
          <Route path="*" element={<Navigate to={firstTab.to} replace />} />
        )}
      </Routes>
    </Page>
  );
};

export default BilanzOnline;
