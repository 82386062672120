import classNames from "classnames";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMember } from "../../context/memberContext";
import useListFilters from "../../hooks/useListFilters";
import { SP, deleteMemberUser, useUsers } from "../../http/serviceportalApi";
import i18n from "../../i18n";
import { Permission } from "../../types";
import { getRemoteDataStatus } from "../../utils";
import PermissionTable from "../PermissionTable";
import SvgDelete from "../icons/Delete";
import SvgEdit from "../icons/Edit";
import Badge from "../ui/Badge";
import ExpandableRow from "../ui/ExpandableRow";
import ModalDialog from "../ui/ModalDialog";
import Table from "../ui/Table";
import TableButton, { TableButtonCell } from "../ui/TableButton";
import styles from "./MemberUsers.module.css";

const MemberUsers = () => {
  const navigate = useNavigate();
  const { member } = useMember();
  const filters = useListFilters();
  const aborter = useRef(new AbortController());
  const { data, isValidating, error, mutate } = useUsers(
    member?.id,
    filters.state,
    aborter.current.signal
  );
  const items = data?.items ?? [];
  const total = data?.total ?? 0;
  const status = getRemoteDataStatus({ isValidating, error });

  const canWrite = member?.permissions?.includes(Permission.MemberUsersWrite);

  const onEdit = (user: SP.User, companyId?: SP.CompanyId) =>
    navigate(`${user.id}${companyId ? `/${companyId}` : ""}`);

  if (!member) return null;

  return (
    <Table
      filters={filters}
      head={
        <ExpandableRow
          CellType="th"
          cells={[
            <th className={styles.alignLeft}>Name</th>,
            <th className={styles.alignLeft}>E-Mail-Adresse</th>,
            <th className={styles.alignLeft}>Betriebsstätten</th>,
          ]}
        />
      }
      body={items.map((user) => (
        <ExpandableRow
          key={user.id}
          CellType="td"
          cells={[
            <td>
              <div className={styles.name}>
                {user.name}
                {user.sources.includes("owner") && (
                  <Badge color="grey">Gesetzlicher Vertreter</Badge>
                )}
              </div>
            </td>,
            <td>{user.email}</td>,
            <td>
              {user.sources.some((s) => ["owner", "member"].includes(s))
                ? i18n.allCompanies
                : i18n.companies(user.companies.length)}
            </td>,
          ]}
          expandedContent={
            <Table
              isSelectable={false}
              head={
                <tr>
                  <th className={classNames(styles.alignLeft, styles.company)}>
                    Betriebsstätte
                  </th>
                  <th className={styles.alignLeft}>Zugriff</th>
                  {canWrite && (
                    <>
                      <th></th>
                      <th></th>
                    </>
                  )}
                </tr>
              }
              body={[
                user.sources.some((s) => ["owner", "member"].includes(s)) && (
                  <tr key={user.id}>
                    <td>{i18n.allCompanies}</td>
                    <td>
                      <PermissionTable permissions={user.permissions ?? []} />
                    </td>
                    {canWrite && (
                      <>
                        <TableButtonCell>
                          <TableButton
                            glyph={SvgEdit}
                            onClick={() => onEdit(user)}
                          />
                        </TableButtonCell>
                        <TableButtonCell>
                          <DeleteAssignmentButton
                            user={user}
                            onSubmit={() =>
                              deleteMemberUser(
                                member.id,
                                undefined,
                                user.id
                              ).then(() =>
                                mutate({
                                  ...data!,
                                  items: data!.items.filter(
                                    (u) =>
                                      u.id !== user.id || u.sources.length > 1
                                  ),
                                })
                              )
                            }
                          />
                        </TableButtonCell>
                      </>
                    )}
                  </tr>
                ),
                user.companies
                  .filter((c) => c.sources.includes("company"))
                  .map((c) => (
                    <tr key={c.id}>
                      <td>{c.name}</td>
                      <td>
                        <PermissionTable permissions={c.permissions ?? []} />
                      </td>
                      {canWrite && (
                        <>
                          <TableButtonCell>
                            <TableButton
                              glyph={SvgEdit}
                              onClick={() => onEdit(user, c.id)}
                            />
                          </TableButtonCell>
                          <TableButtonCell>
                            <DeleteAssignmentButton
                              user={user}
                              companyName={c.name}
                              onSubmit={() =>
                                deleteMemberUser(member.id, c.id, user.id).then(
                                  () =>
                                    mutate({
                                      ...data!,
                                      items: data!.items.reduce((state, u) => {
                                        if (user.id !== u.id) {
                                          return [...state, u];
                                        }

                                        const filteredCompanies =
                                          u.companies.filter(
                                            (company) => company.id !== c.id
                                          );
                                        if (filteredCompanies.length === 0) {
                                          return state;
                                        }

                                        return [
                                          ...state,
                                          {
                                            ...u,
                                            companies: filteredCompanies,
                                          },
                                        ];
                                      }, [] as SP.User[]),
                                    })
                                )
                              }
                            />
                          </TableButtonCell>
                        </>
                      )}
                    </tr>
                  )),
              ]}
              total={user.companies.length ?? 1}
            />
          }
        />
      ))}
      total={total}
      remoteDataStatus={status}
    />
  );
};

interface DeleteAssignmentButtonProps {
  user: SP.User;
  companyName?: string;
  onSubmit: () => Promise<any>;
}

const DeleteAssignmentButton = ({
  user,
  companyName,
  onSubmit,
}: DeleteAssignmentButtonProps) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  return (
    <>
      <ModalDialog
        title="Sind Sie sich sicher?"
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        actions={[
          {
            title: "Abbrechen",
            type: "secondary",
            onClick: () => setModalOpen(false),
          },
          {
            title: "Entfernen",
            type: "destructive",
            isSubmitting: isSubmitting,
            onClick: () => {
              setSubmitting(true);
              onSubmit()
                .then(() => setModalOpen(false))
                .catch(() => setSubmitting(false));
            },
          },
        ]}
      >
        <p>
          Wollen Sie <strong>{user.name}</strong> wirklich von{" "}
          <strong>{companyName ?? "allen Betriebsstätten"}</strong> entfernen?
        </p>
      </ModalDialog>
      <TableButton glyph={SvgDelete} onClick={() => setModalOpen(true)} />
    </>
  );
};

export default MemberUsers;
