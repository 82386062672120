import Page from "../ui/Page";
import styles from "./Arbeitssicherheit.module.css";

const Arbeitssicherheit = () => {
  return (
    <Page
      title="Arbeitssicherheit für Arbeitnehmer:innen"
      backLinkText="Zurück zu Personalberatung"
      narrow={true}
    >
      <div>
        <p>
          Mitarbeiter:innen im Hotel- und Gastgewerbe (niedriges Risiko) müssen
          einen achtstündigen Arbeitssicherheitskurs absolvieren, welcher sich
          aus vier Stunden allgemeiner Ausbildung und vier Stunden spezifischer
          Ausbildung zusammensetzt.
          <br />
          Der Arbeitssicherheitskurs muss innerhalb von 60 Tagen nach
          Arbeitsbeginn abgeschlossen werden.
          <br />
          Mitarbeiter:innen, welche den achtstündigen Grundkurs zur
          Arbeitssicherheit bereits besucht haben, müssen alle fünf Jahre ab
          Kursbesuch einen Auffrischungskurs von sechs Stunden besuchen.
        </p>
        <p>
          <a
            href="https://www.hgv.it/de/dienstleistungen/weiterbildung/arbeitssicherheit-fuer-arbeitnehmer"
            target="_blank"
          >
            Hier
          </a>{" "}
          können Sie Ihre Mitarbeiter:innen zum Online-Kurs „Arbeitssicherheit“
          anmelden.
        </p>
        <p>
          <a
            href="https://www.hgv.it/de/dienstleistungen/weiterbildung#c282"
            target="_blank"
          >
            Hier
          </a>{" "}
          können Sie Ihre Mitarbeiter:innen zu einem der offenen
          Weiterbildungskurse zum Thema „Arbeitssicherheit“ in Präsenzform
          anmelden.
        </p>

        <p className={styles.caption}>Stand: März 2023</p>
      </div>
    </Page>
  );
};

export default Arbeitssicherheit;
