import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { AuthProvider, RequireAuth } from "../context/authContext";
import NotificationDispatch, {
  useNotification,
} from "../context/notificationContext";
import styles from "./App.module.css";
import Notification from "./ui/Notification";
import { AppsProvider } from "../context/appsContext";
import { MemberProvider } from "../context/memberContext";
import AdminApp from "./AdminApp";
import CustomerApp from "./CustomerApp";
import EconomicAdvisorApp from "./EconomicAdvisorApp";
import { useMe, useMembers } from "../http/serviceportalApi";
import {
  isRouteEconomicAdvisors,
  checkHasAdminPermission,
  checkHasEconomicAdvisorPermission,
  isRoutePrivateHousehold,
  checkHasPrivateHouseholdPermission,
  checkHasRevisorPermission,
  isRouteRevisor,
} from "../utils";
import { useEffect } from "react";
import PrivateHouseholdApp from "./PrivateHouseholdApp";
import RevisorApp from "./RevisorApp";

const App = () => {
  const [{ show, severity, message }, dispatch] = useNotification();

  return (
    <BrowserRouter>
      <AuthProvider>
        <NotificationDispatch.Provider value={dispatch}>
          <div className={styles.app}>
            <RequireAuth>
              <AppRoutes />
            </RequireAuth>
          </div>
          {show && <Notification type={severity} text={message ?? ""} />}
        </NotificationDispatch.Provider>
      </AuthProvider>
    </BrowserRouter>
  );
};

const AppRoutes = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { data: me, isLoading: isLoadingUserPermissions } = useMe();
  const userPermissions = me?.permissions ?? [];
  const hasAdminPermission = checkHasAdminPermission(userPermissions);
  const hasEconomicAdvisorPermission =
    checkHasEconomicAdvisorPermission(userPermissions);
  const hasPrivateHouseholdPermission =
    checkHasPrivateHouseholdPermission(userPermissions);
  const hasRevisorPermission = checkHasRevisorPermission(userPermissions);

  const { data: members, isLoading: isLoadingMemberPermissions } = useMembers({
    page: 1,
    limit: 1,
  });
  const hasMemberPermission = members?.items.length ?? 0 > 0;

  const canNavigateToEconomicAdvisorApp =
    hasEconomicAdvisorPermission && !isRouteEconomicAdvisors(location);
  const canNavigateToPrivateHouseholdApp =
    hasPrivateHouseholdPermission && !isRoutePrivateHousehold(location);
  const canNavigateToRevisorApp =
    hasRevisorPermission && !isRouteRevisor(location);

  // Redirect to /wirtschaftsberater if it's the only available option
  useEffect(() => {
    if (isLoadingUserPermissions || isLoadingMemberPermissions) {
      return;
    }

    if (
      canNavigateToEconomicAdvisorApp &&
      !canNavigateToPrivateHouseholdApp &&
      !canNavigateToRevisorApp &&
      !hasMemberPermission &&
      !hasAdminPermission
    ) {
      navigate("/wirtschaftsberater", { replace: true });
    }
  }, [
    canNavigateToEconomicAdvisorApp,
    canNavigateToPrivateHouseholdApp,
    canNavigateToRevisorApp,
    hasMemberPermission,
    hasAdminPermission,
    isLoadingUserPermissions,
    isLoadingMemberPermissions,
  ]);

  // Redirect to /privathaushalt if it's the only available option
  useEffect(() => {
    if (isLoadingUserPermissions || isLoadingMemberPermissions) {
      return;
    }

    if (
      canNavigateToPrivateHouseholdApp &&
      !canNavigateToEconomicAdvisorApp &&
      !canNavigateToRevisorApp &&
      !hasMemberPermission &&
      !hasAdminPermission
    ) {
      navigate("/privathaushalt", { replace: true });
    }
  }, [
    canNavigateToPrivateHouseholdApp,
    canNavigateToEconomicAdvisorApp,
    canNavigateToRevisorApp,
    hasMemberPermission,
    hasAdminPermission,
    isLoadingUserPermissions,
    isLoadingMemberPermissions,
  ]);

  // Redirect to /revisoren if it's the only available option
  useEffect(() => {
    if (isLoadingUserPermissions || isLoadingMemberPermissions) {
      return;
    }

    if (
      canNavigateToRevisorApp &&
      !canNavigateToEconomicAdvisorApp &&
      !canNavigateToPrivateHouseholdApp &&
      !hasMemberPermission &&
      !hasAdminPermission
    ) {
      navigate("/revisoren", { replace: true });
    }
  }, [
    canNavigateToRevisorApp,
    canNavigateToEconomicAdvisorApp,
    canNavigateToPrivateHouseholdApp,
    hasMemberPermission,
    hasAdminPermission,
    isLoadingUserPermissions,
    isLoadingMemberPermissions,
  ]);

  return (
    <Routes>
      {hasAdminPermission && <Route path="admin/*" element={<AdminApp />} />}
      {hasEconomicAdvisorPermission && (
        <Route path="wirtschaftsberater/*" element={<EconomicAdvisorApp />} />
      )}
      {hasPrivateHouseholdPermission && (
        <Route path="privathaushalt/*" element={<PrivateHouseholdApp />} />
      )}
      {hasRevisorPermission && (
        <Route path="revisoren/*" element={<RevisorApp />} />
      )}
      <Route
        path="*"
        element={
          <MemberProvider>
            <AppsProvider>
              <CustomerApp />
            </AppsProvider>
          </MemberProvider>
        }
      />
    </Routes>
  );
};

export default App;
