import { SP } from "../../http/serviceportalApi";
import { getDefaultPeriod, getWageCostPeriodUntil } from "../../utils";

interface Props {
  id?: string;
  period: SP.Period;
  onPeriodChange: (period: SP.Period) => void;
}

const maxFromYear = getDefaultPeriod(getWageCostPeriodUntil()).from.year;

const NumberOfYearsSelect = ({ id, period, onPeriodChange }: Props) => (
  <select
    id={id}
    value={period.from.year}
    onChange={(e) =>
      onPeriodChange({
        ...period,
        from: { ...period.from, year: +e.target.value },
      })
    }
  >
    <option value={maxFromYear}>1 Jahr</option>
    <option value={maxFromYear - 1}>2 Jahre</option>
    <option value={maxFromYear - 2}>3 Jahre</option>
  </select>
);

export default NumberOfYearsSelect;
