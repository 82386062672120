import { useMe } from "../http/serviceportalApi";
import QuestionnaireTab from "./questionnaire/QuestionnaireTab";
import Page from "./ui/Page";

const EconomicAdvisorQuestionnaire = () => {
  const { data, isValidating } = useMe();

  return (
    <Page
      title="Modell CU / 770"
      description={
        <>
          Untenstehend finden Sie die Liste unserer gemeinsamen Kunden.
          <br />
          <br />
          Bei jenen Kunden, für welche Sie den Versand der Certificazione Unica
          (CU) betreffend Freiberufler durchgeführt haben, aktivieren Sie bitte
          das Kontrollkästchen. Durch die Aktivierung übernehmen Sie auch
          gleichzeitig die Verantwortung für den termingerechten Versand des
          Mod. 770 betreffend Freiberufler. Bitte geben Sie jene Steuernummer
          an, mit welcher Sie den Versand des Mod. 770 vornehmen werden.
          <br />
          <br />
          Der telematische Versand des Mod. 770 (betreffend Arbeitnehmende)
          erfolgt von Seiten der HGV-Service-Genossenschaft mit folgender
          Steuernummer: 00576540215.
        </>
      }
      isLoading={isValidating}
    >
      <QuestionnaireTab luid={data?.economicAdvisor?.luid!} />
    </Page>
  );
};

export default EconomicAdvisorQuestionnaire;
