import classNames from "classnames";
import { forwardRef } from "react";
import { FieldError } from "react-hook-form";
import { SP } from "../../http/serviceportalApi";
import SvgCheckbox, { SvgCheckboxOutlineBlank } from "../icons/Checkbox";
import Icon from "../ui/Icon";
import styles from "./CountrySelect.module.css";

interface Props {
  countries: SP.Country[];
  selectedItems: string[];
  error: FieldError | undefined;
  onChange: (selected: string[]) => void;
}

const CountrySelect = forwardRef<HTMLDivElement, Props>(
  ({ countries, selectedItems, error, onChange }, ref) => {
    const { quickAccessItems, remainingItems } = countries.reduce(
      (
        state: { quickAccessItems: SP.Country[]; remainingItems: SP.Country[] },
        value
      ) => {
        value.order !== undefined
          ? state.quickAccessItems.push(value)
          : state.remainingItems.push(value);
        return state;
      },
      { quickAccessItems: [], remainingItems: [] }
    );

    const renderItem = (country: SP.Country) => {
      const isSelected = selectedItems.includes(country.alpha2Code);
      return (
        <Item
          key={country.alpha2Code}
          country={country}
          isSelected={isSelected}
          onClick={() =>
            onChange([
              ...selectedItems.filter((item) => item !== country.alpha2Code),
              ...(!isSelected ? [country.alpha2Code] : []),
            ])
          }
        />
      );
    };

    return (
      <>
        <div
          ref={ref}
          tabIndex={0} // Needed to make div focusable
          className={classNames(styles.countrySelect, {
            [styles.invalid]: error !== undefined,
          })}
        >
          <ul>{quickAccessItems.sort(byOrder).map(renderItem)}</ul>
          <hr className={styles.separator} />
          <ul>{remainingItems.sort(byName).map(renderItem)}</ul>
        </div>
        {error && <div className={styles.error}>{error.message}</div>}
      </>
    );
  }
);

interface ItemProps {
  country: SP.Country;
  isSelected: boolean;
  onClick: () => void;
}

const Item = ({ country, isSelected, onClick }: ItemProps) => (
  <li className={styles.item} onClick={onClick}>
    <Icon glyph={isSelected ? SvgCheckbox : SvgCheckboxOutlineBlank} />
    {country.name}
  </li>
);

const byOrder = (a: SP.Country, b: SP.Country) => {
  if (!a.order) return -1;
  if (!b.order) return 1;
  return a.order - b.order;
};

const byName = (a: SP.Country, b: SP.Country) => a.name.localeCompare(b.name);

export default CountrySelect;
