import { SP } from "../../http/serviceportalApi";
import { formatYearMonth, getLongMonthName, range } from "../../utils";
import styles from "./PeriodSelect.module.css";

interface Props {
  period: SP.Period;
  periodUntil?: Date;
  onChange: (value: SP.Period) => void;
}

const PeriodSelect = ({
  period,
  periodUntil = new Date(),
  period: { from, until },
  onChange,
}: Props) => {
  const fixUntilYearMonth = ({ from, until }: SP.Period): SP.Period =>
    +formatYearMonth(from) > +formatYearMonth(until)
      ? { from, until: from }
      : { from, until };

  const fixFromYearMonth = ({ from, until }: SP.Period): SP.Period =>
    +formatYearMonth(until) < +formatYearMonth(from)
      ? { from: until, until }
      : { from, until };

  const renderYearMonth = (
    id: string,
    yearMonth: SP.YearMonth,
    onChange: (value: SP.YearMonth) => void
  ) => (
    <select
      id={id}
      value={formatYearMonth(yearMonth)}
      onChange={(e) => {
        const yearMonth = +e.target.value;
        const year = Math.floor(yearMonth / 100);
        const month = yearMonth % 100;
        onChange({ year, month });
      }}
    >
      {range(2010, periodUntil.getFullYear()).map((year) => (
        <optgroup key={year} label={year.toString()}>
          {range(1, 12)
            .filter(
              (month) =>
                year !== periodUntil.getFullYear() ||
                month <= periodUntil.getMonth() + 1
            )
            .map((month) => (
              <option key={month} value={formatYearMonth({ year, month })}>
                {getLongMonthName(month)} {year}
              </option>
            ))}
        </optgroup>
      ))}
    </select>
  );

  return (
    <div className={styles.periodSelect}>
      <div className={styles.period}>
        <label htmlFor="from">Von</label>
        {renderYearMonth("from", from, (yearMonth) =>
          onChange(fixUntilYearMonth({ ...period, from: yearMonth }))
        )}
      </div>
      <div className={styles.period}>
        <label htmlFor="until">Bis</label>
        {renderYearMonth("until", until, (yearMonth) =>
          onChange(fixFromYearMonth({ ...period, until: yearMonth }))
        )}
      </div>
    </div>
  );
};

export default PeriodSelect;
