import { ReactNode, useRef } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { SP, useChart2Data } from "../../http/serviceportalApi";
import { getChartDataGroupedByRange } from "../../selectors/accountingCharts";
import {
  ChartDataGroupedByRange,
  ChartRowGroupedByRange,
  Evaluation,
} from "../../types";
import {
  colorCycle,
  formatAsCurrencyWith,
  formatAsPercent,
  getValueBasedOnValueType,
} from "../../utils";
import SvgBullet from "../icons/Bullet";
import Chart from "../ui/Chart";
import ErrorText from "../ui/ErrorText";
import LoadingSpinner from "../ui/LoadingSpinner";
import styles from "./Chart2.module.css";

interface Props {
  memberId: number;
  seacNumber: string;
  filters: ReactNode;
  companyId?: SP.CompanyId;
  evaluation: Evaluation;
  period: SP.Period;
  showVerwalterentschaedigung: boolean;
  valueType: SP.ValueType;
  className?: string;
}

const Chart2 = ({
  memberId,
  seacNumber,
  filters,
  companyId,
  evaluation,
  period,
  showVerwalterentschaedigung,
  valueType,
  className,
}: Props) => {
  const aborter = useRef(new AbortController());
  const { data, isValidating, error } = useChart2Data(
    memberId,
    seacNumber,
    period,
    showVerwalterentschaedigung,
    evaluation === "competence",
    companyId,
    aborter.current.signal
  );
  const isSuccess = !isValidating && !error;
  const chart2Data = data ?? [];

  const formatter = (value: number) =>
    valueType === "currency"
      ? formatAsCurrencyWith(0, value)
      : formatAsPercent(2, value);

  const renderChart = ({ labels, chartRows }: ChartDataGroupedByRange) => (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart data={chartRows}>
        <CartesianGrid
          vertical={false}
          stroke="var(--ci-light-grey)"
          strokeDasharray="3 3"
        />
        <XAxis
          dataKey="range"
          axisLine={{ stroke: "var(--ci-light-grey)" }}
          tickLine={{ stroke: "var(--ci-light-grey)" }}
          stroke="var(--ci-grey)"
          strokeWidth="2px"
        />
        <YAxis
          tickFormatter={formatter}
          axisLine={{ stroke: "var(--ci-light-grey)" }}
          tickLine={{ stroke: "var(--ci-light-grey)" }}
          stroke="var(--ci-grey)"
          strokeWidth="2px"
          width={111}
        />
        <Legend
          layout="vertical"
          align="right"
          verticalAlign="middle"
          iconSize={24}
          width={368}
          wrapperStyle={{ paddingLeft: "1em" }}
          payload={labels.reverse().map((label, i) => ({
            key: i,
            value: label,
            color: "var(--ci-grey)",
            legendIcon: <SvgBullet fill={colorCycle(labels.length - 1 - i)} />,
          }))}
        />
        {labels.map((label, i) => {
          const getValueFromChartRow = (row: ChartRowGroupedByRange) =>
            getValueBasedOnValueType(row.values[i] ?? [0, 0], valueType);

          return (
            <Bar
              key={i}
              name={label}
              dataKey={getValueFromChartRow}
              fill={colorCycle(i)}
              stackId="a"
            >
              <LabelList
                valueAccessor={getValueFromChartRow}
                formatter={formatter}
                position="center"
              />
            </Bar>
          );
        })}
      </BarChart>
    </ResponsiveContainer>
  );

  return (
    <Chart className={className} title="Betriebskostenentwicklung">
      <Chart.Filter>{filters}</Chart.Filter>
      <Chart.Content>
        {isSuccess &&
          renderChart(getChartDataGroupedByRange(chart2Data, period))}
        {isValidating && (
          <div className={styles.status}>
            <LoadingSpinner />
          </div>
        )}
        {error && (
          <div className={styles.status}>
            <ErrorText text="Fehler beim Laden der Betriebskostenentwicklung." />
          </div>
        )}
      </Chart.Content>
    </Chart>
  );
};

export default Chart2;
