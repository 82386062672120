import { useContext } from "react";
import { Controller } from "react-hook-form";
import { useMember } from "../../context/memberContext";
import NotificationDispatch, {
  showErrorNotification,
  showSuccessNotification,
} from "../../context/notificationContext";
import { SP, postEmergency } from "../../http/serviceportalApi";
import { getPayrollOffice } from "../../utils";
import Button from "../ui/Button";
import Checkbox from "../ui/Checkbox";
import CompanySelect from "../ui/CompanySelect";
import Form from "../ui/Form";
import FormField, { Error } from "../ui/FormField";
import Input from "../ui/Input";
import ModalDialog from "../ui/ModalDialog";
import styles from "./EmergencyModal.module.css";

interface FormData {
  companyId?: SP.CompanyId;
  phone: string;
  description: string;
  acceptTerms: boolean;
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const EmergencyModal = ({ isOpen, onClose }: Props) => {
  const { member } = useMember();
  const dispatch = useContext(NotificationDispatch);

  if (!member) {
    return null;
  }

  return (
    <ModalDialog
      title="Individueller Notfalldienst der HGV-Personalberatung"
      isOpen={isOpen}
      onClose={onClose}
    >
      <Form<FormData>
        defaultValues={{
          phone: "",
          description: "",
          acceptTerms: false,
        }}
        submitText="Notruf absetzen"
        onSubmit={() => (formData) => {
          const office = getPayrollOffice(member.giottoId);

          if (!member.giottoId || !office) return;

          const selectedCompany = member.companies?.find(
            (c) => c.id === formData.companyId
          );
          const firstCompany = member.companies?.[0];

          postEmergency({
            giottoId: member.giottoId,
            companyName:
              // Fall back to member name if the member has no companies
              selectedCompany?.name ?? firstCompany?.name ?? member.name,
            office,
            phone: formData.phone,
            description: formData.description,
          })
            .then(() => {
              dispatch(
                showSuccessNotification("Notruf erfolgreich abgesetzt.")
              );
              onClose();
            })
            .catch((error) =>
              dispatch(
                showErrorNotification(
                  error,
                  "Fehler beim Absetzen des Notrufs."
                )
              )
            );
        }}
        secondaryButton={
          <Button type="secondary" buttonProps={{ onClick: onClose }}>
            Abbrechen
          </Button>
        }
      >
        {({ register, control, formState: { errors } }) => (
          <div className={styles.formFields}>
            {member.companies && member.companies.length > 1 && (
              <FormField
                label="Betrieb"
                direction="column"
                error={errors.companyId}
              >
                <CompanySelect
                  id="company"
                  autoFocus={true}
                  isSingleSelect={true}
                  {...register("companyId", {
                    required: true,
                    valueAsNumber: true,
                  })}
                />
              </FormField>
            )}

            <FormField
              label="Telefonnummer"
              direction="column"
              error={errors.phone}
            >
              {({ labelId, isOptional }) => (
                <Input
                  id={labelId}
                  type="tel"
                  {...register("phone", {
                    required: !isOptional,
                  })}
                />
              )}
            </FormField>

            <FormField
              label="Kurze Beschreibung des Vorfalles/Anliegen"
              direction="column"
              error={errors.description}
            >
              {({ labelId, isOptional }) => (
                <textarea
                  id={labelId}
                  rows={7}
                  spellCheck={false}
                  {...register("description", {
                    required: !isOptional,
                  })}
                />
              )}
            </FormField>

            <Controller
              name="acceptTerms"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <>
                  <Checkbox
                    label="Ich erkläre mich mit der Aktivierung des individuellen
                          Notfalldienstes einverstanden, für welchen an den Feiertagen
                          eine Bearbeitungsgebühr von 130,00 Euro berechnet wird."
                    isSelected={field.value}
                    onChange={field.onChange}
                  />
                  {errors.acceptTerms && (
                    <Error
                      error={{
                        type: "required",
                        message:
                          "Bitte kreuzen Sie dieses Feld an, wenn Sie fortfahren möchten",
                      }}
                    />
                  )}
                </>
              )}
            />
          </div>
        )}
      </Form>
    </ModalDialog>
  );
};

export default EmergencyModal;
