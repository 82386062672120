import { PieLabelRenderProps } from "recharts";
import { formatAsPercent } from "../../utils";

interface PercentPieChartLabelProps extends PieLabelRenderProps {}

const RADIAN = Math.PI / 180;

const tryParseNumber = (str: string | undefined, defaultValue = 0) => {
  if (str === undefined) return defaultValue;
  const parsed = parseInt(str, 10);
  return !isNaN(parsed) ? parsed : defaultValue;
};

const tryParseStringOrNumber = (
  stringOrNumber: string | number | undefined,
  defaultValue = 0
) => {
  if (typeof stringOrNumber === "number") return stringOrNumber;
  return tryParseNumber(stringOrNumber, defaultValue);
};

const PercentPieChartLabel = ({
  cx: cxP,
  cy: cyP,
  midAngle: midAngleP,
  innerRadius: innerRadiusP,
  outerRadius: outerRadiusP,
  percent: percentP,
}: PercentPieChartLabelProps) => {
  const cx = tryParseStringOrNumber(cxP);
  const cy = tryParseStringOrNumber(cyP);
  const midAngle = midAngleP ?? 0;
  const innerRadius = tryParseStringOrNumber(innerRadiusP);
  const outerRadius = tryParseStringOrNumber(outerRadiusP);
  const percent = percentP ?? 0;

  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor="middle"
      dominantBaseline="central"
    >
      {formatAsPercent(0, percent)}
    </text>
  );
};

export default PercentPieChartLabel;
