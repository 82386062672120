import { ReactNode } from "react";
import {
  DefaultValues,
  FieldValues,
  SubmitHandler,
  useForm,
  UseFormReset,
  UseFormReturn,
} from "react-hook-form";
import Button from "./Button";
import styles from "./Form.module.css";

type FormChildrenProps<TFieldValues extends FieldValues> =
  UseFormReturn<TFieldValues> & {
    disabled?: boolean;
  };

type Props<TFieldValues extends FieldValues> = {
  disabled?: boolean;
  secondaryButton?: ReactNode;
  onSubmit?: (reset: UseFormReset<TFieldValues>) => SubmitHandler<TFieldValues>;
  submitText?: ReactNode;
  defaultValues?: DefaultValues<TFieldValues>;
  children?:
    | ReactNode
    | ((useFormProps: FormChildrenProps<TFieldValues>) => ReactNode);
};

const Form = <TFieldValues extends FieldValues>({
  children,
  disabled,
  secondaryButton,
  submitText = "Speichern",
  defaultValues,
  onSubmit,
}: Props<TFieldValues>) => {
  const useFormProps = useForm<TFieldValues>({
    defaultValues,
    mode: "onBlur",
  });
  const { isSubmitting } = useFormProps.formState;

  return (
    <form
      onSubmit={(e) => {
        if (onSubmit) {
          e.stopPropagation();
          useFormProps.handleSubmit(onSubmit(useFormProps.reset))(e);
        }
      }}
    >
      {typeof children === "function"
        ? children({ ...useFormProps, disabled })
        : children}

      {onSubmit && (
        <div className={styles.submitBar}>
          {secondaryButton}
          <Button
            type="primary"
            isLoading={isSubmitting}
            buttonProps={{
              type: "submit",
              disabled: disabled || isSubmitting,
            }}
          >
            {submitText}
          </Button>
        </div>
      )}
    </form>
  );
};

export default Form;
