import PDFObject from "pdfobject";
import ModalDialog from "./ModalDialog";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  src: string | undefined;
}

const PdfPreview = ({ isOpen, onClose, title, src }: Props) => (
  <ModalDialog title={title} isOpen={isOpen} onClose={onClose} size="large">
    <div
      style={{ height: window.innerHeight - 180 }}
      ref={(elem) =>
        src &&
        elem &&
        PDFObject.embed(src, elem, {
          pdfOpenParams: { view: "FitV", page: 1 },
          fallbackLink:
            "<p>Dieser Browser unterstützt keine PDF-Vorschau. Bitte laden Sie das <a href='[url]'>PDF</a> herunter, um es anzusehen.</p>",
        })
      }
    />
  </ModalDialog>
);

export default PdfPreview;
