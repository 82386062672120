import { SP } from "../http/serviceportalApi";

const byReferenceOrder =
  (referenceOrder: SP.DocumentSetType[]) =>
  ({ type: a }: SP.DocumentSet, { type: b }: SP.DocumentSet) =>
    referenceOrder.indexOf(a) - referenceOrder.indexOf(b);

export const sort = (sets: SP.DocumentSet[], archive: SP.DocumentArchive) => {
  const getOrder = (archive: SP.DocumentArchive): SP.DocumentSetType[] => {
    switch (archive) {
      case "payroll":
        return [
          "lohn-rundschreiben",
          "prov-lohnstreifen",
          "lohnauswertung",
          "zusaetzliche-auswertungen",
          "einheitslohnbuch",
          "matrikelbuch",
          "arbeitsvertraege",
          "antrag-um-familiengeld",
          "zahlungsbestaetigung-f24",
          "formblatt-770",
          "formblatt-cu",
          "jahresdokumente",
        ];
      case "accounting":
        return ["steuer-rundschreiben", "jahresauswertungen"];
    }
  };

  return sets.sort(byReferenceOrder(getOrder(archive)));
};

export const shouldShowUnreadCount = (type: SP.DocumentSetType) => {
  const whitelist: SP.DocumentSetType[] = [
    "lohn-rundschreiben",
    "prov-lohnstreifen",
    "lohnauswertung",
    "zusaetzliche-auswertungen",
    "matrikelbuch",
    "arbeitsvertraege",
    "antrag-um-familiengeld",
    "zahlungsbestaetigung-f24",
    "formblatt-770",
    "formblatt-cu",
    "steuer-rundschreiben",
  ];

  return whitelist.includes(type);
};
