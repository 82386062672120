import { ReactNode, useRef } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { useMember } from "../../context/memberContext";
import { SP, useChart5Data } from "../../http/serviceportalApi";
import { getChartDataGroupedByRange } from "../../selectors/accountingCharts";
import {
  ChartDataGroupedByRange,
  ChartRowGroupedByRange,
  Evaluation,
} from "../../types";
import {
  colorCycle,
  formatAsCurrencyWith,
  getValueBasedOnValueType,
} from "../../utils";
import SvgBullet from "../icons/Bullet";
import Chart from "../ui/Chart";
import ErrorText from "../ui/ErrorText";
import LoadingSpinner from "../ui/LoadingSpinner";
import styles from "./Chart5.module.css";

interface Props {
  seacNumber: string;
  filters: ReactNode;
  companyId?: SP.CompanyId;
  evaluation: Evaluation;
  period: SP.Period;
  className?: string;
}

const Chart5 = ({
  seacNumber,
  filters,
  companyId,
  evaluation,
  period,
  className,
}: Props) => {
  const { member } = useMember();
  if (!member) return null;

  const requiresCompanySelection = !companyId && member.companies?.length !== 1;
  // if (requiresCompanySelection || !member.companies[0]) return null;

  const aborter = useRef(new AbortController());
  const {
    data: chart5Data,
    isValidating,
    error,
  } = useChart5Data(
    member.id,
    seacNumber,
    period,
    evaluation === "competence",
    companyId || member.companies?.[0]?.id,
    aborter.current.signal
  );
  const isSuccess = !isValidating && !error;
  if (!chart5Data) return null;

  const getFractionDigits = (companyTypeId: SP.CompanyTypeId) => {
    switch (companyTypeId) {
      case 3:
      case 4:
      case 6:
        return 0;

      default:
        return 1;
    }
  };

  const getChartLabel = (companyTypeId: SP.CompanyTypeId) => {
    switch (companyTypeId) {
      case 1:
      case 7:
      case 8:
        return "Nächtigungen Erwachsene";

      case 2:
        return "Sitzplätze";

      case 3:
      case 4:
      case 6:
        return "Offenhaltetage";

      case 5:
        return "Vermietete Stellplätze";
    }
  };

  const renderChart = (
    { labels, chartRows }: ChartDataGroupedByRange,
    fractionDigits: number
  ) => {
    const formatter = (value: number) =>
      formatAsCurrencyWith(fractionDigits, value);

    return (
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={chartRows}>
          <CartesianGrid
            vertical={false}
            stroke="var(--ci-light-grey)"
            strokeDasharray="3 3"
          />
          <XAxis
            dataKey="range"
            axisLine={{ stroke: "var(--ci-light-grey)" }}
            tickLine={{ stroke: "var(--ci-light-grey)" }}
            stroke="var(--ci-grey)"
            strokeWidth="2px"
          />
          <YAxis
            tickFormatter={formatter}
            axisLine={{ stroke: "var(--ci-light-grey)" }}
            tickLine={{ stroke: "var(--ci-light-grey)" }}
            stroke="var(--ci-grey)"
            strokeWidth="2px"
            width={111}
          />
          <Legend
            iconSize={24}
            wrapperStyle={{ paddingTop: "1em" }}
            payload={labels.map((label, i) => ({
              key: i,
              value: label,
              color: "var(--ci-grey)",
              legendIcon: <SvgBullet fill={colorCycle(i)} />,
            }))}
          />
          {labels.map((label, i) => {
            const getValueFromChartRow = (row: ChartRowGroupedByRange) =>
              getValueBasedOnValueType(row.values[i] ?? [0, 0], "currency");

            return (
              <Bar
                key={i}
                name={label}
                dataKey={getValueFromChartRow}
                fill={colorCycle(i)}
              >
                <LabelList
                  valueAccessor={getValueFromChartRow}
                  formatter={formatter}
                  position="center"
                />
              </Bar>
            );
          })}
        </BarChart>
      </ResponsiveContainer>
    );
  };

  return (
    <Chart
      className={className}
      title={`Umsatz- operative Kosten- Analyse pro ${
        isSuccess ? getChartLabel(chart5Data.companyTypeId) : "…"
      }`}
    >
      <Chart.Filter>{filters}</Chart.Filter>
      <Chart.Content>
        {requiresCompanySelection ? (
          <div className={styles.status}>
            Bitte wählen Sie einen Betrieb aus.
          </div>
        ) : (
          <>
            {isSuccess &&
              renderChart(
                getChartDataGroupedByRange(chart5Data.accountings, period),
                getFractionDigits(chart5Data.companyTypeId)
              )}
            {isValidating && (
              <div className={styles.status}>
                <LoadingSpinner />
              </div>
            )}
            {error && (
              <div className={styles.status}>
                <ErrorText text="Fehler beim Laden der Umsatz- operative Kosten- Analyse." />
              </div>
            )}
          </>
        )}
      </Chart.Content>
    </Chart>
  );
};

export default Chart5;
