import { PropsWithChildren, useState } from "react";
import sickNote from "../../assets/sick-note.jpg";
import workAccidentCertificate from "../../assets/work-accident-certificate.jpg";
import { useMember } from "../../context/memberContext";
import { useEmergencyStatus } from "../../http/serviceportalApi";
import { configIncompleteMsg } from "../../utils";
import Button from "../ui/Button";
import ErrorText from "../ui/ErrorText";
import LoadingSpinner from "../ui/LoadingSpinner";
import Page from "../ui/Page";
import EmergencyModal from "./EmergencyModal";
import styles from "./EmergencyService.module.css";
import PayrollOfficeEmailAddress from "./PayrollOfficeEmailAddress";

const EmergencyService = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const { data: status, isValidating, error } = useEmergencyStatus();
  const isSuccess = !isValidating && !error;
  const { member } = useMember();
  if (!member) {
    return null;
  }

  return (
    <Page
      title="Individueller Notfalldienst der HGV-Personalberatung"
      backLinkText="Zurück zu Personalberatung"
    >
      {!member.giottoId ? (
        <div className={styles.status}>{configIncompleteMsg}</div>
      ) : (
        <div>
          <p className={styles.introduction}>Was tun bei:</p>

          <Article title="Anmeldungen">
            <p>
              Wir erinnern hiermit daran, dass alle Anmeldungen bis spätestens
              11.45 Uhr des letzten Werktages vor Arbeitsbeginn über die
              Funktion Mitarbeitermeldung im HGV Serviceportal an die zuständige
              HGV-Personalberatung übermittelt werden müssen.
            </p>
            <p>
              Sollte dennoch am Wochenende oder an Feiertagen eine dringende
              Mitarbeiteranmeldung nötig sein, können Sie die
              Mitarbeiteranmeldung,{" "}
              <strong>
                innerhalb 24 Uhr des Tages vor Arbeitsbeginn, mittels{" "}
                <a
                  href="https://assets-eu-01.kc-usercontent.com/a0e24cdf-7b6b-010e-94fe-bdbecae481ee/509ecddb-2420-4870-a95f-36cc85f1ac31/UniUrg.pdf"
                  target="_blank"
                >
                  UniUrg
                </a>
              </strong>{" "}
              direkt ans Arbeitsamt übermitteln.
            </p>
            <p>
              Die Versandbestätigung des Formulars UniUrg, sowie die
              entsprechende Meldungsnummer, die vom Amt nach erfolgter
              Übermittlung zugewiesen wird, sind vom Kunden unverzüglich an
              den/die jeweilige:n Ansprechpartner:in der HGV-Personalberatung
              weiterzuleiten (
              <PayrollOfficeEmailAddress member={member} />
              ), damit die ordentliche UniLav-Anmeldung, sowie die Ausarbeitung
              des Arbeitsvertrages am erstmöglichen Tag erfolgen kann.
            </p>
          </Article>

          <Article title="Streitfälle/Disziplinarmaßnahmen">
            <p>
              Falls es am Wochenende oder an einem Feiertag zu einer
              gravierenden Streitigkeit
              (Handgreiflichkeit/Beleidigung/Trunkenheit) kommen sollte,
              empfehlen wir Ihnen, den/die <strong>Mitarbeitenden</strong> für
              die Tage der Schließung der HGV-Personalberatung{" "}
              <strong>vom Dienst zu befreien</strong> (Urlaub,
              Überstundenausgleich, o.ä).
            </p>
            <p>
              Kontaktieren Sie bitte <strong>am ersten Werktag</strong> Ihre:n
              Ansprechpartner:in der HGV-Personalberatung, um den Sachverhalt zu
              besprechen und das weitere Vorgehen gemeinsam zu bestimmen.
            </p>
          </Article>

          <Article title="Unentschuldigtes Fernbleiben vom Arbeitsplatz">
            <p>
              Falls ein Mitarbeiter ohne triftigen Grund (Krankschreibung,
              Unfall usw.) bzw. Erlaubnis (Urlaub) seinem Arbeitsplatz
              fernbleibt, so befindet sich der Arbeitgeber meist in einer
              Notsituation, welche es kurzfristig zu überbrücken gilt. Die
              Aufgaben und der Tätigkeitsbereich des abwesenden Mitarbeiters
              müssen auf die anderen Mitarbeitenden aufgeteilt werden oder aber
              auch eventuelle Aushilfen müssen kurzfristig gefunden und auch
              angemeldet (am Wochenende mittels des Formulars Uniurg) werden.
              <br />
              Bei einer unentschuldigten Abwesenheit eines Mitarbeitenden
              empfehlen wir Ihnen, sich umgehend mit Ihrer/m Ansprechpartner/in
              der HGV-Personalberatung in Verbindung zu setzen, um das weitere
              Vorgehen gemeinsam abzustimmen. Bitte achten Sie bei der
              Kommunikation mit den betreffenden Mitarbeitenden immer auf eine
              sorgfältige und neutrale Wortwahl, vermeiden Sie die eigenmächtige
              Verfassung von Vorhaltungen oder sonstigem Schriftverkehr
              (beispielsweise WhatsApp oder E-Mail) und gehen Sie auf eventuelle
              Provokationen nicht ein, da sich dies bei einem Streitfall negativ
              auswirken könnte.
              <br />
              Die unentschuldigte Abwesenheit des Mitarbeitenden muss in jedem
              Fall schriftlich vorgehalten werden. Da es bei der Vorhaltung
              einige Punkte zu beachten gibt, damit diese rechtmäßig und korrekt
              ist, wird Ihr/Ihre Ansprechpartner/in diese individuell
              ausarbeiten und Ihnen zukommen lassen. Damit eine Vorhaltung auch
              regelkonform zugestellt werden kann, ist die korrekte Wohnadresse
              des Empfängers (auch für Mitarbeitende aus dem Ausland) zwingend
              notwendig. Durch die schriftliche Vorhaltung wird das
              unentschuldigte Fernbleiben belegt und dem Mitarbeitenden die
              Möglichkeit einer Rechtfertigung - ebenfalls in schriftlicher Form
              - eingeräumt.
              <br />
              Wenn die Abwesenheit nicht gerechtfertigt werden kann, wird dem
              Mitarbeitenden das Folgeschreiben zugestellt, in welchem er über
              die verhängten Disziplinarmaßnahmen informiert wird.
              <br />
              Der Zeitraum der unentschuldigten Abwesenheit wird nicht entlohnt,
              es besteht weder Anspruch auf den normalen Tageslohn noch auf
              andere Lohnelemente. Es empfiehlt sich, den Mitarbeiter auch
              darüber in schriftlicher Form zu informieren.
              <br />
              Sollte die unentschuldigte Abwesenheit des Mitarbeitenden länger
              als 5 Tage andauern, so besteht die Möglichkeit, das
              Arbeitsverhältnis zu beenden.
              <br />
              Auch in diesem Fall muss die Abwesenheit vorab durch eine
              disziplinarrechtliche Vorhaltung beanstandet werden und erst nach
              der Feststellung, dass keine Rechtfertigungen vorliegen, kann das
              2. disziplinarrechtliche Folgeschreiben mit der Entlassung
              übermittelt werden.
              <br />
              Falls ein Mitarbeitender am Wochenende nicht zur Arbeit kommt,
              kann der Kontakt mit Ihrem zuständigen Ansprechpartner ohne
              Weiteres wieder am darauffolgenden Werktag zur Besprechung der
              Sachlage aufgenommen werden.
            </p>
          </Article>

          <Article title="Arbeitsunfall des Mitarbeitenden">
            <p>
              Falls ein Mitarbeitender einen Arbeitsunfall erleidet, daraufhin
              ins Krankenhaus eingeliefert wird und den entsprechenden
              INAIL-Unfallschein vorweist, darf der Mitarbeiter für den
              angegebenen Zeitraum nicht mehr seine Arbeitsleistung erbringen.
              <br />
              Bitte leiten Sie den INAIL-Unfallschein umgehend Ihrem zuständigen
              Ansprechpartner der HGV-Personalberatung weiter. Die Meldung des
              Arbeitsunfalles wird dann von uns innerhalb der vorgesehenen Frist
              veranlasst. Selbst wenn der Unfall am Wochenende oder an
              Feiertagen vorfällt,{" "}
              <strong>
                kann die Unfallmeldung am nächsten Werktag eingereicht
              </strong>{" "}
              werden.
            </p>
            <img
              className={styles.image}
              src={workAccidentCertificate}
              alt="Unfallschein"
            />
          </Article>

          <Article title="Krankheit des Mitarbeitenden">
            <p>
              Falls ein Mitarbeitender einen Krankenschein übermittelt bzw.
              vorbeibringt darf der Mitarbeiter im angegebenen Zeitraum keine
              Arbeitsleistung erbringen.
              <br />
              Bitte übermitteln Sie die Protokollnummer des Krankenscheins oder
              den Krankenschein selbst Ihrer:m Ansprechpartner:in der
              HGV-Personalberatung.
              <br />
              Wir werden den Krankheitszeitraum dann im Stundenregister des
              Mitarbeitenden eintragen.
            </p>
            <p>
              Falls Ihnen der Mitarbeitende nur die Protokollnummer des
              Krankenscheins übermittelt und Sie somit nicht den genauen
              Zeitraum des Krankenstandes kennen, können Sie unter folgendem
              Link, durch Eingabe der Steuernummer und der Protokollnummer den
              vollständigen Krankenschein abrufen.
            </p>
            <a
              href="https://serviziweb2.inps.it/AttestatiCittadinoWeb/"
              target="_blank"
            >
              Attestati malattia - Consultazione (inps.it)
            </a>
            <img className={styles.image} src={sickNote} alt="Krankenschein" />
          </Article>

          <Article title="Individueller Notfalldienst">
            <p>
              Falls die oben angegebenen Fragen und Antworten Ihren konkreten
              Problemfall nicht lösen, und Sie dennoch eine dringende Auskunft
              benötigen, können Sie auf den unten angeführten{" "}
              <strong>Notfallbutton</strong> klicken.
            </p>
            <p>
              Dadurch werden Sie zeitnah vom/von der Bereichsleiter:in Ihrer
              zuständigen HGV-Personalberatung kontaktiert.
            </p>
            <p>Der Dienst ist in folgenden Zeiten aktiv:</p>
            <p>
              Während der Zeit des Sommerstundenplans vom 1. Juli 2024 bis 23.
              August 2024 von Montag bis Freitag von 14:00 bis 17:00 Uhr.
            </p>
            <div>
              <strong>An den folgenden Tagen von 08:00 bis 17:00 Uhr:</strong>
              <ul className={styles.holidayList}>
                <li>Ostermontag, 01. April 2024</li>
                <li>Pfingstmontag, 20. Mai 2024</li>
                <li>Allerheiligen, Freitag 1. November 2024</li>
                <li>Hl. 3 König, Montag 6. Januar 2025</li>
              </ul>
            </div>
            <p className={styles.warning}>
              <strong>
                ACHTUNG: An den Feiertagen fällt für die Aktivierung des
                individuellen Notfalldienstes eine Bearbeitungsgebühr von 130,00
                Euro an.
              </strong>
            </p>
          </Article>

          <div className={styles.emergencyButton}>
            {isSuccess && (
              <Button
                type="primary"
                buttonProps={{
                  disabled: !status?.active,
                  title: !status?.active
                    ? "Der Notfalldienst ist zurzeit nicht aktiv."
                    : undefined,
                  onClick: () => setModalOpen(true),
                }}
              >
                Notfallbutton
              </Button>
            )}
            {isValidating && <LoadingSpinner />}
            {error && (
              <ErrorText text="Fehler beim Initialisieren des Notfallbuttons." />
            )}
          </div>

          <EmergencyModal
            isOpen={isModalOpen}
            onClose={() => setModalOpen(false)}
          />
        </div>
      )}
    </Page>
  );
};

type ArticleProps = PropsWithChildren & { title: string };

const Article = ({ title, children }: ArticleProps) => (
  <article className={styles.article}>
    <h2 className={styles.heading}>{title}</h2>
    {children}
  </article>
);

export default EmergencyService;
