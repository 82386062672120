import { useEffect, useState } from "react";
import { useMember } from "../../context/memberContext";
import { SP, useOpenItems } from "../../http/serviceportalApi";
import { OpenItemStatus } from "../../types";
import {
  configIncompleteMsg,
  formatLongDate,
  getRemoteDataStatus,
  notAvailableMsg,
  removeDuplicates,
  sumOpenItems,
} from "../../utils";
import ErrorText from "../ui/ErrorText";
import LoadingSpinner from "../ui/LoadingSpinner";
import Page from "../ui/Page";
import CompaniesTable, { OpenItemsCompany } from "./CompaniesTable";
import Filters, { FilterState } from "./Filters";
import styles from "./OpenItems.module.css";

const OpenItems = () => {
  const { member, status } = useMember();

  return (
    <Page title="Offene Posten" isLoading={status === "validating"}>
      {!member?.seacNumber ? (
        <div className={styles.status}>{configIncompleteMsg}</div>
      ) : (
        <OpenItemsContent memberId={member.id} seacNumber={member.seacNumber} />
      )}
    </Page>
  );
};

interface Props {
  memberId: SP.MemberId;
  seacNumber: string;
}

const OpenItemsContent = ({ memberId, seacNumber }: Props) => {
  const { data, isValidating, error } = useOpenItems(memberId, seacNumber);
  const openItems = data ?? [];
  const status = getRemoteDataStatus({ isValidating, error });

  const docYears = removeDuplicates(
    openItems.reduce((state: number[], value) => {
      if (!value.documentDate) return state;
      const year = new Date(value.documentDate).getFullYear();
      return [...state, year];
    }, [])
  );

  const [filters, setFilters] = useState<FilterState>({
    status: "open",
    openUntil: undefined,
    term: "",
  });

  useEffect(() => {
    if (filters.openUntil || docYears.length === 0) return;
    setFilters({ ...filters, openUntil: Math.max(...docYears) });
  }, [docYears]);

  const supplierCompanies = getFilteredCompanies(
    openItems.filter(({ type }) => type === "F"),
    filters
  );
  const customerCompanies = getFilteredCompanies(
    openItems.reduce((state: SP.OpenItem[], value) => {
      if (value.type !== "C") return state;
      return [...state, { ...value, balance: -value.balance }];
    }, []),
    filters
  );

  return (
    <div className={styles.openItems}>
      {status === "success" && (
        <>
          <div>Letzte Aktualisierung: {getLatestUpdate(openItems)}</div>
          <Filters state={filters} years={docYears} onChange={setFilters} />
          <CompaniesTable title="Lieferant" companies={supplierCompanies} />
          <CompaniesTable title="Kunde" companies={customerCompanies} />
        </>
      )}
      {status === "validating" && (
        <div className={styles.loadingSpinner}>
          <LoadingSpinner />
        </div>
      )}
      {status === "failure" && (
        <div className={styles.status}>
          <ErrorText text="Fehler beim Laden der offenen Posten." />
        </div>
      )}
    </div>
  );
};

const compareDates = (a: Date, b: Date) => a.getTime() - b.getTime();

const getLatestUpdate = (openItems: SP.OpenItem[]) => {
  const latestDate = openItems.reduce(
    (state: Date | undefined, { operationDate }) => {
      if (!operationDate) return state;
      const od = new Date(operationDate);
      return !state || compareDates(state, od) < 0 ? od : state;
    },
    undefined
  );
  return latestDate ? formatLongDate(latestDate.toString()) : notAvailableMsg;
};

const getStatus = (company: OpenItemsCompany): OpenItemStatus =>
  sumOpenItems(company.openItems) === 0 ? "settled" : "open";

const matchesSearchTerm = (text: string, term: string) =>
  text.toLowerCase().includes(term.toLowerCase());

const getFilteredCompanies = (items: SP.OpenItem[], filters: FilterState) => {
  const filteredByOpenUntil = items.filter(
    ({ documentDate }) =>
      !filters.openUntil ||
      !documentDate ||
      new Date(documentDate).getFullYear() <= filters.openUntil
  );

  const companies = Object.values(
    filteredByOpenUntil.reduce(
      (state: { [vatCode: string]: OpenItemsCompany }, value) => {
        state[value.vatCode] = {
          vatCode: value.vatCode,
          name: value.name,
          openItems: [...(state[value.vatCode]?.openItems ?? []), value],
        };
        return state;
      },
      {}
    )
  );

  return companies.filter(
    (c) =>
      (filters.status === "all" || filters.status === getStatus(c)) &&
      (matchesSearchTerm(c.vatCode, filters.term) ||
        matchesSearchTerm(c.name ?? "", filters.term))
  );
};

export default OpenItems;
