import classNames from "classnames";
import { useRef } from "react";
import useListFilters from "../../hooks/useListFilters";
import { useAdminUserMembers } from "../../http/serviceportalApi";
import i18n from "../../i18n";
import { getRemoteDataStatus } from "../../utils";
import PermissionTable from "../PermissionTable";
import ExpandableRow from "../ui/ExpandableRow";
import Table from "../ui/Table";
import styles from "./AdminUserAssignments.module.css";

interface Props {
  userId: string;
}

const AdminUserAssignments = ({ userId }: Props) => {
  const filters = useListFilters();
  const aborter = useRef(new AbortController());
  const { data, isValidating, error } = useAdminUserMembers(
    userId,
    filters.state,
    aborter.current.signal
  );
  const members = data?.items ?? [];
  const status = getRemoteDataStatus({ isValidating, error });

  return (
    <Table
      remoteDataStatus={status}
      total={members.length}
      filters={filters}
      head={
        <ExpandableRow
          CellType="th"
          cells={[
            <th className={styles.alignLeft}>Mitglied</th>,
            <th className={styles.alignLeft}>Betriebsstätten</th>,
          ]}
        />
      }
      body={members.map((m) => {
        const assignedCompanies =
          m.companies?.filter(
            (c) => c.permissions && c.permissions.length > 0
          ) ?? [];
        return (
          <ExpandableRow
            key={m.id}
            CellType="td"
            cells={[
              <td>{m.name}</td>,
              <td>
                {m.permissions
                  ? i18n.allCompanies
                  : i18n.companies(assignedCompanies.length)}
              </td>,
            ]}
            expandedContent={
              <Table
                isSelectable={false}
                total={(m.permissions ? 1 : 0) + assignedCompanies.length}
                head={
                  <tr>
                    <th
                      className={classNames(styles.company, styles.alignLeft)}
                    >
                      Betriebsstätte
                    </th>
                    <th className={styles.alignLeft}>Zugriff</th>
                  </tr>
                }
                body={
                  <>
                    {m.permissions && (
                      <tr>
                        <td>{i18n.allCompanies}</td>
                        <td>
                          <PermissionTable permissions={m.permissions} />
                        </td>
                      </tr>
                    )}
                    {assignedCompanies.map((c) => (
                      <tr key={c.id}>
                        <td>{c.name}</td>
                        <td>
                          {c.permissions && (
                            <PermissionTable permissions={c.permissions} />
                          )}
                        </td>
                      </tr>
                    ))}
                  </>
                }
              />
            }
          />
        );
      })}
    />
  );
};

export default AdminUserAssignments;
