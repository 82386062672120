import i18n from "../../i18n";
import DepartmentApps from "../DepartmentApps";
import Page from "../ui/Page";
import PageSection from "../ui/PageSection";

const Accounting = () => (
  <Page
    title={i18n.departments.accounting.title}
    description="Die HGV-Steuerberatung ist Ihr Experte für das Steuerrecht im Gastgewerbe. Kompetent, zuverlässig und professionell erledigen wir für Sie alle anfälligen Aufgaben rund um die Steuern."
  >
    <PageSection title="Anwendungen">
      <DepartmentApps department="accounting" />
    </PageSection>
  </Page>
);

export default Accounting;
