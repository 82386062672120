import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  SP,
  useMembers,
  useMember as useSPMember,
} from "../http/serviceportalApi";
import { RemoteDataStatus } from "../types";
import { getRemoteDataStatus, mergeStatuses } from "../utils";
import { useNavigate } from "react-router-dom";

type MemberContextType = {
  member?: SP.Member;
  hasOnlyOneMember?: boolean;
  status: RemoteDataStatus;
  error?: unknown;
  setMember: (member: SP.Member) => void;
};

const MemberContext = createContext<MemberContextType>(undefined!);

const LOCAL_STORAGE_KEY_MEMBER = "selected-member";

export const MemberProvider = ({ children }: PropsWithChildren) => {
  const savedMemberId = localStorage.getItem(LOCAL_STORAGE_KEY_MEMBER);
  const [memberId, setMemberId] = useState(
    savedMemberId ? parseInt(savedMemberId) : undefined
  );
  const navigate = useNavigate();

  const setMember = (member?: SP.Member) => {
    aborter.current.abort();
    aborter.current = new AbortController();

    setMemberId(member?.id);
    if (member) {
      localStorage.setItem(LOCAL_STORAGE_KEY_MEMBER, member.id.toString());
    } else {
      localStorage.removeItem(LOCAL_STORAGE_KEY_MEMBER);
    }

    navigate("/");
  };

  const {
    data,
    isValidating: isMemberListValidating,
    error: memberListError,
  } = useMembers({ page: 1, limit: 2 });
  const firstMember = data?.items[0];
  const memberListStatus = getRemoteDataStatus({
    isValidating: isMemberListValidating,
    error: memberListError,
  });

  useEffect(() => {
    if (savedMemberId || !firstMember) return;
    setMember(firstMember);
  }, [firstMember]);

  const aborter = useRef(new AbortController());
  const {
    data: member,
    isValidating: isMemberValidating,
    error: memberError,
  } = useSPMember(memberId, aborter.current.signal);
  const memberStatus = getRemoteDataStatus({
    isValidating: isMemberValidating,
    error: memberError,
  });

  const value = {
    member,
    hasOnlyOneMember: data?.items.length === 1,
    status: mergeStatuses([memberListStatus, memberStatus]),
    error: memberListError || memberError,
    setMember,
  };

  useEffect(() => {
    if (memberError?.status === 403) {
      value.setMember(undefined);
    }
  }, [memberError]);

  return (
    <MemberContext.Provider value={value}>{children}</MemberContext.Provider>
  );
};

export const useMember = () => {
  return useContext(MemberContext);
};
