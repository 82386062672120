import { useEffect, useState } from "react";
import { Link, Navigate, Route, Routes } from "react-router-dom";
import { useAuth } from "../context/authContext";
import { useMediaQuery } from "../hooks/useMediaQuery";
import { useMe } from "../http/serviceportalApi";
import AccountButton from "./AccountButton";
import styles from "./PrivateHouseholdApp.module.css";
import Sidebar from "./Sidebar";
import LoadingSpinner from "./ui/LoadingSpinner";
import Nav from "./ui/Nav";
import SvgDescription from "./icons/Description";
import Hamburger from "./ui/Hamburger";
import AppMenu from "./ui/AppMenu";
import PrivateHouseholdDocuments from "./PrivateHouseholdDocuments";
import { checkHasPrivateHouseholdPermission } from "../utils";

const PrivateHouseholdApp = () => {
  const auth = useAuth();
  const matchesMedia = useMediaQuery("(min-width: 1400px)");
  const [isSidebarOpen, setSidebarOpen] = useState(matchesMedia);

  const { data, isValidating } = useMe();
  const userPermissions = data?.permissions ?? [];

  const isPrivateHousehold =
    checkHasPrivateHouseholdPermission(userPermissions);

  const closeMenu = () => {
    !matchesMedia && setSidebarOpen(false);
  };

  useEffect(closeMenu, [matchesMedia]);

  if (!auth.session || !isPrivateHousehold) {
    return null;
  }

  return isValidating ? (
    <div className={styles.loadingSpinner}>
      <LoadingSpinner />
    </div>
  ) : (
    <>
      <header className={styles.header}>
        <div className={styles.aside}>
          <Hamburger
            isOpen={isSidebarOpen}
            className={styles.hamburger}
            onClick={() => setSidebarOpen(!isSidebarOpen)}
          />
          <Link to="/privathaushalt" className={styles.logo}>
            Serviceportal Privathaushalte
          </Link>
        </div>
        <AppMenu />
        <AccountButton />
      </header>
      <div>
        <Sidebar isOpen={isSidebarOpen} close={closeMenu}>
          <Nav>
            <Nav.Item
              text="Dokumente"
              to="dokumente"
              icon={SvgDescription}
              onClick={closeMenu}
            />
          </Nav>
        </Sidebar>
        <Routes>
          <Route path="dokumente/*" element={<PrivateHouseholdDocuments />} />
          <Route path="*" element={<Navigate to="dokumente" replace />} />
        </Routes>
      </div>
    </>
  );
};

export default PrivateHouseholdApp;
