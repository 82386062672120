import MetricsPieChart from "./MetricsPieChart";
import styles from "./ComparisonPieChart.module.css";
import { SP } from "../../http/serviceportalApi";

interface Props {
  data: SP.ComparisonPieChartData;
  formatter?: (value: number) => string;
}

const ComparisonPieChart = ({
  data: [ownData, filterData],
  formatter,
}: Props) => {
  return (
    <div className={styles.comparisonChart}>
      <div className={styles.chart}>
        <MetricsPieChart title="Betrieb" data={ownData} formatter={formatter} />
      </div>
      <div className={styles.chart}>
        <MetricsPieChart
          title="Benchmark"
          data={filterData}
          formatter={formatter}
        />
      </div>
    </div>
  );
};

export default ComparisonPieChart;
