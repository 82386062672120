import classNames from "classnames";
import { useEffect, useState } from "react";
import styles from "./LoadingSpinner.module.css";

export type Theme = "light" | "dark";

interface Props {
  delayed?: number;
  size?: "small" | "default";
  theme?: Theme;
  className?: string;
}

const LoadingSpinner = ({
  delayed = 125,
  size = "default",
  theme = "dark",
  className,
}: Props) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setShow(true), delayed, [delayed]);
    return () => clearTimeout(timeout);
  });

  return (
    <>
      {show && (
        <div
          className={classNames(styles.loader, className, {
            [styles.small]: size === "small",
            [styles.light]: theme === "light",
          })}
          aria-label="Lade …"
          role="progressbar"
        >
          <div className={styles.track} />
          <div className={styles.fills}>
            <div className={styles.fillMask1}>
              <div className={styles.fillSubMask1}>
                <div className={styles.fill} />
              </div>
            </div>
            <div className={styles.fillMask2}>
              <div className={styles.fillSubMask2}>
                <div className={styles.fill} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LoadingSpinner;
