import classNames from "classnames";
import { PropsWithChildren, ReactNode, useEffect } from "react";
import { NavLink } from "react-router-dom";
import i18n from "../../i18n";
import SvgBackArrow from "../icons/BackArrow";
import Icon from "./Icon";
import LoadingSpinner from "./LoadingSpinner";
import styles from "./Page.module.css";

type Props = PropsWithChildren<{
  title: string;
  shortInfo?: ReactNode;
  description?: ReactNode;
  actions?: ReactNode;
  backLinkText?: string;
  isLoading?: boolean;
  narrow?: boolean;
}>;

const Page = ({
  title,
  shortInfo,
  description,
  actions,
  backLinkText,
  isLoading = false,
  narrow = false,
  children,
}: Props) => {
  useEffect(() => {
    document.title = `${title} | ${i18n.title}`;
  }, [title]);

  return (
    <div className={classNames(styles.page, { [styles.narrow]: narrow })}>
      <div>
        {backLinkText && (
          <NavLink className={styles.backLink} to="..">
            <Icon glyph={SvgBackArrow} className={styles.icon} />
            {backLinkText}
          </NavLink>
        )}
        <header className={styles.header}>
          <div className={styles.heading}>
            {title.length > 0 && <h1 className={styles.title}>{title}</h1>}
            {actions && <div className={styles.actions}>{actions}</div>}
          </div>
          {shortInfo && <div className={styles.shortInfo}>{shortInfo}</div>}
          {description && (
            <div className={styles.description}>{description}</div>
          )}
        </header>
      </div>

      {!isLoading ? (
        children
      ) : (
        <div className={styles.loadingSpinner}>
          <LoadingSpinner />
        </div>
      )}
    </div>
  );
};

export default Page;
