import { ReactNode, useRef } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { SP, useChart1Data } from "../../http/serviceportalApi";
import { getChart1Data } from "../../selectors/accountingCharts";
import { Evaluation } from "../../types";
import {
  colorCycle,
  formatAsCurrencyWith,
  formatAsPercent,
  getYearFromPeriod,
  range,
} from "../../utils";
import SvgBullet from "../icons/Bullet";
import Chart from "../ui/Chart";
import ErrorText from "../ui/ErrorText";
import LoadingSpinner from "../ui/LoadingSpinner";
import styles from "./Chart1.module.css";

interface Props {
  memberId: number;
  seacNumber: string;
  filters: ReactNode;
  companyId?: SP.CompanyId;
  evaluation: Evaluation;
  period: SP.Period;
  showVerwalterentschaedigung: boolean;
  valueType: SP.ValueType;
  className?: string;
}

const Chart1 = ({
  memberId,
  seacNumber,
  filters,
  companyId,
  evaluation,
  period,
  showVerwalterentschaedigung,
  valueType,
  className,
}: Props) => {
  const aborter = useRef(new AbortController());
  const { data, isValidating, error } = useChart1Data(
    memberId,
    seacNumber,
    period,
    showVerwalterentschaedigung,
    evaluation === "competence",
    companyId,
    aborter.current.signal
  );
  const isSuccess = !isValidating && !error;
  const chart1Data = data ?? [];

  const yearsRange = range(0, 2);

  return (
    <Chart
      className={className}
      title="Darstellung Umsatz - Wareneinsatz - Personalkosten"
    >
      <Chart.Filter>{filters}</Chart.Filter>
      <Chart.Content>
        {isSuccess && (
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={getChart1Data(chart1Data, valueType)}>
              <CartesianGrid
                vertical={false}
                stroke="var(--ci-light-grey)"
                strokeDasharray="3 3"
              />
              <XAxis
                dataKey="label"
                axisLine={{ stroke: "var(--ci-light-grey)" }}
                tickLine={{ stroke: "var(--ci-light-grey)" }}
                stroke="var(--ci-grey)"
                strokeWidth="2px"
              />
              <YAxis
                tickFormatter={(value) =>
                  valueType === "percentage"
                    ? formatAsPercent(2, value)
                    : formatAsCurrencyWith(0, value)
                }
                axisLine={{ stroke: "var(--ci-light-grey)" }}
                tickLine={{ stroke: "var(--ci-light-grey)" }}
                stroke="var(--ci-grey)"
                strokeWidth="2px"
                width={111}
              />
              <Legend
                iconSize={24}
                wrapperStyle={{ paddingTop: "1em" }}
                payload={yearsRange.map((x) => ({
                  key: x,
                  value: getYearFromPeriod(-x, period),
                  color: "var(--ci-grey)",
                  legendIcon: <SvgBullet fill={colorCycle(x)} />,
                }))}
              />
              {yearsRange.map((x) => (
                <Bar
                  key={x}
                  name={"" + (period.until.year - x)}
                  dataKey={`range${x + 1}Value`}
                  fill={colorCycle(x)}
                >
                  <LabelList
                    dataKey={`range${x + 1}Value`}
                    formatter={(value: number) =>
                      valueType === "percentage"
                        ? formatAsPercent(2, value)
                        : formatAsCurrencyWith(0, value)
                    }
                    position="center"
                  />
                </Bar>
              ))}
            </BarChart>
          </ResponsiveContainer>
        )}
        {isValidating && (
          <div className={styles.status}>
            <LoadingSpinner />
          </div>
        )}
        {error && (
          <div className={styles.status}>
            <ErrorText text="Fehler beim Laden der Darstellung Umsatz - Wareneinsatz - Personalkosten." />
          </div>
        )}
      </Chart.Content>
    </Chart>
  );
};

export default Chart1;
