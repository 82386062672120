import { ReactNode, useRef } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { SP, useChart3Data } from "../../http/serviceportalApi";
import { getMonthlyChartData } from "../../selectors/accountingCharts";
import { Evaluation } from "../../types";
import {
  colorCycle,
  formatAsCurrencyWith,
  getLongMonthName,
  range,
} from "../../utils";
import SvgBullet from "../icons/Bullet";
import Chart from "../ui/Chart";
import ChartTooltip from "../ui/ChartTooltip";
import ErrorText from "../ui/ErrorText";
import LoadingSpinner from "../ui/LoadingSpinner";
import styles from "./Chart3.module.css";

interface Props {
  memberId: number;
  seacNumber: string;
  filters: ReactNode;
  companyId?: SP.CompanyId;
  evaluation: Evaluation;
  period: SP.Period;
  className?: string;
}

const Chart3 = ({
  memberId,
  seacNumber,
  filters,
  companyId,
  evaluation,
  period,
  className,
}: Props) => {
  const aborter = useRef(new AbortController());
  const { data, isValidating, error } = useChart3Data(
    memberId,
    seacNumber,
    period,
    evaluation === "competence",
    companyId,
    aborter.current.signal
  );
  const isSuccess = !isValidating && !error;
  const chart3Data = data ?? [];

  const yearsRange = range(0, 2);

  return (
    <Chart className={className} title="Monats-Umsatz-Analyse">
      <Chart.Filter>{filters}</Chart.Filter>
      <Chart.Content>
        {isSuccess &&
          (chart3Data.length === 0 ? (
            <div className={styles.status}>
              Es wurden keine Daten für den ausgewählten Zeitraum gefunden.
            </div>
          ) : (
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={getMonthlyChartData(
                  chart3Data,
                  period.until.year,
                  (rows, year) => rows.find((x) => x.year === year)?.value ?? 0
                )}
              >
                <CartesianGrid
                  vertical={false}
                  stroke="var(--ci-light-grey)"
                  strokeDasharray="3 3"
                />
                <XAxis
                  dataKey="month"
                  tickFormatter={(value) => getLongMonthName(value)}
                  axisLine={{ stroke: "var(--ci-light-grey)" }}
                  tickLine={{ stroke: "var(--ci-light-grey)" }}
                  stroke="var(--ci-grey)"
                  strokeWidth="2px"
                />
                <YAxis
                  tickFormatter={(value) => formatAsCurrencyWith(0, value)}
                  axisLine={{ stroke: "var(--ci-light-grey)" }}
                  tickLine={{ stroke: "var(--ci-light-grey)" }}
                  stroke="var(--ci-grey)"
                  strokeWidth="2px"
                  width={111}
                />
                <Tooltip
                  content={
                    <ChartTooltip
                      labelFormatter={(label) => getLongMonthName(label)}
                      formatter={(value: number) =>
                        formatAsCurrencyWith(2, value)
                      }
                    />
                  }
                  cursor={{ fill: "var(--white-hover)" }}
                />
                <Legend
                  iconSize={24}
                  wrapperStyle={{ paddingTop: "1em" }}
                  payload={yearsRange.map((x) => ({
                    key: x,
                    value: period.until.year - x,
                    color: "var(--ci-grey)",
                    legendIcon: <SvgBullet fill={colorCycle(x)} />,
                  }))}
                />
                {yearsRange.map((x) => (
                  <Bar
                    key={x}
                    name={"" + (period.until.year - x)}
                    dataKey={`year${x + 1}`}
                    fill={colorCycle(x)}
                  ></Bar>
                ))}
              </BarChart>
            </ResponsiveContainer>
          ))}
        {isValidating && (
          <div className={styles.status}>
            <LoadingSpinner />
          </div>
        )}
        {error && (
          <div className={styles.status}>
            <ErrorText text="Fehler beim Laden der Monats-Umsatz-Analyse." />
          </div>
        )}
      </Chart.Content>
    </Chart>
  );
};

export default Chart3;
