import { Fragment } from "react";
import { useMember } from "../../context/memberContext";
import { notAvailableMsg } from "../../utils";
import Label from "../ui/Label";
import Page from "../ui/Page";
import Panel from "../ui/Panel";
import styles from "./MasterData.module.css";

const MasterData = () => {
  const { member } = useMember();

  return (
    <Page
      title="Stammdaten"
      description="Ihre Mitgliedsdaten und allgemeine Informationen über Ihre Betriebsstätten."
    >
      <h3 className={styles.title}>Mitgliedsdaten</h3>
      <Panel>
        <div className={styles.memberData}>
          <div>
            <Label>Name</Label>
            <strong>{member?.name}</strong>
          </div>
          <div>
            <Label>Mitgliedsnummer</Label>
            {member?.id}
          </div>
          <div className={styles.street}>
            <Label>Strasse</Label>
            {member?.street}
          </div>
          <div>
            <Label>Ort</Label>
            {member?.city}
          </div>
          <div>
            <Label>PLZ</Label>
            {member?.zip}
          </div>
          <div>
            <Label>Steuernummer</Label>
            {member?.taxCode}
          </div>
          <div>
            <Label>MwSt. - Nummer</Label>
            {member?.vatNumber}
          </div>
        </div>
      </Panel>
      {member?.companies && (
        <>
          <h3 className={styles.title}>Betriebsstätten</h3>
          <Panel>
            {member.companies.map((company, index) => (
              <Fragment key={index}>
                {index > 0 && <hr className={styles.separator} />}
                <div className={styles.memberData}>
                  <div>
                    <Label>Name</Label>
                    <strong>{company?.name}</strong>
                  </div>
                  <div>
                    <Label>Betriebsstättennummer</Label>
                    {company.id}
                  </div>
                  <div className={styles.street}>
                    <Label>Strasse</Label>
                    {company.street ?? notAvailableMsg}
                  </div>
                  <div>
                    <Label>Ort</Label>
                    {company.city ?? notAvailableMsg}
                  </div>
                  <div>
                    <Label>PLZ</Label>
                    {company.zip ?? notAvailableMsg}
                  </div>
                </div>
              </Fragment>
            ))}
          </Panel>
        </>
      )}
    </Page>
  );
};

export default MasterData;
