import classNames from "classnames";
import { ReactNode } from "react";
import i18n from "../../i18n";
import { Permission } from "../../types";
import SvgCheckbox, { SvgCheckboxOutlineBlank } from "../icons/Checkbox";
import Icon from "../ui/Icon";
import styles from "./PermissionSelect.module.css";

interface Category {
  label: string;
  permissions: Permission[];
}

interface Props {
  categories: Category[];
  renderItem: (permission: Permission) => ReactNode;
}

const PermissionSelect = ({ categories, renderItem }: Props) => (
  <div className={styles.permissionSelect}>
    <table className={styles.table}>
      <tbody>
        {categories.map(({ label, permissions }) => (
          <tr key={label}>
            <td className={styles.categoryLabel}>{label}</td>
            <td>
              <ul>{permissions.map(renderItem)}</ul>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

type ItemStatus =
  | "not_selected"
  | "selected"
  | "implicitly_selected"
  | "disabled";

interface ItemProps {
  permission: Permission;
  status?: ItemStatus;
  onClick: () => void;
}

const Item = ({ permission, status = "not_selected", onClick }: ItemProps) => {
  const isSelected = status === "selected";
  const isImplicitlySelected = status === "implicitly_selected";
  const isDisabled = status === "disabled";
  const isImplicitlySelectedOrDisabled = isImplicitlySelected || isDisabled;

  return (
    <li
      className={classNames(styles.item, {
        [styles.disabled]: isImplicitlySelectedOrDisabled,
      })}
      aria-disabled={isImplicitlySelectedOrDisabled}
      onClick={() => !isImplicitlySelectedOrDisabled && onClick()}
    >
      <Icon
        glyph={
          isSelected || isImplicitlySelected
            ? SvgCheckbox
            : SvgCheckboxOutlineBlank
        }
      />
      <label>{i18n.getPermission(permission)}</label>
    </li>
  );
};

PermissionSelect.Item = Item;

export default PermissionSelect;
