import { SP } from "../../http/serviceportalApi";
import { getPayrollOffice } from "../../utils";

interface Props {
  member: SP.Member;
}

const PayrollOfficeEmailAddress = ({ member: { giottoId } }: Props) => {
  const office = getPayrollOffice(giottoId);
  const officeEmailAddress = `personalberatung.${office ?? "BÜRO"}@hgv.it`;

  return office ? (
    <a href={`mailto:${officeEmailAddress}`}>{officeEmailAddress}</a>
  ) : (
    <>{officeEmailAddress}</>
  );
};

export default PayrollOfficeEmailAddress;
