import i18n from "../../i18n";
import { Permission } from "../../types";
import PermissionSelect from "./PermissionSelect";

interface Props {
  selectedPermissions: string[];
  onChange: (permissions: string[]) => void;
}

const DocumentPermissions = ({ selectedPermissions, onChange }: Props) => {
  const selectedTypes = selectedPermissions.flatMap((p) => getDocumentTypes(p));

  return (
    <PermissionSelect
      categories={[
        {
          label: i18n.departments.accounting.title,
          permissions: accountingDocumentPermissions,
        },
        {
          label: i18n.departments.payroll.title,
          permissions: payrollDocumentPermissions,
        },
      ]}
      renderItem={(permission) => {
        const isSelected = selectedPermissions.includes(permission);
        const isImplicitlySelected =
          !isSelected &&
          checkIsSubset(getDocumentTypes(permission), selectedTypes) &&
          permission !== Permission.DocumentsPrivateHaushalteRead;
        return (
          <PermissionSelect.Item
            key={permission}
            permission={permission}
            status={
              isSelected
                ? "selected"
                : isImplicitlySelected
                ? "implicitly_selected"
                : "not_selected"
            }
            onClick={() =>
              onChange([
                ...selectedPermissions.filter(
                  (p) =>
                    !checkIsSubset(
                      getDocumentTypes(p),
                      getDocumentTypes(permission)
                    )
                ),
                ...(!isSelected ? [permission] : []),
              ])
            }
          />
        );
      }}
    />
  );
};

export const accountingDocumentPermissions: Permission[] = [
  Permission.DocumentsSteuerRundschreibenRead,
  Permission.DocumentsJahresauswertungRead,
];

export const payrollDocumentPermissions: Permission[] = [
  Permission.DocumentsBetriebOnlineServiceRead,
  Permission.DocumentsBetriebStandardRead,
  Permission.DocumentsPrivateHaushalteRead,
  Permission.DocumentsAufstellungNettoloehneRead,
  Permission.DocumentsBuchungsbelegeRead,
  Permission.DocumentsCUDRead,
  Permission.DocumentsEinbehalt15Read,
  Permission.DocumentsErklSteuerfreibetraegeRead,
  Permission.DocumentsLaborfondMonatlichRead,
  Permission.DocumentsLaborfondTrimestralRead,
  Permission.DocumentsLohnstreifenRead,
  Permission.DocumentsMatrikelbuchRead,
  Permission.DocumentsF24Read,
  Permission.DocumentsNummInailRead,
  Permission.DocumentsSchulgeldLehrlingeRead,
  Permission.DocumentsUeberweisungslisteRead,
  Permission.DocumentsZahlungsbestaetigungF24Read,
  Permission.DocumentsEinheitslohnbuchRead,
  Permission.Documents770SemplificatoRead,
  Permission.Documents770OrdinarioRead,
  Permission.DocumentsArbeitsvertraegeRead,
  Permission.DocumentsLohnRundschreibenRead,
  Permission.DocumentsProvisorischerLohnstreifenRead,
];

type DocumentType =
  | "DJAUS"
  | "DSRUN"
  | "DANET"
  | "DBUCH"
  | "DUF24"
  | "DULOH"
  | "DAHPK"
  | "DAMLK"
  | "DAPLK"
  | "DALEN"
  | "DRM22"
  | "DRM13"
  | "DUEIA"
  | "DCUD"
  | "DEINB"
  | "DERST"
  | "DLAMO"
  | "DLATR"
  | "DLOHN"
  | "DMATR"
  | "DMF24"
  | "DNUIN"
  | "DSLEH"
  | "DUEBE"
  | "DR730"
  | "DPRES"
  | "D770S"
  | "D770O"
  | "DAABF"
  | "DAUFS"
  | "DAZGE"
  | "DBILN"
  | "DIJFG"
  | "DUEFS"
  | "DLOPR"
  | "DMQ24"
  | "DVERT"
  | "DRUND"
  | "DBO22";

const getDocumentTypes = (permission: string): DocumentType[] => {
  switch (permission as Permission) {
    case "documents.steuer_rundschreiben:read":
      return ["DSRUN"];
    case "documents.jahresauswertung:read":
      return ["DJAUS"];
    case "documents.betrieb_online_service:read":
      return [
        "DANET",
        "DBUCH",
        "DUF24",
        "DULOH",
        "DAHPK",
        "DAMLK",
        "DAPLK",
        "DALEN",
        "DRM22",
        "DRM13",
        "DUEIA",
        "DCUD",
        "DEINB",
        "DERST",
        "DLAMO",
        "DLATR",
        "DLOHN",
        "DMATR",
        "DMF24",
        "DNUIN",
        "DSLEH",
        "DUEBE",
        "DR730",
        "DPRES",
        "D770S",
        "D770O",
        "DAABF",
        "DAUFS",
        "DAZGE",
        "DBILN",
        "DIJFG",
        "DUEFS",
      ];
    case "documents.betrieb_standard:read":
      return [
        "D770O",
        "DERST",
        "DCUD",
        "DMATR",
        "DLOPR",
        "DMQ24",
        "D770S",
        "DVERT",
        "DRUND",
        "DBO22",
      ];
    case "documents.private_haushalte:read":
      return ["DLOHN", "DMATR", "DVERT", "DLOPR"];
    case "documents.aufstellung_nettoloehne:read":
      return ["DANET"];
    case "documents.buchungsbelege:read":
      return ["DBUCH"];
    case "documents.cud:read":
      return ["DCUD"];
    case "documents.einbehalt_1_5:read":
      return ["DEINB"];
    case "documents.erkl_steuerfreibetraege:read":
      return ["DERST"];
    case "documents.laborfond_monatlich:read":
      return ["DLAMO"];
    case "documents.laborfond_trimestral:read":
      return ["DLATR"];
    case "documents.lohnstreifen:read":
      return ["DLOHN"];
    case "documents.matrikelbuch:read":
      return ["DMATR"];
    case "documents.f24:read":
      return ["DMF24"];
    case "documents.numm_inail:read":
      return ["DNUIN"];
    case "documents.schulgeld_lehrlinge:read":
      return ["DSLEH"];
    case "documents.ueberweisungsliste:read":
      return ["DUEBE"];
    case "documents.zahlungsbestaetigung_f24:read":
      return ["DMQ24"];
    case "documents.einheitslohnbuch:read":
      return ["DPRES"];
    case "documents.770_semplificato:read":
      return ["D770S"];
    case "documents.770_ordinario:read":
      return ["D770O"];
    case "documents.arbeitsvertraege:read":
      return ["DVERT"];
    case "documents.lohn_rundschreiben:read":
      return ["DRUND"];
    case "documents.provisorischer_lohnstreifen:read":
      return ["DLOPR"];
    default:
      return [];
  }
};

// Checks if a is a subset of b
const checkIsSubset = (a: DocumentType[], b: DocumentType[]) =>
  a.every((elem) => b.includes(elem));

export default DocumentPermissions;
