import { Fragment, ReactNode } from "react";
import { SP } from "../../http/serviceportalApi";
import { RemoteDataStatus } from "../../types";
import { formatAsCurrencyWith, formatAsPercent, range } from "../../utils";
import Table from "../ui/Table";
import TableCell from "../ui/TableCell";

interface Props {
  period: SP.Period;
  rows: SP.AnnualComparisonOperationRow<SP.ThreeYearRowOld>[];
  remoteDataStatus: RemoteDataStatus;
  showDifferences?: boolean;
  title?: string;
  caption?: ReactNode;
}

const hasPreviousYear = (x: number, xs: number[]) => x < xs.length - 1;

const calculateDifference = (
  curr: number | undefined,
  prev: number | undefined
) => (curr && prev ? (curr - prev) / prev : 0);

const isSummand = ({ type }: SP.AnnualComparisonOperationRow<any>) =>
  type === "summand";

const isSum = ({ type }: SP.AnnualComparisonOperationRow<any>) =>
  type === "sum";

const AnnualComparisonTable = ({
  period: {
    from,
    until,
    until: { year: untilYear },
  },
  rows,
  remoteDataStatus,
  showDifferences = true,
  caption,
}: Props) => {
  const yearsRange = range(0, until.year - from.year);
  const renderRow = (
    CellType: "th" | "td",
    rowIndex: number,
    {
      value: { label, value },
    }: SP.AnnualComparisonOperationRow<SP.ThreeYearRowOld>
  ) => (
    <tr key={`annual-comparison-table-row-${rowIndex}`}>
      <CellType align="left">{label}</CellType>
      {yearsRange.map((x, columnIndex) => (
        <Fragment key={`annual-comparison-table-row-cell-${columnIndex}`}>
          <TableCell
            CellType={CellType}
            value={value[`year${x + 1}`] ?? 0}
            formatter={(value) => formatAsCurrencyWith(2, value)}
          />
          {showDifferences && hasPreviousYear(x, yearsRange) && (
            <TableCell
              CellType={CellType}
              value={calculateDifference(
                value[`year${x + 1}`],
                value[`year${x + 2}`]
              )}
              formatter={(value) => formatAsPercent(2, value)}
            />
          )}
        </Fragment>
      ))}
    </tr>
  );

  return (
    <Table
      caption={caption}
      head={
        <tr>
          <th align="left">Bezeichnung</th>
          {yearsRange.map((x, i) => (
            <Fragment key={`annual-comparison-table-header-cell-${i}`}>
              <th style={{ textAlign: "right" }}>{untilYear - x}</th>
              {showDifferences && hasPreviousYear(x, yearsRange) && (
                <th style={{ textAlign: "right" }}>Abw. %</th>
              )}
            </Fragment>
          ))}
        </tr>
      }
      body={rows.filter(isSummand).map((x, i) => renderRow("td", i, x))}
      footer={rows.filter(isSum).map((x, i) => renderRow("th", i, x))}
      total={rows.length}
      remoteDataStatus={remoteDataStatus}
    />
  );
};

export default AnnualComparisonTable;
