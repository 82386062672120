import productTourSVG from "../assets/product-tour.svg";
import { useApps } from "../context/appsContext";
import { Department } from "../types";
import styles from "./DepartmentApps.module.css";
import LoadingSpinner from "./ui/LoadingSpinner";
import Services from "./ui/Services";

interface Props {
  department: Department;
}

const DepartmentApps = ({ department }: Props) => {
  const { apps, isValidating } = useApps();
  const departmentApps = apps.filter(
    (app) => app.department === department && app.isVisible
  );
  const email = getDepartmentEmail(department);

  return isValidating ? (
    <div className={styles.status}>
      <LoadingSpinner />
    </div>
  ) : (
    <>
      <Services items={departmentApps} />
      {departmentApps.length === 0 && (
        <div className={styles.noApps}>
          <img src={productTourSVG} className={styles.illustration} />
          <span className={styles.title}>
            Es sind keine Anwendungen aktiviert.
          </span>
          <span className={styles.text}>
            Falls Sie Interesse an einer unverbindlichen Beratung haben oder
            sich über unsere Produkte und Dienstleistungen näher informieren
            möchten, wenden Sie sich bitte per E-Mail an{" "}
            <a href={`mailto:'${email}'`}>{email}</a>
          </span>
        </div>
      )}
    </>
  );
};

const getDepartmentEmail = (department: Department) => {
  switch (department) {
    case "accounting":
      return "steuerberatung.bozen@hgv.it";
    case "payroll":
      return "personalberatung.bozen@hgv.it";
    case "webMarketing":
      return "products@hgv.it";
  }
};

export default DepartmentApps;
