import { PropsWithChildren } from "react";
import styles from "./PanelSection.module.css";

type Props = PropsWithChildren & {
  title?: string;
};

const PanelSection = ({ title, children }: Props) => (
  <div className={styles.section}>
    <h3>{title}</h3>
    {children}
  </div>
);

export default PanelSection;
