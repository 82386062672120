import { SP } from "../http/serviceportalApi";
import {
  ChartDataGroupedByRange,
  ChartRow,
  ChartRowGroupedByRange,
} from "../types";
import { getValueBasedOnValueType, getYearFromPeriod } from "../utils";

export const getChart1Data = (
  rows: SP.ChartRow[],
  valueType: SP.ValueType
): ChartRow[] =>
  rows.map(({ label, range1, range2, range3 }) => ({
    label: label,
    range1Value: getValueBasedOnValueType(range1, valueType),
    range2Value: getValueBasedOnValueType(range2, valueType),
    range3Value: getValueBasedOnValueType(range3, valueType),
  }));

export const getChartDataGroupedByRange = (
  rows: SP.ChartRow[],
  period: SP.Period
): ChartDataGroupedByRange => {
  const unorderedRows = rows.reduce(
    (
      state: { range: string; value: SP.ValuePercent }[],
      { range1, range2, range3 }: SP.ChartRow
    ) => {
      state.push({ range: getYearFromPeriod(0, period), value: range1 });
      state.push({ range: getYearFromPeriod(-1, period), value: range2 });
      state.push({ range: getYearFromPeriod(-2, period), value: range3 });
      return state;
    },
    []
  );

  const rowsGroupedByRange = unorderedRows.reduce(
    (
      state: { [range: string]: SP.ValuePercent[] },
      value: { range: string; value: SP.ValuePercent }
    ) => {
      (state[value.range] = state[value.range] || []).push(value.value);
      return state;
    },
    {}
  );

  const chartRows = Object.entries(rowsGroupedByRange).map(
    ([range, values]) => ({
      range,
      values,
    })
  );

  const sortByRangeDesc = (
    a: ChartRowGroupedByRange,
    b: ChartRowGroupedByRange
  ) => parseInt(b.range.substring(0, 4)) - parseInt(a.range.substring(0, 4));

  chartRows.sort((a, b) => sortByRangeDesc(a, b));

  return { labels: rows.map(({ label }) => label), chartRows };
};

export const getMonthlyChartData = (
  rows: SP.MonthlyChartRow[],
  untilYear: number,
  getYearValue: (rows: SP.MonthlyChartRow[], year: number) => number
) => {
  const rowsGroupedByMonth = rows.reduce(
    (
      state: { [month: number]: SP.MonthlyChartRow[] },
      value: SP.MonthlyChartRow
    ) => {
      (state[value.month] = state[value.month] || []).push(value);
      return state;
    },
    {}
  );

  return Object.entries(rowsGroupedByMonth).map(([month, rows]) => ({
    month,
    year1: getYearValue(rows, untilYear),
    year2: getYearValue(rows, untilYear - 1),
    year3: getYearValue(rows, untilYear - 2),
  }));
};
