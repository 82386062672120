import { useRef } from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import { useAdminUser } from "../../http/serviceportalApi";
import { notAvailableMsg } from "../../utils";
import ErrorText from "../ui/ErrorText";
import LoadingSpinner from "../ui/LoadingSpinner";
import NavTabs from "../ui/NavTabs";
import Page from "../ui/Page";
import ShortInfo from "../ui/ShortInfo";
import styles from "./AdminUser.module.css";
import AdminUserAssignments from "./AdminUserAssignments";
import AdminUserInfos from "./AdminUserInfos";

const AdminUser = () => {
  const { userId } = useParams();
  const aborter = useRef(new AbortController());
  const {
    data: user,
    isValidating,
    error,
  } = useAdminUser(userId, aborter.current.signal);
  const displayName = user
    ? user.name
      ? user.name
      : `${user.firstName} ${user.lastName}`
    : notAvailableMsg;

  return (
    <>
      <Page
        title={isValidating ? "" : displayName}
        shortInfo={
          !isValidating ? (
            <ShortInfo title="ID">{user?.id ?? notAvailableMsg}</ShortInfo>
          ) : undefined
        }
        backLinkText="Zurück zu Benutzer"
      >
        <NavTabs
          tabs={[
            { to: "infos", text: "Infos" },
            { to: "zugriff", text: "Zugriff" },
          ]}
        />
        {isValidating && (
          <div className={styles.status}>
            <LoadingSpinner />
          </div>
        )}
        {error && (
          <div className={styles.status}>
            <ErrorText text="Fehler beim Laden des Benutzers." />
          </div>
        )}
        {user && (
          <Routes>
            <Route path="infos" element={<AdminUserInfos user={user} />} />
            <Route
              path="zugriff"
              element={<AdminUserAssignments userId={user.id} />}
            />
            <Route path="*" element={<Navigate to="infos" replace />} />
          </Routes>
        )}
      </Page>
    </>
  );
};

export default AdminUser;
