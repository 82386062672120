import { SP } from "./http/serviceportalApi";
import { VV } from "./http/vertragsverwaltungApi";
import { Permission } from "./types";
import { notAvailableMsg } from "./utils";

const i18n = {
  title: "HGV Serviceportal",
  departments: {
    accounting: { title: "Steuerberatung", slug: "steuerberatung" },
    payroll: { title: "Personalberatung", slug: "personalberatung" },
    webMarketing: { title: "Web Marketing", slug: "web-marketing" },
  },
  allCompanies: "Alle Betriebsstätten",
  companies: (count: number) =>
    `${count} ${count === 1 ? "Betriebsstätte" : "Betriebsstätten"}`,
  getDocumentSetName: (type: SP.DocumentSetType) => {
    switch (type) {
      case "lohnauswertung":
        return "Lohnauswertung";
      case "zahlungsbestaetigung-f24":
        return "Zahlungsbestätigung F24";
      case "einheitslohnbuch":
        return "Einheitslohnbuch";
      case "formblatt-770":
        return "Formblatt 770";
      case "formblatt-cu":
        return "Formblatt CU";
      case "matrikelbuch":
        return "Matrikelbuch";
      case "arbeitsvertraege":
        return "Arbeitsverträge";
      case "lohn-rundschreiben":
      case "steuer-rundschreiben":
        return "Rundschreiben";
      case "prov-lohnstreifen":
        return "Prov. Lohnstreifen";
      case "zusaetzliche-auswertungen":
        return "Zusätzliche Auswertungen";
      case "jahresdokumente":
        return "Jahresdokumente";
      case "antrag-um-familiengeld":
        return "Antrag um Familiengeld";
      case "jahresauswertungen":
        return "Jahresauswertungen";
    }
  },
  getAgreementStatus: (status: VV.AgreementStatus) => {
    switch (status) {
      case "Published":
        return "Offen";
      case "Signed":
        return "Bestätigt";
      case "Cancelled":
        return "Gekündigt";
      default:
        return notAvailableMsg;
    }
  },
  getPermission: (p: Permission): string => {
    switch (p) {
      case Permission.UsersRead:
        return "Benutzer lesen";
      case Permission.UsersWrite:
        return "Benutzer schreiben";
      case Permission.InvitesRead:
        return "Einladungen lesen";
      case Permission.MemberUsersRead:
        return "Mitgliedsbenutzer lesen";
      case Permission.MemberUsersWrite:
        return "Mitgliedsbenutzer schreiben";
      case Permission.AccountingsRead:
        return "Kennzahlen";
      case Permission.OpenItemsRead:
        return "Offene Posten";
      case Permission.WageCostsRead:
        return "Kennzahlen";
      case Permission.WebsiteMetricsRead:
        return "Kennzahlen";
      case Permission.HourRegistrationExecute:
        return "Stundenregister";
      case Permission.EmployeeRegistrationExecute:
        return "Mitarbeitermeldung";
      case Permission.InvoicePortalExecute:
        return "SEAC Rechnungsportal";
      case Permission.AgreementsExecute:
        return "Vertragsverwaltung";
      case Permission.BusinessCheckExecute:
        return "Business Check";
      case Permission.TIBuchRead:
        return "Tagesinkassobuch";
      case Permission.MonatsdatenDataWrite:
        return "Monatsdaten";
      case Permission.MonatsdatenSettingsWrite:
        return "Monatsdaten Admin";
      case Permission.EndbestaendeWorksheetsWrite:
        return "Endbestände";
      case Permission.EndbestaendeSettingsWrite:
        return "Endbestände Admin";
      case Permission.Dac7Write:
        return "DAC7 Meldepflicht";
      case Permission.DocumentsSteuerRundschreibenRead:
        return "Rundschreiben";
      case Permission.DocumentsJahresauswertungRead:
        return "Jahresauswertung";
      case Permission.DocumentsRevisorenRead:
        return "Revisorendokumente";
      case Permission.DocumentsBetriebOnlineServiceRead:
        return "Betrieb Online Service";
      case Permission.DocumentsBetriebStandardRead:
        return "Betrieb Standard";
      case Permission.DocumentsPrivateHaushalteRead:
        return "Private Haushalte";
      case Permission.DocumentsWirtschaftsberaterRead:
        return "Wirtschaftsberater";
      case Permission.DocumentsAufstellungNettoloehneRead:
        return "Aufstellung Nettolöhne";
      case Permission.DocumentsBuchungsbelegeRead:
        return "Buchungsbelege";
      case Permission.DocumentsCUDRead:
        return "CUD";
      case Permission.DocumentsEinbehalt15Read:
        return "Einbehalt 1/5";
      case Permission.DocumentsErklSteuerfreibetraegeRead:
        return "Erkl. Steuerfreibeträge";
      case Permission.DocumentsLaborfondMonatlichRead:
        return "Laborfond monatlich";
      case Permission.DocumentsLaborfondTrimestralRead:
        return "Laborfond trimestral";
      case Permission.DocumentsLohnstreifenRead:
        return "Lohnstreifen";
      case Permission.DocumentsMatrikelbuchRead:
        return "Matrikelbuch";
      case Permission.DocumentsF24Read:
        return "F24";
      case Permission.DocumentsNummInailRead:
        return "Numm.INAIL";
      case Permission.DocumentsSchulgeldLehrlingeRead:
        return "Schulgeld Lehrlinge";
      case Permission.DocumentsUeberweisungslisteRead:
        return "Überweisungsliste";
      case Permission.DocumentsZahlungsbestaetigungF24Read:
        return "Zahlungsbestätigung F24";
      case Permission.DocumentsEinheitslohnbuchRead:
        return "Einheitslohnbuch";
      case Permission.Documents770SemplificatoRead:
        return "770 semplificato";
      case Permission.Documents770OrdinarioRead:
        return "770 ordinario";
      case Permission.DocumentsArbeitsvertraegeRead:
        return "Arbeitsverträge";
      case Permission.DocumentsLohnRundschreibenRead:
        return "Rundschreiben";
      case Permission.DocumentsProvisorischerLohnstreifenRead:
        return "Provisorischer Lohnstreifen";
    }
  },
  getDepartment: (d: SP.Department): string => {
    switch (d) {
      case "Steuer":
        return "Steuerberatung";
      case "Personal":
        return "Personalberatung";
      default:
        return d;
    }
  },
};

export default i18n;
