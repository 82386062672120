import { SP } from "../../http/serviceportalApi";
import Label from "../ui/Label";
import Panel from "../ui/Panel";
import styles from "./AdminUserInfos.module.css";

interface Props {
  user: SP.User & { firstName: string; lastName: string };
}

const AdminUserInfos = ({
  user: { name, firstName, lastName, email },
}: Props) => {
  const displayName = name ? name : `${firstName} ${lastName}`;
  return (
    <Panel>
      <div className={styles.infos}>
        <div>
          <Label>Name</Label>
          {displayName}
        </div>
        <div>
          <Label>E-Mail-Adresse</Label>
          {email}
        </div>
      </div>
    </Panel>
  );
};

export default AdminUserInfos;
