import { Glyph } from "../../types";

const SvgPdf: Glyph = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
      <path
        fill="#fff"
        d="M3.5 15h9c.275 0 .5-.225.5-.5V5h-1.5c-.827 0-1.5-.673-1.5-1.5V1H3.5c-.275 0-.5.225-.5.5v13c0 .275.225.5.5.5z"
      />
      <path
        fill="#fff"
        d="M13 4v-.086a.496.496 0 00-.146-.353L11 1.707V3.5c0 .275.225.5.5.5H13z"
      />
      <path
        opacity=".64"
        fill="#605esc"
        d="M13.56 2.853L11.146.44a1.51 1.51 0 00-1.06-.44H3.5C2.673 0 2 .673 2 1.5v13c0 .827.673 1.5 1.5 1.5h9c.827 0 1.5-.673 1.5-1.5V3.914c0-.4-.156-.777-.44-1.06zm-.707.708c.095.094.147.22.147.353V4h-1.5a.501.501 0 01-.5-.5V1.707l1.854 1.854zM12.5 15h-9a.501.501 0 01-.5-.5v-13c0-.275.225-.5.5-.5H10v2.5c0 .827.673 1.5 1.5 1.5H13v9.5c0 .275-.225.5-.5.5z"
      />
      <path fill="#c8c6c4" d="M11.5 8h-7a.5.5 0 010-1h7a.5.5 0 010 1z" />
      <path
        fill="#d65532"
        d="M14.5 13h-2.3a.2.2 0 01-.2-.2v-2.6c0-.11.09-.2.2-.2h2.3a.5.5 0 01.5.5v2a.5.5 0 01-.5.5zM3.8 13H1.5a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h2.3c.11 0 .2.09.2.2v2.6a.2.2 0 01-.2.2zm6 0H6.2a.2.2 0 01-.2-.2v-2.6c0-.11.09-.2.2-.2h3.6c.11 0 .2.084.2.194v2.612c0 .11-.09.194-.2.194zM5 10v3a1 1 0 001 1h4a1 1 0 001-1v-3a1 1 0 00-1-1H6a1 1 0 00-1 1z"
      />
    </svg>
  );
};

export default SvgPdf;
