import i18n from "../../i18n";
import { Permission } from "../../types";
import PermissionSelect from "./PermissionSelect";

interface Props {
  selectedPermissions: string[];
  disabledPermissions?: string[];
  onChange: (permissions: string[]) => void;
}

const ApplicationPermissions = ({
  selectedPermissions,
  disabledPermissions,
  onChange,
}: Props) => (
  <PermissionSelect
    categories={[accounting, payroll, webMarketing]}
    renderItem={(permission) => {
      const isSelected = selectedPermissions.includes(permission);
      const isDisabled = disabledPermissions?.includes(permission);
      return (
        <PermissionSelect.Item
          key={permission}
          permission={permission}
          status={
            isSelected ? "selected" : isDisabled ? "disabled" : "not_selected"
          }
          onClick={() =>
            onChange([
              ...selectedPermissions.filter((p) => p !== permission),
              ...(!isSelected ? [permission] : []),
            ])
          }
        />
      );
    }}
  />
);

const accounting = {
  label: i18n.departments.accounting.title,
  permissions: [
    Permission.AccountingsRead,
    Permission.OpenItemsRead,
    Permission.InvoicePortalExecute,
    Permission.BusinessCheckExecute,
    Permission.TIBuchRead,
    Permission.MonatsdatenDataWrite,
    Permission.EndbestaendeWorksheetsWrite,
  ],
};

const payroll = {
  label: i18n.departments.payroll.title,
  permissions: [
    Permission.HourRegistrationExecute,
    Permission.EmployeeRegistrationExecute,
    Permission.WageCostsRead,
  ],
};

const webMarketing = {
  label: i18n.departments.webMarketing.title,
  permissions: [
    Permission.WebsiteMetricsRead,
    Permission.AgreementsExecute,
    Permission.Dac7Write,
  ],
};

export default ApplicationPermissions;
