import classNames from "classnames";
import { ReactNode } from "react";
import { UseListFiltersReturn } from "../../hooks/useListFilters";
import { Glyph, RemoteDataStatus } from "../../types";
import SvgNavigateNext from "../icons/NagivateNext";
import SvgNavigateBefore from "../icons/NavigateBefore";
import ErrorText from "./ErrorText";
import Icon from "./Icon";
import LoadingSpinner from "./LoadingSpinner";
import SearchBar from "./SearchBar";
import styles from "./Table.module.css";

interface Props {
  filters?: UseListFiltersReturn;
  searchOptions?: {
    placeholder: string;
    onChange: () => void;
  };
  total: number;
  head?: ReactNode;
  body: ReactNode;
  footer?: ReactNode;
  caption?: ReactNode;
  isSelectable?: boolean;
  isTall?: boolean;
  isStriped?: boolean;
  classNames?: {
    tableWrapper?: string;
    table?: string;
    controls?: string;
  };
  remoteDataStatus?: RemoteDataStatus;
}

const Table = ({
  filters,
  searchOptions,
  total,
  head,
  body,
  footer,
  caption,
  isSelectable = true,
  isTall = false,
  isStriped = false,
  classNames: stylesOverwrite,
  remoteDataStatus = "success",
}: Props) => {
  const isSearchable = filters && filters.isSearchable;
  const page = filters?.state.page ?? 1;
  const limit = filters?.state.limit ?? 5;

  const fromItem = (page - 1) * limit + 1;
  const maxToItem = fromItem + limit - 1;
  const toItem = Math.min(maxToItem, total);

  return (
    <div className={classNames(styles.wrapper, stylesOverwrite?.tableWrapper)}>
      <div className={styles.heading}>
        {isSearchable && (
          <SearchBar
            className={styles.search}
            placeholder={searchOptions?.placeholder ?? "Suche"}
            value={filters.state.q ?? ""}
            onChange={(term) => {
              searchOptions?.onChange();
              filters.setSearchTerm(term);
            }}
          />
        )}

        <table
          className={classNames(styles.table, stylesOverwrite?.table, {
            [styles.selectable]: isSelectable,
            [styles.tall]: isTall,
            [styles.striped]: isStriped,
          })}
        >
          {head && <thead>{head}</thead>}
          <tbody>{body}</tbody>
          {footer && <tfoot>{footer}</tfoot>}
          {caption && <caption className={styles.caption}>{caption}</caption>}
        </table>

        {remoteDataStatus === "success" && total === 0 && (
          <div className={styles.status}>Keine Einträge gefunden.</div>
        )}

        {remoteDataStatus === "validating" && (
          <div className={styles.loadingSpinner}>
            <LoadingSpinner delayed={0} />
          </div>
        )}

        {remoteDataStatus === "failure" && (
          <div className={styles.status}>
            <ErrorText text="Fehler beim Laden der Einträge" />
          </div>
        )}
      </div>

      {filters && remoteDataStatus === "success" && total > 0 && (
        <div className={classNames(styles.controls, stylesOverwrite?.controls)}>
          <div>
            {fromItem} – {toItem} von {total}
          </div>
          <div className={styles.pagination}>
            <PaginateBtn
              glyph={SvgNavigateBefore}
              title={`Vorherige ${limit} Zeilen`}
              disabled={page === 1}
              onClick={filters.prevPage}
            />
            <PaginateBtn
              glyph={SvgNavigateNext}
              disabled={toItem === total}
              title={`Nächste ${limit} Zeilen`}
              onClick={filters.nextPage}
            />
          </div>
        </div>
      )}
    </div>
  );
};

interface PaginateBtnProps {
  glyph: Glyph;
  title: string;
  onClick: () => void;
  disabled?: boolean;
}

const PaginateBtn = ({ glyph, title, onClick, disabled }: PaginateBtnProps) => {
  return (
    <button
      className={styles.paginationButton}
      title={title}
      onClick={onClick}
      disabled={disabled}
    >
      <Icon className={styles.paginationButtonIcon} glyph={glyph} />
    </button>
  );
};

export default Table;
