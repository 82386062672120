import { PropsWithChildren } from "react";
import { OpenItemStatus } from "../../types";
import SearchBar from "../ui/SearchBar";
import styles from "./Filters.module.css";

type StatusFilterOption = OpenItemStatus | "all";

export interface FilterState {
  status: StatusFilterOption;
  openUntil: number | undefined;
  term: string;
}

interface Props {
  state: FilterState;
  years: number[];
  onChange: (updated: FilterState) => void;
}

const Filters = ({ state, years, onChange }: Props) => (
  <div className={styles.filters}>
    <Filter id="status" label="Status">
      <select
        id="status"
        defaultValue={state.status}
        onChange={(e) =>
          onChange({
            ...state,
            status: e.target.value as StatusFilterOption,
          })
        }
      >
        <option value="all">Alle</option>
        <option value="open">Offen</option>
        <option value="settled">Beglichen</option>
      </select>
    </Filter>

    <Filter id="openUntil" label="Offen bis">
      <select
        id="openUntil"
        defaultValue={state.openUntil}
        onChange={(e) =>
          onChange({
            ...state,
            openUntil: +e.target.value,
          })
        }
      >
        {years
          .sort((a, b) => b - a)
          .map((year, index) => (
            <option key={index} value={year}>
              {year}
            </option>
          ))}
      </select>
    </Filter>

    <Filter id="search" label="Suche">
      <SearchBar
        id="search"
        className={styles.search}
        placeholder="Bezeichnung / MwSt.-Nummer"
        value={state.term}
        onChange={(term) =>
          onChange({
            ...state,
            term,
          })
        }
      />
    </Filter>
  </div>
);

type FilterProps = PropsWithChildren<{ id: string; label: string }>;

const Filter = ({ id, label, children }: FilterProps) => (
  <div className={styles.filter}>
    <label htmlFor={id}>{label}</label>
    {children}
  </div>
);

export default Filters;
