import { useRef } from "react";
import useListFilters from "../../hooks/useListFilters";
import { useAdminInvites } from "../../http/serviceportalApi";
import i18n from "../../i18n";
import { formatLongDateTime, getRemoteDataStatus } from "../../utils";
import Page from "../ui/Page";
import Table from "../ui/Table";
import InviteStatus from "./InviteStatus";

const AdminInvites = () => {
  const filters = useListFilters();
  const aborter = useRef(new AbortController());
  const { data, isValidating, error } = useAdminInvites(
    filters.state,
    aborter.current.signal
  );
  const items = data?.items ?? [];
  const total = data?.total ?? 0;
  const status = getRemoteDataStatus({ isValidating, error });

  return (
    <Page title="Einladungen">
      <Table
        filters={filters}
        head={
          <tr>
            <th align="left">E-Mail-Adresse</th>
            <th align="left">Mitglied</th>
            <th align="left">Betriebsstätten</th>
            <th align="left">Gültig bis</th>
            <th align="left">Status</th>
          </tr>
        }
        body={items.map((invite, index) => (
          <tr key={index}>
            <td>{invite.email}</td>
            <td>{invite.memberName}</td>
            <td>{invite.companyId ? invite.companyName : i18n.allCompanies}</td>
            <td>{formatLongDateTime(invite.expiresAt)}</td>
            <td>
              <InviteStatus invite={invite} />
            </td>
          </tr>
        ))}
        total={total}
        remoteDataStatus={status}
      />
    </Page>
  );
};

export default AdminInvites;
