import { Glyph } from "../../types";
import Icon from "./Icon";
import styles from "./QuickAccess.module.css";

interface Props {
  items: QuickAccessItemProps[];
}

const QuickAccess = ({ items }: Props) => (
  <div className={styles.quickAccess}>
    {items.map((item) => (
      <QuickAccessItem key={item.name} {...item} />
    ))}
  </div>
);

interface QuickAccessItemProps {
  name: string;
  type: string;
  glyph: Glyph;
  onNavigate: () => void;
}

const QuickAccessItem = ({
  name,
  type,
  glyph,
  onNavigate,
}: QuickAccessItemProps) => (
  <article className={styles.item} onClick={onNavigate}>
    <Icon glyph={glyph} className={styles.icon} />
    <div>
      <h3 className={styles.heading}>{name}</h3>
      <span className={styles.type}>{type}</span>
    </div>
  </article>
);

export default QuickAccess;
