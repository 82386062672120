import { createContext, Dispatch, useReducer } from "react";

type Severity = "success" | "error" | "informative";

type State = {
  show: boolean;
  severity: Severity;
  message: string | undefined;
};

type Action =
  | {
      type: "SHOW_NOTIFICATION";
      severity: Severity;
      message: string;
    }
  | { type: "HIDE_NOTIFICATION" };

const showNotification = (severity: Severity, message: string): Action => ({
  type: "SHOW_NOTIFICATION",
  severity,
  message,
});

export const showInformativeNotification = (message: string): Action =>
  showNotification("informative", message);

export const showSuccessNotification = (message?: string): Action =>
  showNotification("success", message ?? "Daten erfolgreich gespeichert.");

export const showErrorNotification = (
  error: unknown,
  message?: string
): Action => {
  if (error instanceof Error) {
    console.error(error);
  }
  return showNotification(
    "error",
    message ?? "Fehler beim Speichern der Daten."
  );
};

export const hideNotification = (): Action => ({
  type: "HIDE_NOTIFICATION",
});

const initialState: State = {
  show: false,
  severity: "success",
  message: undefined,
};

const notificationReducer = (_: State, action: Action): State => {
  switch (action.type) {
    case "SHOW_NOTIFICATION":
      return { show: true, severity: action.severity, message: action.message };
    case "HIDE_NOTIFICATION":
      return initialState;
  }
};

export const useNotification = (): [State, Dispatch<Action>] =>
  useReducer(notificationReducer, initialState);

const NotificationDispatch = createContext<Dispatch<Action>>(() => {});

export default NotificationDispatch;
