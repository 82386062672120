import classNames from "classnames";
import styles from "./Sidebar.module.css";
import { PropsWithChildren, useEffect } from "react";

type Props = PropsWithChildren<{
  isOpen: boolean;
  close: () => void;
}>;

const Sidebar = ({ isOpen, close, children }: Props) => {
  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") close();
    };
    isOpen
      ? window.addEventListener("keydown", onKeyDown)
      : window.removeEventListener("keydown", onKeyDown);
    return () => window.removeEventListener("keydown", onKeyDown);
  }, [isOpen]);

  return (
    <aside
      className={classNames(styles.sidebar, {
        [styles.sidebarClosed]: !isOpen,
      })}
    >
      <div className={styles.scrollable}>{children}</div>
      {isOpen && (
        <div className={styles.menuBackground} onClick={() => close()} />
      )}
    </aside>
  );
};

export default Sidebar;
