import {
  Cell,
  Label,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import styles from "./MetricsPieChart.module.css";
import { colorCycle } from "../../utils";
import PercentPieChartLabel from "./PercentPieChartLabel";
import SvgBullet from "../icons/Bullet";
import { SP } from "../../http/serviceportalApi";

interface Props {
  title?: string;
  data: SP.PieChartRow[];
  formatter?: (value: number) => string;
}

const MetricsPieChart = ({ title, data, formatter }: Props) => (
  <ResponsiveContainer width="100%" height="100%">
    <PieChart>
      <Pie
        nameKey="key"
        dataKey="value"
        data={data}
        cx="50%"
        cy="50%"
        label={PercentPieChartLabel}
        labelLine={false}
        // Animation stops labels from being rendered (e.g. when data changes).
        // This is a temporary fix till recharts or maybe react-smooth fixes this bug.
        // https://github.com/recharts/recharts/issues/829
        isAnimationActive={false}
      >
        {data.map((_, index) => (
          <Cell key={index} fill={colorCycle(index)} />
        ))}
        {title && (
          <Label value={title} className={styles.label} position="center" />
        )}
      </Pie>
      <Tooltip
        formatter={(value: number) => (
          <p className={styles.tooltip}>
            <span className={styles.unit}>Sitzungen </span>
            <span className={styles.value}>
              {formatter && formatter(value)}
            </span>
          </p>
        )}
      />
      <Legend
        layout="vertical"
        align="right"
        verticalAlign="middle"
        iconSize={24}
        wrapperStyle={{ paddingLeft: "1em" }}
        payload={data.map((element, index) => ({
          value: element.key,
          color: "var(--ci-grey)",
          legendIcon: <SvgBullet fill={colorCycle(index)} />,
        }))}
      />
    </PieChart>
  </ResponsiveContainer>
);

export default MetricsPieChart;
