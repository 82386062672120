import { useEffect, useState } from "react";
import { Link, Navigate, Route, Routes } from "react-router-dom";
import { useAuth } from "../context/authContext";
import { useMediaQuery } from "../hooks/useMediaQuery";
import { useMe } from "../http/serviceportalApi";
import { Permission } from "../types";
import { includesAny } from "../utils";
import AccountButton from "./AccountButton";
import styles from "./AdminApp.module.css";
import Sidebar from "./Sidebar";
import SvgOutbox from "./icons/Outbox";
import SvgPeople from "./icons/People";
import AdminInvites from "./invites/AdminInvites";
import Hamburger from "./ui/Hamburger";
import LoadingSpinner from "./ui/LoadingSpinner";
import Nav from "./ui/Nav";
import AdminUser from "./users/AdminUser";
import AdminUsers from "./users/AdminUsers";
import AppMenu from "./ui/AppMenu";

const AdminApp = () => {
  const auth = useAuth();
  const matchesMedia = useMediaQuery("(min-width: 1400px)");
  const [isSidebarOpen, setSidebarOpen] = useState(matchesMedia);

  const { data, isValidating } = useMe();
  const userPermissions = data?.permissions ?? [];

  const usersRoute = includesAny(userPermissions, [
    Permission.UsersRead,
    Permission.UsersWrite,
  ])
    ? "benutzer"
    : undefined;
  const invitesRoute = userPermissions.includes(Permission.InvitesRead)
    ? "einladungen"
    : undefined;

  const closeMenu = () => {
    !matchesMedia && setSidebarOpen(false);
  };

  useEffect(closeMenu, [matchesMedia]);

  if (!auth.session) {
    return null;
  }

  return isValidating ? (
    <div className={styles.loadingSpinner}>
      <LoadingSpinner />
    </div>
  ) : (
    <>
      <header className={styles.header}>
        <div className={styles.aside}>
          <Hamburger
            isOpen={isSidebarOpen}
            className={styles.hamburger}
            onClick={() => setSidebarOpen(!isSidebarOpen)}
          />
          <Link to="/admin" className={styles.logo}>
            Serviceportal Administration
          </Link>
        </div>
        <AppMenu />
        <AccountButton />
      </header>
      <div>
        <Sidebar isOpen={isSidebarOpen} close={closeMenu}>
          <Nav>
            {usersRoute && (
              <Nav.Item
                text="Benutzer"
                to={usersRoute}
                icon={SvgPeople}
                onClick={closeMenu}
              />
            )}
            {invitesRoute && (
              <Nav.Item
                text="Einladungen"
                to={invitesRoute}
                icon={SvgOutbox}
                onClick={closeMenu}
              />
            )}
          </Nav>
        </Sidebar>
        <Routes>
          {usersRoute && (
            <Route path={usersRoute}>
              <Route index element={<AdminUsers />} />
              <Route path=":userId/*" element={<AdminUser />} />
            </Route>
          )}
          {invitesRoute && (
            <Route path={invitesRoute} element={<AdminInvites />} />
          )}

          <Route
            path="*"
            element={
              <Navigate to={usersRoute ?? invitesRoute ?? "/"} replace />
            }
          />
        </Routes>
      </div>
    </>
  );
};

export default AdminApp;
