import { TooltipProps } from "recharts";
import SvgBullet from "../icons/Bullet";
import styles from "./ChartTooltip.module.css";

const ChartTooltip = ({
  label,
  labelFormatter,
  payload,
  formatter,
}: TooltipProps<number, string>) => (
  <div className={styles.wrapper}>
    <span className={styles.title}>
      {(payload && labelFormatter?.(label, payload)) ?? label}
    </span>
    <ol className={styles.list}>
      {payload?.map((p, i) => {
        let { value, name } = p;
        return (
          <li key={i} className={styles.listItem}>
            <span className={styles.label}>
              <SvgBullet className={styles.icon} fill={p.color} />
              {p.name}
            </span>{" "}
            <span className={styles.value}>
              {formatter &&
                value &&
                name &&
                formatter(value, name, p, i, payload)}
            </span>
          </li>
        );
      })}
    </ol>
  </div>
);

export default ChartTooltip;
