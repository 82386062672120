import { forwardRef } from "react";
import { useMember } from "../../context/memberContext";
import { SP } from "../../http/serviceportalApi";
import i18n from "../../i18n";
import { Permission } from "../../types";
import { includesAny } from "../../utils";
import Select from "./Select";

type Props = JSX.IntrinsicElements["select"] & {
  memberId?: SP.MemberId;
  isInvalid?: boolean;
  isSingleSelect?: boolean;
  alwaysShowAllCompaniesOption?: boolean;
  allowedPermissions?: Permission[];
};

const CompanySelect = forwardRef<HTMLSelectElement, Props>(
  (
    {
      isSingleSelect = false,
      alwaysShowAllCompaniesOption = false,
      allowedPermissions,
      ...props
    },
    ref
  ) => {
    const { member } = useMember();
    const hasMemberPermissions =
      !allowedPermissions ||
      includesAny(member?.permissions ?? [], allowedPermissions);
    const filteredCompanies = !hasMemberPermissions
      ? member?.companies?.filter((c) =>
          includesAny(c.permissions ?? [], allowedPermissions)
        )
      : member?.companies;
    const sortedCompanies =
      filteredCompanies?.sort((a, b) => a.name.localeCompare(b.name)) ?? [];

    return (
      <>
        {member ? (
          <Select {...props} ref={ref}>
            {!isSingleSelect &&
              (alwaysShowAllCompaniesOption || sortedCompanies.length > 1) &&
              hasMemberPermissions && (
                <option value="all">{i18n.allCompanies}</option>
              )}
            {sortedCompanies.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </Select>
        ) : (
          <Select>
            <option disabled>Lade Betriebe …</option>
          </Select>
        )}
      </>
    );
  }
);

export default CompanySelect;
