import { useEffect, useState } from "react";
import PdfPreview from "./PdfPreview";

interface Props {
  title: string;
  blob: Blob | undefined;
}

const PdfBlobPreview = ({ title, blob }: Props) => {
  const [pdfObject, setPdfObject] = useState<string>();

  useEffect(() => {
    if (blob === undefined) return;
    const url = window.URL.createObjectURL(blob);
    setPdfObject(url);
    return () => {
      window.URL.revokeObjectURL(url);
    };
  }, [blob]);

  return (
    <PdfPreview
      title={title}
      isOpen={pdfObject !== undefined}
      onClose={() => setPdfObject(undefined)}
      src={pdfObject}
    />
  );
};

export default PdfBlobPreview;
