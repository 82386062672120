import classNames from "classnames";
import styles from "./Hamburger.module.css";

interface Props {
  isOpen: boolean;
  className?: string;
  onClick: () => void;
}

const Hamburger = ({ isOpen, className, onClick }: Props) => (
  <button
    className={classNames(className, styles.hamburger, {
      [styles.open]: isOpen,
    })}
    onClick={onClick}
  >
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </button>
);

export default Hamburger;
