import { FunctionComponent, ReactNode, SVGProps } from "react";
import { SP } from "../http/serviceportalApi";

export type Glyph = FunctionComponent<SVGProps<SVGSVGElement>>;

export type Office = "bozen" | "meran" | "brixen" | "bruneck" | "schlanders";

export type Department = "accounting" | "payroll" | "webMarketing";

export type RemoteDataStatus = "success" | "validating" | "failure";

export interface App {
  id: string;
  name: string;
  department: Department;
  description?: ReactNode;
  isVisible: boolean;
  publishedAt?: Date;
  onNavigate: () => void | Promise<void>;
}

export interface ListFilters {
  page: number;
  limit: number;
  q?: string;
}

export type Paginated<P> = P & { total: number };

export type PaginationQuery<T = {}> = T & Partial<ListFilters>;

export interface FetcherOptions {
  url: string;
  query?: object;
  signal?: AbortSignal;
}

export type Indexed<T> = [T, number];

export type Evaluation = "competence" | "fiscal";

export type ViewMode = "day" | "week" | "month";

export interface ChartRow {
  label: string;
  range1Value: number;
  range2Value: number;
  range3Value: number;
}

export interface ChartRowGroupedByRange {
  range: string;
  values: SP.ValuePercent[];
}

export interface ChartDataGroupedByRange {
  labels: string[];
  chartRows: ChartRowGroupedByRange[];
}

export enum Permission {
  UsersRead = "users:read",
  UsersWrite = "users:write",
  InvitesRead = "invites:read",
  MemberUsersRead = "member_users:read",
  MemberUsersWrite = "member_users:write",
  AccountingsRead = "accountings:read",
  OpenItemsRead = "open_items:read",
  WageCostsRead = "wage_costs:read",
  WebsiteMetricsRead = "website_metrics:read",
  HourRegistrationExecute = "hour_registration:execute",
  EmployeeRegistrationExecute = "employee_registration:execute",
  InvoicePortalExecute = "invoice_portal:execute",
  AgreementsExecute = "agreements:execute",
  BusinessCheckExecute = "businesscheck:execute",
  TIBuchRead = "tibuch:read",
  MonatsdatenDataWrite = "monatsdaten.data:write",
  MonatsdatenSettingsWrite = "monatsdaten.settings:write",
  EndbestaendeWorksheetsWrite = "endbestaende.worksheets:write",
  EndbestaendeSettingsWrite = "endbestaende.settings:write",
  Dac7Write = "dac7:write",
  DocumentsSteuerRundschreibenRead = "documents.steuer_rundschreiben:read",
  DocumentsJahresauswertungRead = "documents.jahresauswertung:read",
  DocumentsRevisorenRead = "documents.revisoren:read",
  DocumentsBetriebOnlineServiceRead = "documents.betrieb_online_service:read",
  DocumentsBetriebStandardRead = "documents.betrieb_standard:read",
  DocumentsPrivateHaushalteRead = "documents.private_haushalte:read",
  DocumentsWirtschaftsberaterRead = "documents.wirtschaftsberater:read",
  DocumentsAufstellungNettoloehneRead = "documents.aufstellung_nettoloehne:read",
  DocumentsBuchungsbelegeRead = "documents.buchungsbelege:read",
  DocumentsCUDRead = "documents.cud:read",
  DocumentsEinbehalt15Read = "documents.einbehalt_1_5:read",
  DocumentsErklSteuerfreibetraegeRead = "documents.erkl_steuerfreibetraege:read",
  DocumentsLaborfondMonatlichRead = "documents.laborfond_monatlich:read",
  DocumentsLaborfondTrimestralRead = "documents.laborfond_trimestral:read",
  DocumentsLohnstreifenRead = "documents.lohnstreifen:read",
  DocumentsMatrikelbuchRead = "documents.matrikelbuch:read",
  DocumentsF24Read = "documents.f24:read",
  DocumentsNummInailRead = "documents.numm_inail:read",
  DocumentsSchulgeldLehrlingeRead = "documents.schulgeld_lehrlinge:read",
  DocumentsUeberweisungslisteRead = "documents.ueberweisungsliste:read",
  DocumentsZahlungsbestaetigungF24Read = "documents.zahlungsbestaetigung_f24:read",
  DocumentsEinheitslohnbuchRead = "documents.einheitslohnbuch:read",
  Documents770SemplificatoRead = "documents.770_semplificato:read",
  Documents770OrdinarioRead = "documents.770_ordinario:read",
  DocumentsArbeitsvertraegeRead = "documents.arbeitsvertraege:read",
  DocumentsLohnRundschreibenRead = "documents.lohn_rundschreiben:read",
  DocumentsProvisorischerLohnstreifenRead = "documents.provisorischer_lohnstreifen:read",
}

export interface DocumentFilterOptions {
  typeOptions: SP.FacetOption[];
  dateOptions: SP.FacetOption[];
  customerOptions?: SP.FacetOption[];
  companyOptions?: SP.FacetOption[];
  memberOptions?: SP.FacetOption[];
  departmentOptions?: SP.FacetOption[];
}

export interface DocumentFilterState {
  type?: string;
  date?: string;
  employee?: string;
  customer?: string;
  company?: string;
  member?: string;
  department?: SP.Department;
}

export type OpenItemStatus = "open" | "settled";
