import { useRef, useState } from "react";
import { useMember } from "../../context/memberContext";
import useListFilters from "../../hooks/useListFilters";
import {
  SP,
  deleteMemberInvite,
  useMemberInvites,
} from "../../http/serviceportalApi";
import i18n from "../../i18n";
import { formatLongDateTime, getRemoteDataStatus } from "../../utils";
import SvgDelete from "../icons/Delete";
import InviteStatus from "../invites/InviteStatus";
import Table from "../ui/Table";
import TableButton, { TableButtonCell } from "../ui/TableButton";

const MemberInvites = () => {
  const { member } = useMember();

  const filters = useListFilters();

  const aborter = useRef(new AbortController());
  const { data, isValidating, error, mutate } = useMemberInvites(
    member?.id,
    filters.state,
    aborter.current.signal
  );
  const items = data?.items ?? [];
  const total = data?.total ?? 0;
  const status = getRemoteDataStatus({ isValidating, error });

  if (!member) return null;

  return (
    <Table
      filters={filters}
      head={
        <tr>
          <th align="left">E-Mail-Adresse</th>
          <th align="left">Betriebsstätten</th>
          <th align="left">Gültig bis</th>
          <th align="left">Status</th>
          <th></th>
        </tr>
      }
      body={items.map((item, index) => (
        <Row
          key={index}
          invite={item}
          onDelete={(invite) =>
            deleteMemberInvite(member.id, invite.id).then(() => {
              mutate({
                ...data!,
                items: [...items.filter((inv) => inv.id !== item.id)],
              });
            })
          }
        />
      ))}
      total={total}
      remoteDataStatus={status}
    />
  );
};

interface RowProps {
  invite: SP.Invite;
  onDelete: (invite: SP.Invite) => Promise<void>;
}

const Row = ({ invite, onDelete }: RowProps) => {
  const [isDeleting, setDeleting] = useState(false);

  return (
    <tr>
      <td>{invite.email}</td>
      <td>{invite.companyId ? invite.companyName : i18n.allCompanies}</td>
      <td>{!invite.declinedAt && formatLongDateTime(invite.expiresAt)}</td>
      <td>
        <InviteStatus invite={invite} />
      </td>
      <TableButtonCell>
        <TableButton
          glyph={SvgDelete}
          loading={isDeleting}
          onClick={() => {
            setDeleting(true);
            onDelete(invite).finally(() => setDeleting(false));
          }}
        />
      </TableButtonCell>
    </tr>
  );
};

export default MemberInvites;
