import { Glyph } from "../../types";

const SvgCheckbox: Glyph = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" {...props}>
      <path d="m419-321 289-290-43-43-246 247-119-119-43 43 162 162ZM120-120v-720h720v720H120Z" />
    </svg>
  );
};

export const SvgCheckboxOutlineBlank: Glyph = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" {...props}>
      <path d="M120-120v-720h720v720H120Zm60-60h600v-600H180v600Z" />
    </svg>
  );
};

export default SvgCheckbox;
