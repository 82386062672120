import classNames from "classnames";
import { MouseEventHandler, PropsWithChildren } from "react";
import { Glyph } from "../../types";
import Icon from "./Icon";
import LoadingSpinner from "./LoadingSpinner";
import styles from "./TableButton.module.css";

interface Props {
  glyph: Glyph;
  title?: string;
  onClick: MouseEventHandler;
  disabled?: boolean;
  loading?: boolean;
}

const TableButton = ({
  glyph,
  title,
  onClick,
  disabled = false,
  loading = false,
}: Props) => {
  return (
    <button
      className={styles.tableButton}
      title={title}
      onClick={onClick}
      disabled={disabled || loading}
    >
      {!loading && <Icon className={styles.icon} glyph={glyph} />}
      {loading && (
        <div className={styles.icon}>
          <LoadingSpinner size="small" />
        </div>
      )}
    </button>
  );
};

export const TableButtonCell = ({ children }: PropsWithChildren) => (
  <td className={classNames(styles.tableButtonCell)}>
    <div onClick={(e) => e.stopPropagation()}>{children}</div>
  </td>
);

export default TableButton;
