import { useEffect, useReducer } from "react";
import { useNavigate, useParams } from "react-router";
import {
  VV,
  getAgreement,
  getPreviewAgreement,
  postSignAgreement,
} from "../../http/vertragsverwaltungApi";
import { useMember } from "../../context/memberContext";
import Button from "../ui/Button";
import Icon from "../ui/Icon";
import Page from "../ui/Page";
import DocumentViewer from "./DocumentViewer";
import styles from "./AgreementDetail.module.css";
import SvgInfo from "../icons/Info";

type State = {
  agreement: VV.Agreement | undefined;
  canSign: boolean;
  isSigning: boolean;
  acceptedTerms: boolean;
  showVexatoryClauses: boolean;
  acceptedVexatoryClauses: boolean;
  error: string | undefined;
};

type Action =
  | { type: "SET_AGREEMENT"; agreement: VV.Agreement }
  | { type: "ALLOW_SIGNING" }
  | { type: "TOGGLE_ACCEPTED_TERMS"; shouldHandleVexatoryClauses: boolean }
  | { type: "TOGGLE_ACCEPTED_VEXATORY_CLAUSES" }
  | { type: "SIGN_AGREEMENT_START" }
  | { type: "SIGN_AGREEMENT_ERROR"; error: string }
  | { type: "CLEAR_ERROR" };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_AGREEMENT":
      return { ...state, agreement: action.agreement };

    case "ALLOW_SIGNING":
      return { ...state, canSign: true };

    case "SIGN_AGREEMENT_START":
      return { ...state, isSigning: true };

    case "TOGGLE_ACCEPTED_TERMS":
      const showVexatoryClauses = !state.acceptedTerms
        ? action.shouldHandleVexatoryClauses
        : state.showVexatoryClauses;
      return {
        ...state,
        acceptedTerms: !state.acceptedTerms,
        showVexatoryClauses: showVexatoryClauses,
        acceptedVexatoryClauses: !action.shouldHandleVexatoryClauses,
      };

    case "TOGGLE_ACCEPTED_VEXATORY_CLAUSES":
      return {
        ...state,
        acceptedVexatoryClauses: !state.acceptedVexatoryClauses,
      };

    case "SIGN_AGREEMENT_ERROR":
      return { ...state, isSigning: false, error: action.error };

    case "CLEAR_ERROR":
      return { ...state, error: undefined };

    default:
      return state;
  }
};

const AgreementDetail = () => {
  const initialState: State = {
    agreement: undefined,
    canSign: false,
    isSigning: false,
    acceptedTerms: false,
    showVexatoryClauses: false,
    acceptedVexatoryClauses: false,
    error: undefined,
  };
  const { member } = useMember();
  if (!member) return null;

  const [state, dispatch] = useReducer(reducer, initialState);

  const { id: agreementId, version, showVexatoryClauses: svcP } = useParams();
  if (!agreementId || !version) return null;

  const navigate = useNavigate();

  const initializeAgreement = async () => {
    const agreement = await getAgreement(member.id, agreementId, +version);
    dispatch({
      type: "SET_AGREEMENT",
      agreement: agreement,
    });
  };

  const trySignAgreement = () => {
    dispatch({ type: "CLEAR_ERROR" });
    if (!(state.acceptedTerms && state.acceptedVexatoryClauses)) return;
    signAgreement();
  };

  const signAgreement = () => {
    dispatch({ type: "SIGN_AGREEMENT_START" });
    postSignAgreement(member.id, agreementId, +version)
      .then(() => {
        navigate("/vertraege-und-preise");
      })
      .catch(() => {
        dispatch({
          type: "SIGN_AGREEMENT_ERROR",
          error:
            "Fehler beim Bestätigen des Vertrages! Bitte versuchen Sie es erneut oder kontaktieren Sie die HGV-Abteilung Web Marketing.",
        });
      });
  };

  useEffect(() => {
    initializeAgreement();
  }, []);

  return (
    <Page
      title={state.agreement?.name ?? ""}
      backLinkText="Zurück zu Verträge &amp; Preise"
    >
      <div className={styles.agreementDetail}>
        {state.isSigning && (
          <div className={styles.statusIndicator}>
            Der Vertrag wird bestätigt …
          </div>
        )}
        {state.error && (
          <div className={styles.statusIndicator}>
            {state.error}
            <Button
              type="secondary"
              buttonProps={{
                disabled: !state.canSign,
                onClick: trySignAgreement,
              }}
            >
              Erneut versuchen
            </Button>
          </div>
        )}
        <DocumentViewer
          docRetriever={() =>
            getPreviewAgreement(member.id, agreementId, +version)
          }
          loadingMessage="Lade Vertrag …"
          onScrolledToBottom={() => dispatch({ type: "ALLOW_SIGNING" })}
        />
        <form
          className={styles.actions}
          onSubmit={(e) => {
            e.preventDefault();
            trySignAgreement();
          }}
        >
          {!state.canSign && (
            <span className={styles.infoText}>
              <Icon glyph={SvgInfo} className={styles.icon} /> Bitte scrollen
              Sie ganz nach unten, um den Vertag zu bestätigen.
            </span>
          )}
          {state.canSign && (
            <div className={styles.acceptTerms}>
              <input
                id="acceptTerms"
                type="checkbox"
                checked={state.acceptedTerms}
                required={true}
                onChange={() =>
                  dispatch({
                    type: "TOGGLE_ACCEPTED_TERMS",
                    shouldHandleVexatoryClauses: svcP === "true",
                  })
                }
              />
              <label htmlFor="acceptTerms">
                Ich nehme obige Vertragsbedingungen explizit an und bestätige
                die Unterzeichnung des Vertrages.
              </label>
            </div>
          )}
          {state.showVexatoryClauses && (
            <div className={styles.acceptVexatoryClauses}>
              <input
                id="acceptVexatoryClauses"
                type="checkbox"
                checked={state.acceptedVexatoryClauses}
                required={true}
                onChange={() =>
                  dispatch({ type: "TOGGLE_ACCEPTED_VEXATORY_CLAUSES" })
                }
              />

              <label htmlFor="acceptVexatoryClauses">
                Der Kunde bestätigt ausdrücklich folgende Artikeln des
                Rahmenvertrages zu kennen und anzunehmen:
                <br />
                Art. 2 Laufzeit, Rücktritt und Kündigung des
                HGV-Rahmenvertrages;
                <br />
                Art. 5 Laufzeit, Rücktritt und Kündigung der einzelnen
                Nutzungsverträge;
                <br />
                Art. 6.2 Abänderung Preisliste Nutzungsverträge;
                <br />
                Art 10 Ausdrückliche Auflösungsklausel der Nutzungsverträge;
                <br />
                Art 11 Anwendbares Recht und Gerichtsbarkeit;
              </label>
            </div>
          )}
          <Button
            buttonProps={{
              type: "submit",
              disabled: !state.canSign,
            }}
          >
            Bestätige jetzt
          </Button>
        </form>
      </div>
    </Page>
  );
};

export default AgreementDetail;
