import type Sentry from "@sentry/browser";

let SentryInstance: typeof Sentry | undefined;

export default () => {
  // Skip error reporting on legacy browsers
  // (which don’t support Object.fromEntries).
  if (!("fromEntries" in Object)) {
    return;
  }

  const onFirstError = async (event: ErrorEvent) => {
    window.removeEventListener("error", onFirstError);
    reportError(event.error);
  };

  window.addEventListener("error", onFirstError);
};


const initSentry = async () => {
  SentryInstance = await import("@sentry/browser");

  SentryInstance.init({
    dsn: "https://1485e044203545a7ae3fcdc2fff4b0bb@o179954.ingest.sentry.io/4503935764135936",
    autoSessionTracking: false,
  });
};

const reportError = async (error: unknown) => {
  console.error(error);
  if (!SentryInstance) await initSentry();
  SentryInstance?.captureException(error);
};
