import classNames from "classnames";
import { ButtonHTMLAttributes, PropsWithChildren } from "react";
import { Glyph } from "../../types";
import styles from "./Button.module.css";
import Icon from "./Icon";
import LoadingSpinner, { Theme } from "./LoadingSpinner";

export type ButtonType = "primary" | "secondary" | "destructive";

type Props = PropsWithChildren<{
  type?: ButtonType;
  size?: "small" | "default";
  glyph?: Glyph;
  isLoading?: boolean;
  buttonProps?: ButtonHTMLAttributes<HTMLButtonElement>;
}>;

const Button = ({
  type = "primary",
  size = "default",
  glyph,
  isLoading = false,
  buttonProps,
  children,
}: Props) => {
  const getLoadingSpinnerTheme = (): Theme => {
    switch (type) {
      case "primary":
      case "secondary":
        return "dark";
      case "destructive":
        return "light";
    }
  };

  return (
    <button
      type="button"
      {...buttonProps}
      className={classNames(styles.button, buttonProps?.className, {
        [styles.primary]: type === "primary",
        [styles.secondary]: type === "secondary",
        [styles.destructive]: type === "destructive",
        [styles.default]: size === "default",
        [styles.small]: size === "small",
        [styles.disabled]: buttonProps?.disabled,
      })}
    >
      {isLoading && (
        <LoadingSpinner
          className={styles.loadingSpinner}
          size="small"
          theme={getLoadingSpinnerTheme()}
        />
      )}
      {glyph && <Icon className={styles.icon} glyph={glyph} />}
      {children}
    </button>
  );
};

type IconButtonProps = PropsWithChildren<{
  glyph?: Glyph;
  buttonProps?: ButtonHTMLAttributes<HTMLButtonElement>;
}>;

export const IconButton = ({
  glyph,
  buttonProps,
  children,
}: IconButtonProps) => {
  return (
    <button
      type="button"
      {...buttonProps}
      className={classNames(styles.iconButton, buttonProps?.className)}
    >
      {glyph && <Icon className={styles.icon} glyph={glyph} />}
      {children}
    </button>
  );
};

export default Button;
