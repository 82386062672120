import { PropsWithChildren } from "react";
import Label from "./Label";
import styles from "./PageSection.module.css";

type Props = PropsWithChildren<{
  title: string;
}>;

const PageSection = ({ title, children }: Props) => {
  return (
    <section className={styles.pageSection}>
      <Label className={styles.label}>{title}</Label>
      {children}
    </section>
  );
};

export default PageSection;
