import classNames from "classnames";
import { PropsWithChildren } from "react";
import styles from "./Panel.module.css";

type Props = PropsWithChildren & {
  title?: string;
  className?: boolean;
};

const Panel = ({ title, className, children }: Props) => (
  <div className={classNames(styles.panel, className)}>
    {title && <h2>{title}</h2>}
    {children}
  </div>
);

export default Panel;
