import { PropsWithChildren } from "react";
import { useSearchParams } from "react-router-dom";
import { DocumentFilterOptions, DocumentFilterState } from "../../types";
import SearchBar from "../ui/SearchBar";
import Select from "../ui/Select";
import styles from "./DocumentsFilters.module.css";
import i18n from "../../i18n";
import { SP } from "../../http/serviceportalApi";

interface Props {
  filterOptions: DocumentFilterOptions;
  documentFilters: DocumentFilterState;
}

const DocumentsFilters = ({
  filterOptions: {
    typeOptions,
    dateOptions,
    customerOptions,
    memberOptions,
    departmentOptions,
  },
  documentFilters: { type, date, employee, customer, member, department },
}: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setParam = (key: string, value: string) => {
    value !== "" ? searchParams.set(key, value) : searchParams.delete(key);
    searchParams.set("seite", "1");
    setSearchParams(searchParams);
  };

  return (
    <div className={styles.filters}>
      {customerOptions && customerOptions.length > 0 && (
        <Filter id="customer" label="Kunde">
          <Select
            id="customer"
            className={styles.typeSelect}
            value={customer ?? customerOptions[0]?.id}
            onChange={(e) => setParam("kunde", e.target.value)}
          >
            {customerOptions.map(({ id, displayName }) => (
              <option key={id} value={id}>
                {displayName}
              </option>
            ))}
          </Select>
        </Filter>
      )}
      {departmentOptions && departmentOptions.length > 0 && (
        <Filter id="department" label="Abteilung">
          <Select
            id="department"
            className={styles.typeSelect}
            value={department ?? departmentOptions[0]?.id}
            onChange={(e) => setParam("abteilung", e.target.value)}
          >
            {departmentOptions.map(({ id, displayName }) => (
              <option key={id} value={id}>
                {i18n.getDepartment(displayName as SP.Department)}
              </option>
            ))}
          </Select>
        </Filter>
      )}
      {memberOptions && memberOptions.length > 0 && (
        <Filter id="member" label="Mitgliedsnummer">
          <Select
            id="member"
            className={styles.typeSelect}
            value={member ?? memberOptions[0]?.id}
            onChange={(e) => setParam("mitglied", e.target.value)}
          >
            {memberOptions.map(({ id, displayName }) => (
              <option key={id} value={id}>
                {displayName}
              </option>
            ))}
          </Select>
        </Filter>
      )}
      {!memberOptions?.length && !departmentOptions?.length && (
        <Filter id="documentType" label="Dokument">
          <Select
            id="documentType"
            className={styles.typeSelect}
            value={type ?? typeOptions[0]?.id}
            onChange={(e) => setParam("dokument", e.target.value)}
          >
            {typeOptions.map(({ id, displayName }) => (
              <option key={id} value={id}>
                {displayName}
              </option>
            ))}
          </Select>
        </Filter>
      )}
      <Filter id="date" label="Datum">
        <Select
          id="date"
          className={styles.dateSelect}
          value={date ?? dateOptions[0]?.id}
          onChange={(e) => setParam("datum", e.target.value)}
        >
          {dateOptions.map(({ id, displayName }) => (
            <option key={id} value={id}>
              {displayName}
            </option>
          ))}
        </Select>
      </Filter>
      {!customerOptions?.length &&
        !memberOptions?.length &&
        !departmentOptions?.length && (
          <Filter id="employee" label="Mitarbeiter">
            <SearchBar
              id="employee"
              className={styles.search}
              value={employee ?? ""}
              onChange={(value) => setParam("mitarbeiter", value)}
            />
          </Filter>
        )}
    </div>
  );
};

type FilterProps = PropsWithChildren<{ id: string; label: string }>;

const Filter = ({ id, label, children }: FilterProps) => (
  <div className={styles.filter}>
    <label htmlFor={id}>{label}</label>
    {children}
  </div>
);

export default DocumentsFilters;
