import { PropsWithChildren } from "react";
import Badge from "./Badge";

type Props = PropsWithChildren<{
  title: string;
}>;

const ShortInfo = ({ title, children }: Props) => {
  return (
    <div>
      {title}: <Badge color="grey">{children}</Badge>
    </div>
  );
};

export default ShortInfo;
