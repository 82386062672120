import classNames from "classnames";
import { Glyph } from "../../types";
import styles from "./Bullet.module.css";

const SvgBullet: Glyph = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path d="M18 16l-8 1-4-6 5-4 7 1z" />
    </svg>
  );
};

export const SvgBulletAccounting: Glyph = (props) => (
  <SvgBullet
    {...props}
    className={classNames(props.className, styles.accounting)}
  />
);

export const SvgBulletPayroll: Glyph = (props) => (
  <SvgBullet
    {...props}
    className={classNames(props.className, styles.payroll)}
  />
);

export const SvgBulletWebMarketing: Glyph = (props) => (
  <SvgBullet
    {...props}
    className={classNames(props.className, styles.webMarketing)}
  />
);

export default SvgBullet;
