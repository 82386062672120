import { PropsWithChildren } from "react";
import styles from "./Alert.module.css";

type Props = PropsWithChildren<{
  showUntil?: Date;
}>;

const Alert = ({ showUntil, children }: Props) => {
  if (showUntil && showUntil <= new Date()) {
    return null;
  }
  return <div className={styles.alert}>{children}</div>;
};

export default Alert;
