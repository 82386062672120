import { Link, useLocation } from "react-router-dom";
import useToggle from "../../hooks/useToggle";
import SvgAdminPanelSettings from "../icons/AdminPanelSettings";
import SvgMoreVert from "../icons/MoreVert";
import SvgOpenInNew from "../icons/OpenInNew";
import { IconButton } from "./Button";
import Icon from "./Icon";
import styles from "./AppMenu.module.css";
import { useMe, useMembers } from "../../http/serviceportalApi";
import {
  isRouteEconomicAdvisors,
  checkHasAdminPermission,
  checkHasEconomicAdvisorPermission,
  isRoutePrivateHousehold,
  checkHasPrivateHouseholdPermission,
  checkHasRevisorPermission,
  isRouteRevisor,
} from "../../utils";
import SvgAccountTie from "../icons/AccountTie";
import { useRef } from "react";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import SvgWeekend from "../icons/Weekend";
import SvgQuickReferenceAll from "../icons/QuickReferenceAll";

const AppMenu = () => {
  const menu = useToggle();
  const location = useLocation();
  const ref = useRef<HTMLDivElement>(null);

  const { data: me } = useMe();
  const userPermissions = me?.permissions ?? [];

  const { data: members } = useMembers({
    page: 1,
    limit: 1,
  });
  const hasMemberPermission = members?.items.length ?? 0 > 0;

  const isRouteAdmin = location.pathname.startsWith("/admin");

  const canNavigateToCustomerApp =
    hasMemberPermission &&
    (isRouteAdmin ||
      isRouteEconomicAdvisors(location) ||
      isRoutePrivateHousehold(location) ||
      isRouteRevisor(location));
  const canNavigateToAdminApp =
    checkHasAdminPermission(userPermissions) && !isRouteAdmin;
  const canNavigateToEconomicAdvisorApp =
    checkHasEconomicAdvisorPermission(userPermissions) &&
    !isRouteEconomicAdvisors(location);
  const canNavigateToPrivateHouseholdApp =
    checkHasPrivateHouseholdPermission(userPermissions) &&
    !isRoutePrivateHousehold(location);
  const canNavigateToRevisorApp =
    checkHasRevisorPermission(userPermissions) && !isRouteRevisor(location);

  useOnClickOutside(ref, menu.close);

  if (
    !canNavigateToCustomerApp &&
    !canNavigateToAdminApp &&
    !canNavigateToEconomicAdvisorApp &&
    !canNavigateToPrivateHouseholdApp
  ) {
    return null;
  }

  return (
    <div ref={ref} className={styles.appMenu}>
      <IconButton glyph={SvgMoreVert} buttonProps={{ onClick: menu.toggle }} />
      {menu.isOpen && (
        <ul className={styles.menu}>
          {canNavigateToCustomerApp && (
            <li>
              <Link
                to="/"
                target="_blank"
                className={styles.menuItem}
                onClick={menu.close}
              >
                <Icon glyph={SvgAdminPanelSettings} className={styles.icon} />
                Portal für Kunden
                <Icon glyph={SvgOpenInNew} className={styles.openInNew} />
              </Link>
            </li>
          )}
          {canNavigateToAdminApp && (
            <li>
              <Link
                to="/admin"
                target="_blank"
                className={styles.menuItem}
                onClick={menu.close}
              >
                <Icon glyph={SvgAdminPanelSettings} className={styles.icon} />
                Administration
                <Icon glyph={SvgOpenInNew} className={styles.openInNew} />
              </Link>
            </li>
          )}
          {canNavigateToEconomicAdvisorApp && (
            <li>
              <Link
                to="/wirtschaftsberater"
                target="_blank"
                className={styles.menuItem}
                onClick={menu.close}
              >
                <Icon glyph={SvgAccountTie} className={styles.icon} />
                Portal für Wirtschaftsberater
                <Icon glyph={SvgOpenInNew} className={styles.openInNew} />
              </Link>
            </li>
          )}
          {canNavigateToPrivateHouseholdApp && (
            <li>
              <Link
                to="/privathaushalt"
                target="_blank"
                className={styles.menuItem}
                onClick={menu.close}
              >
                <Icon glyph={SvgWeekend} className={styles.icon} />
                Portal für Privathaushalte
                <Icon glyph={SvgOpenInNew} className={styles.openInNew} />
              </Link>
            </li>
          )}
          {canNavigateToRevisorApp && (
            <li>
              <Link
                to="/revisoren"
                target="_blank"
                className={styles.menuItem}
                onClick={menu.close}
              >
                <Icon glyph={SvgQuickReferenceAll} className={styles.icon} />
                Portal für Revisoren
                <Icon glyph={SvgOpenInNew} className={styles.openInNew} />
              </Link>
            </li>
          )}
        </ul>
      )}
    </div>
  );
};

export default AppMenu;
