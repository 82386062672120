import classNames from "classnames";
import useToggle from "../../hooks/useToggle";
import { SP } from "../../http/serviceportalApi";
import { formatAsCurrencyWith, sumOpenItems } from "../../utils";
import SvgExpandLess from "../icons/ExpandLess";
import SvgExpandMore from "../icons/ExpandMore";
import Icon from "../ui/Icon";
import Table from "../ui/Table";
import TableCell from "../ui/TableCell";
import styles from "./CompaniesTable.module.css";
import DocumentsTable, { OpenItemsDocument } from "./DocumentsTable";

export interface OpenItemsCompany {
  vatCode: string;
  name: string | null;
  openItems: SP.OpenItem[];
}

interface Props {
  title: string;
  companies: OpenItemsCompany[];
}

const CompaniesTable = ({ title, companies }: Props) => {
  const total = companies.reduce(
    (state: number, value) => state + sumOpenItems(value.openItems),
    0
  );

  return (
    <div>
      <h3 className={styles.title}>{title}</h3>
      <Table
        classNames={{ table: styles.table }}
        total={companies.length}
        head={
          <tr>
            <th></th>
            <th className={styles.iconCell}></th>
            <th className={styles.alignLeft}>Bezeichnung</th>
            <th className={styles.alignRight}>MwSt.-Nummer</th>
            <th className={styles.alignRight}>Saldo</th>
          </tr>
        }
        body={companies.map(({ vatCode, name, openItems }, index) => (
          <Row
            key={index}
            vatCode={vatCode}
            name={name}
            openItems={openItems}
          />
        ))}
        footer={
          companies.length > 0 ? (
            <tr className={styles.footer}>
              <td></td>
              <td className={styles.iconCell}></td>
              <td className={styles.alignLeft}>Summe</td>
              <td></td>
              <TableCell
                CellType="td"
                value={total}
                formatter={(value) => formatAsCurrencyWith(2, value)}
              />
            </tr>
          ) : undefined
        }
      />
    </div>
  );
};

interface RowProps {
  vatCode: string;
  name: string | null;
  openItems: SP.OpenItem[];
}

const Row = ({ vatCode, name, openItems }: RowProps) => {
  const isExpandable = openItems.length > 0;
  const level2 = useToggle();

  const documents = Object.values(
    openItems.reduce(
      (state: { [key: string]: OpenItemsDocument }, value: SP.OpenItem) => {
        const documentProps = {
          documentDate: value.documentDate,
          documentNumber: value.documentNumber,
          documentNumberOriginal: value.documentNumberOriginal,
          operationDate: value.operationDate,
          section: value.section,
        };
        const key = JSON.stringify(documentProps);
        if (!state[key]) {
          state[key] = { ...documentProps, openItems: [] };
        }
        state[key]?.openItems.push(value);
        return state;
      },
      {}
    )
  ).sort(byOperationDateDesc);

  return (
    <>
      <tr
        className={classNames(styles.row, {
          [styles.expandable]: isExpandable,
          [styles.expanded]: level2.isOpen,
        })}
        onClick={() => isExpandable && level2.toggle()}
      >
        <td></td>
        <td>
          {isExpandable && (
            <Icon
              glyph={level2.isOpen ? SvgExpandLess : SvgExpandMore}
              className={styles.icon}
            />
          )}
        </td>
        <td>{name ?? "<Nicht zuordenbare Posten>"}</td>
        <td className={styles.alignRight}>{vatCode}</td>
        <TableCell
          CellType="td"
          value={sumOpenItems(openItems)}
          formatter={(value) => formatAsCurrencyWith(2, value)}
        />
      </tr>
      {level2.isOpen && (
        <tr className={styles.level2}>
          <td></td>
          <td colSpan={4}>
            <DocumentsTable documents={documents} />
          </td>
        </tr>
      )}
    </>
  );
};

const byOperationDateDesc = (
  { operationDate: a }: OpenItemsDocument,
  { operationDate: b }: OpenItemsDocument
) => {
  // Nulls last
  if (!a) return -1;
  if (!b) return 1;
  return new Date(b).getTime() - new Date(a).getTime();
};

export default CompaniesTable;
