import { PropsWithChildren, ReactNode } from "react";
import styles from "./Fieldset.module.css";

type Props = PropsWithChildren<{
  title: string;
  description?: ReactNode;
}>;

const Fieldset = ({ title, description, children }: Props) => (
  // The `overflow` property does not work correctly with <fieldset> elements,
  // thus a <div> is used instead.
  // (see https://bugs.chromium.org/p/chromium/issues/detail?id=876697)
  <div className={styles.fieldset}>
    <h4 className={styles.title}>{title}</h4>
    {description && <p className={styles.description}>{description}</p>}
    <div>{children}</div>
  </div>
);

export default Fieldset;
